import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { useState, useRef } from "react";
import { Card, CardColor } from "../card";
import { format } from "date-fns";
import { IFlourishingScaleResult } from "../../../client-server-shared/response-types";
import CloseIcon from "../../../assets/icons/icon-close.svg";
import ReadingSun from "../../../assets/illustrations/scale-sun.svg";
import QuestionCircle from "../../../assets/icons/icon-question-circle.svg";

export const getLatestScaleDetailsData = (flourishingScaleResult?: IFlourishingScaleResult) => {
  return [
    {
      dimension: "Purpose".toUpperCase(),
      score: flourishingScaleResult ? flourishingScaleResult.purpose : 0,
    },
    {
      dimension: "Relationships".toUpperCase(),
      score: flourishingScaleResult ? flourishingScaleResult.relationships : 0,
    },
    {
      dimension: "Engagement".toUpperCase(),
      score: flourishingScaleResult ? flourishingScaleResult.engagement : 0,
    },
    {
      dimension: "Contribution".toUpperCase(),
      score: flourishingScaleResult ? flourishingScaleResult.contribution : 0,
    },
    {
      dimension: "Competence".toUpperCase(),
      score: flourishingScaleResult ? flourishingScaleResult.competence : 0,
    },
    {
      dimension: "Self-Esteem".toUpperCase(),
      score: flourishingScaleResult ? flourishingScaleResult.selfEsteem : 0,
    },
    {
      dimension: "Optimism".toUpperCase(),
      score: flourishingScaleResult ? flourishingScaleResult.optimism : 0,
    },
    {
      dimension: "Acceptance".toUpperCase(),
      score: flourishingScaleResult ? flourishingScaleResult.acceptance : 0,
    },
  ];
};

export const ScaleDetailsCard = ({
  flourishingScaleResult,
}: {
  flourishingScaleResult: IFlourishingScaleResult;
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [showScoreCard, setShowScoreCard] = useState(false);
  const dateValue = new Date(flourishingScaleResult.submittedAt);
  return (
    <>
      <Card color={CardColor.Gray}>
        <div className="min-w-[343px] bg-teal-0 rounded-3xl pl-3 pr-3 pt-4 pb-6 space-y-3">
          <div className="grid grid-cols-2">
            <div className="pl-2 text-teal-500 text-xs font-medium">FLOURISH PROFILE</div>
            <div className="text-right pr-2 text-gray-400 text-xs">
              <span>Updated on {format(dateValue, "M/d")}</span>
            </div>
          </div>

          <div className="flex flex-row flex-wrap space-y-3">
            <div className="flex md:w-1/3">
              <img className="h-24" src={ReadingSun} />
              <div className="pl-3 pt-4">
                <div className="text-teal-600 text-sm font-semibold">
                  Your Flourish Scale:
                  <br />
                </div>
                <span className="text-5xl font-semibold text-teal-500">
                  {flourishingScaleResult.totalScore}
                </span>
                <span className="text-teal-800 text-base font-normal">/10</span>
              </div>
            </div>

            <div className="w-[310px] h-[200px] md:block md:mx-auto">
              <ResponsiveContainer>
                <RadarChart data={getLatestScaleDetailsData(flourishingScaleResult)}>
                  <PolarGrid />
                  <PolarAngleAxis
                    dataKey="dimension"
                    tick={{ fontSize: 10, fontWeight: 600, fill: "#147180" }}
                  />
                  <PolarRadiusAxis domain={[0, 7]} tick={false} />
                  <Radar
                    name="LatestScaleDetails"
                    dataKey="score"
                    stroke="#7CBDC6"
                    fill="#49ADBB"
                    fillOpacity={0.6}
                  />
                </RadarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="pl-2">
            <button
              className="underline text-sm font-semibold text-teal-600"
              onClick={() => setShowScoreCard(true)}
            >
              What is the Flourishing Score?
            </button>
          </div>
        </div>
      </Card>

      {showScoreCard && (
        <div className="fixed top-0 left-0 w-full h-screen bg-white/60 z-10 !mt-0">
          <div className="w-full h-full flex justify-center items-center" ref={popupRef}>
            <div className="w-1/2 min-w-[343px]">
              <Card>
                <div className="px-3 pt-4 pb-6 flex flex-col space-y-4 bg-teal-0 rounded-xl">
                  <div className="flex items-center justify-end px-2">
                    <button className="h-[12px] w-[12px]">
                      <img src={CloseIcon} alt="" onClick={() => setShowScoreCard(false)} />
                    </button>
                  </div>

                  <div className="h-[390px] overflow-auto space-y-3">
                    <div className="flex flex-row">
                      <div className="pl-2">
                        <img src={QuestionCircle} alt="" className="w-4 max-w-[16px]" />
                      </div>
                      <div className="space-y-3 pr-2 pl-3">
                        <div className="text-xs font-semibold text-teal-500">
                          What is the Flourishing Score?
                        </div>
                        <div className="text-sm font-normal text-teal-800">
                          The Flourishing Score is calculated based on the “Flourishing Scale”, a
                          well-established scientific measure developed by Dr. Ed Diener, a leading
                          scholar in positive psychology (you can read more{" "}
                          <a href="https://eddiener.com/scales/9" className="underline">
                            here
                          </a>
                          ). This short survey can help you take stock of different aspects of life
                          and track your subjective well-being over time.
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row">
                      <div className="pl-2">
                        <img src={QuestionCircle} alt="" className="w-4 max-w-[16px]" />
                      </div>
                      <div className="space-y-3 pr-2 pl-3">
                        <div className="text-xs font-semibold text-teal-500">
                          What does each dimension mean?
                        </div>
                        <div className="text-sm font-normal text-teal-800">
                          <span className="font-semibold">PURPOSE</span>: I lead a purposeful and
                          meaningful life.
                          <br />
                          <span className="font-semibold">RELATIONSHIP</span>: My social
                          relationships are supportive and rewarding.
                          <br />
                          <span className="font-semibold">ENGAGEMENT</span>: I am engaged and
                          interested in my daily activities.
                          <br />
                          <span className="font-semibold">CONTRIBUTION</span>: I actively contribute
                          to the happiness and well-being of others.
                          <br />
                          <span className="font-semibold">COMPETENCE</span>: I am competent and
                          capable in the activities that are important to me.
                          <br />
                          <span className="font-semibold">SELF-ESTEEM</span>: I am a good person and
                          live a good life.
                          <br />
                          <span className="font-semibold">OPTIMISM</span>: I am optimistic about my
                          future.
                          <br />
                          <span className="font-semibold">ACCEPTANCE</span>: People respect me.
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row">
                      <div className="pl-2">
                        <img src={QuestionCircle} alt="" className="w-4 max-w-[16px]" />
                      </div>
                      <div className="space-y-3 pr-2 pl-3">
                        <div className="text-xs font-semibold text-teal-500">
                          Will my Flourish Score change over time?
                        </div>
                        <div className="text-sm font-normal text-teal-800">
                          Yes! Your subjective well-being is influenced by many factors and will
                          change over time. You will have access to the same set of questions next
                          week and find out how your score may have changed after starting this
                          Flourish Journey.
                          <br />
                          <br />
                          Regardless of your score today, the truth is you are both a masterpiece
                          and a work in progress. We&apos;re glad to meet you where you are, and
                          we&apos;ll support you and grow with you along this journey.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
