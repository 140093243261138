export const ONBOARDING1_FORM_ID = "zxdoTnB7";
export const ONBOARDING2_FORM_ID = "f1mnpcOV";

export const FUTURE_SELF_FORM_ID = "XteOK6DQ"; // Letter To Future Self
export const THREE_GOOD_FORM_ID = "bYN3mtW0"; // Three Good Things
export const BEAUTIFUL_MOMENT_FORM_ID = "KY9IrSB9"; // Beautiful Moment
export const NATURE_WALK_FORM_ID = "oE1TZx8N"; // Nature Walk
export const MEANINGFUL_CONVERSATION_FORM_ID = "g4eaRsPB"; // Meaningful Conversation
export const GRATITUDE_NOTE_FORM_ID = "Klw8z6ZI"; // Gratitude Note
export const GIFTING_COMPLIMENT_FORM_ID = "e0kTCaMV"; // Gifting Compliment
export const BLAST_PAST_FORM_ID = "DMcf7wgR"; // Blast From The Past
export const SOMEBODY_WHO_FORM_ID = "mhlk0y3N"; // Somebody Who

export const GRATITUDE_NOTE_FORM_ID_OLD = "B0F9FgYS"; // Gratitude Note
export const NATURE_WALK_FORM_ID_OLD = "k6gl62PI"; // Nature Walk
export const BEAUTIFUL_MOMENT_FORM_ID_OLD = "fue8tepP"; // Beautiful Moment
export const BLAST_PAST_FORM_ID_OLD = "EgzZtCyq"; // Blast From The Past
export const GIFTING_COMPLIMENT_FORM_ID_OLD = "kGFf9dtr"; // Gifting Compliment
export const MEANINGFUL_CONVERSATION_FORM_ID_OLD = "oIdxsQI5"; // Meaningful Conversation

export const FLOURISHING_SCALE_FORM_ID = "mCJP6gMb"; // Flourishing Scale
export const FOLLOWUP1_FORM_ID = "wUFKOvs5";
export const FOLLOWUP2_FORM_ID = "jLAhoNbt";

export const FEEDBACK_FORM_ID = "EWkcDVvs";
export const FEEDBACK_FORM_TITLE = "We'd love to hear your feedback!"; 

// flourish pulse formId constant
export const PULSE_FORM_ID = "dqUVla4n";
export const PULSE_FORM_TITLE = "Flourish Pulse";

export const SkipIDs = new Set<string>([
  // Old Part-1 ID's of 2-part activities
  "IUv0glbj",
  "ZZoYMLyx",
  "cm90o0W5",
  "K7qShTMW",
  "X79CKN0l",
  "XIMgweJY",
  ONBOARDING1_FORM_ID,
  ONBOARDING2_FORM_ID,
  FLOURISHING_SCALE_FORM_ID,
  FOLLOWUP1_FORM_ID,
  FOLLOWUP2_FORM_ID,
  PULSE_FORM_ID,
]);

export const FUTURE_SELF_TYPEFORM_LINK = "https://www.heyflourish.app/letterfromself";
export const THREE_GOOD_TYPEFORM_LINK = "https://www.heyflourish.app/threegoodthings";
export const GRATITUDE_NOTE_TYPEFORM_LINK = "https://www.heyflourish.app/gratitude";
export const NATURE_WALK_TYPEFORM_LINK = "https://www.heyflourish.app/take-naturewalk";
export const BEAUTIFUL_MOMENT_TYPEFORM_LINK = "";
export const BLAST_PAST_TYPEFORM_LINK = "";
export const GIFTING_COMPLIMENT_TYPEFORM_LINK = "https://www.heyflourish.app/giftcompliment";
export const MEANINGFUL_CONVERSATION_TYPEFORM_LINK = "https://www.heyflourish.app/meaningfulconvo";
export const SOMEBODY_WHO_TYPEFORM_LINK = "https://www.heyflourish.app/somebodywho";
