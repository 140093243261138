import { useCallback, useEffect } from "react";

export const useTypeform = (skip?: boolean) => {
  useEffect(() => {
    if (!skip) {
      const script = document.createElement("script");
      script.src = "//embed.typeform.com/next/embed.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [skip]);
};

export const useListenTypeformSubmit = (onSubmit: () => void) => {
  const submitHandler = useCallback(
    (event: any) => {
      if (event.data.type == "form-submit") {
        onSubmit();
      }
    },
    [onSubmit]
  );
  useEffect(() => {
    window.addEventListener("message", submitHandler, false);
    // TODO: unmount
    // return window.removeEventListener("message", submitHandler, false);
  }, []);
};
