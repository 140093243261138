import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import LeafImage from "../../../assets/accessories/leaf.svg";

export const ButtonColor = {
  TealFilled:
    "bg-[#006972] hover:bg-teal-500 disabled:bg-gray-400 text-gray-0 disabled:text-gray-100 border-transparent focus:border-teal-700 border-2",
  TealUnfilled:
    "bg-transparent hover:bg-teal-0 text-teal-500 disabled:text-gray-300 border-teal-500 disabled:border-gray-300 focus:bg-teal-50 border-2",
  TealLightUnfilled:
    "bg-transparent hover:bg-teal-100 hover:text-teal-600 hover:border-teal-100 text-teal-50 disabled:text-gray-300 border-teal-50 border-2 disabled:border-gray-300 focus:bg-teal-50",
  TealLightFilled:
    "bg-teal-50 hover:bg-teal-100 hover:text-white disabled:bg-gray-400 text-gray-0 disabled:text-gray-100 border-transparent focus:border-teal-700 border-2",
  TealNone:
    "bg-transparent hover:bg-teal-0 text-teal-500 disabled:text-gray-300 border-transparent disabled:border-gray-300 focus:bg-teal-50 border-2",
  RedFilled:
    "bg-red-300 hover:bg-red-400 diabled:bg-gray-400 text-gray-0 disabled:text-gray-100 border-transparent border-2",
  RedUnFilled:
    "bg-transparent hover:bg-red-0 hover:border-red-400 text-red-400 disabled:text-gray-300 border-white disabled:border-gray-300 focus:bg-red-50 border-2",
  RedUnFilledBorder:
    "bg-transparent hover:bg-red-0 text-red-400 disabled:text-gray-300 border-red-300 disabled:border-gray-300 focus:bg-red-50 border-2",
  DarkGreenFilled:
    "bg-green-300 hover:bg-green-400 disabled:bg-gray-400 text-gray-0 disabled:text-gray-100 border-transparent focus:border-green-700 border-2",
  Disabled:
    "bg-gray-100 text-gray-300 border-2 border-transparent"
} as const;
export type ButtonColor = (typeof ButtonColor)[keyof typeof ButtonColor];

export const ButtonSize = {
  Small: "rounded-lg font-semibold text-xs py-1 px-2 w-fit",
  Medium: "rounded-lg font-semibold text-sm py-1 px-2 w-fit",
  Large: "rounded-3xl font-semibold px-6 py-1.5 w-fit",
  BorderlessLarge: "rounded-xl font-semibold py-1.5 w-fit",
  Full: "rounded-full font-semibold px-6 py-2 w-full",
} as const;
export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize];

const getButtonStyles = (color: ButtonColor, size: ButtonSize) => {
  return classNames("border block outline-none transition duration-80", color, size);
};

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "className" | "children"> {
  color?: ButtonColor;
  size?: ButtonSize;
  children?: ReactNode;
  className?: string;
  ref?: React.RefObject<HTMLButtonElement>;
  onClick?: () => void,
  disabled?: boolean,
}
export const Button = ({
  color = ButtonColor.TealFilled,
  size = ButtonSize.Large,
  children,
  className,
  ref,
  onClick,
  disabled = false,
  ...rest
}: ButtonProps) => {
  const defaultClasses = classNames("text-center", getButtonStyles(color, size));
  return (
    <button
      className={classNames(defaultClasses, className)} {...rest}
      ref={ref ? ref : null}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

interface ButtonLinkProps extends ButtonProps {
  to: string;
  target?: string;
  usingDefaultStyles?: boolean,
}

// `ButtonLink` is a component that acts as a button using an anchor tag
export const ButtonLink = ({
  to,
  color = ButtonColor.TealFilled,
  size = ButtonSize.Large,
  usingDefaultStyles = true,
  target = "_self",
  children,
  className,
}: ButtonLinkProps) => {
  let defaultStyle = "";
  if (usingDefaultStyles) defaultStyle = classNames("text-center", getButtonStyles(color, size));
  return (
    <a href={to} target={target} className={classNames(defaultStyle, className)}>
      {children}
    </a>
  );
};

// `ButtonNavigate` is a component that is a button using the `useNavigate` hook
export const ButtonNavigate = ({
  to,
  color = ButtonColor.TealFilled,
  size = ButtonSize.Large,
  children,
  className,
}: ButtonLinkProps) => {
  const navigate = useNavigate();
  const defaultClasses = classNames("text-center", getButtonStyles(color, size));
  return (
    <button onClick={() => navigate(to)} className={classNames(defaultClasses, className)}>
      {children}
    </button>
  );
};

interface TypeformButtonLinkProps extends ButtonProps {
  className?: string;
  usingDefaultStyles?: boolean;
  typeformLink: {
    id?: string | undefined;
    title?: string | undefined;
    hiddenData?: string | undefined;
  };
}

/*
 * This component is used to provide the button to trigger Typeform.
 * It can be provided with `className` styling. If no `className` prop is
 * provided, a default styling will be used instead.
 */
export const TypeformButtonLink = ({
  className,
  typeformLink,
  usingDefaultStyles = true,
  color = ButtonColor.TealFilled,
  size = ButtonSize.Large,
  children,
}: TypeformButtonLinkProps) => {
  const { id, hiddenData } = typeformLink;
  let defaultStyle = "";
  if (usingDefaultStyles) defaultStyle = `max-[320px]:text-sm ${classNames(getButtonStyles(color, size), color)}`
  return (
    <button
      className={className ? classNames(className, defaultStyle) : defaultStyle}
      data-tf-popup={id}
      data-tf-medium="snippet"
      data-tf-hidden={hiddenData}
      data-tf-on-ending-button-click="onTypeformEndingButtonClick"
    >
      {children}
    </button>
  );
};

interface ActivityButtonProps extends ButtonProps {
  className?: string;
  usingDefaultStyles?: boolean;
  component: React.ElementType;
}
export const ActivityButton = ({
  className,
  usingDefaultStyles = true,
  color = ButtonColor.TealFilled,
  size = ButtonSize.Large,
  children,
  component: Component,
}: ActivityButtonProps) => {
  const [renderActivity, setRenderActivity] = useState<boolean>(false);
  let defaultStyle = "";
  if (usingDefaultStyles) defaultStyle = `max-[320px]:text-sm ${classNames(getButtonStyles(color, size), color)}`
  return (
    <>
      {renderActivity && <Component />} 
      <button
        className={className ? classNames(className, defaultStyle) : defaultStyle}
        onClick={() => setRenderActivity(true)}
      >
        {children}
      </button>
    </>
  );
};

// TODO: get disabled from button props
export const LeafWrapper = ({
  disabled,
  children,
}: {
  disabled?: boolean;
  children?: ReactNode;
}) => {
  return (
    <div className="flex flex-col items-end">
      <div
        className={classNames("h-9", {
          "opacity-0": disabled,
          "opacity-100": !disabled,
        })}
      >
        <img src={LeafImage} />
      </div>
      {children}
    </div>
  );
};
