import { useState, useEffect } from "react";
import { IUser } from "../../../client-server-shared/response-types";
import { DailyMoodPopup } from "../daily-mood/DailyMoodPopup";
import { OrgInvitePopup } from "../../pages/organizations/OrgInvitePopup";
import { BuddyInvitePopup } from "../../pages/buddies/BuddyInvitePopup";
export enum PopupState {
    LOADING,
    SHOWN,
    NOT_SHOWN,
  }
/**
 * This component will handle the popups that will appear for certain features
 * when a user logs in.
 * 
 * Each popup component gets its own rendering state, with Daily Mood having lower
 * priority than Org and Buddy invites.
 */
interface PopupHandlerProps {
    user: IUser;
}
export const PopupHandler = ({user}:PopupHandlerProps) => {
    const [orgInvitePopupState, setOrgInvitePopupState] = useState<PopupState>(PopupState.LOADING);
    const [buddyInvitePopupState, setBuddyInvitePopupState] = useState<PopupState>(PopupState.LOADING);
    const [dailyMoodPopupState, setDailyMoodPopupState] = useState<PopupState>(PopupState.LOADING);
    // This useEffect handles the logic to show the Daily Mood popup depending on Org and Buddy popup states.
    useEffect(() => {
        if (orgInvitePopupState === PopupState.NOT_SHOWN && buddyInvitePopupState === PopupState.NOT_SHOWN) {
            // Additionally, check if the user is supposed to see the DailyMoodPopup
            if (user.showDailyMood) {
                setDailyMoodPopupState(PopupState.SHOWN);
            } else {
                setDailyMoodPopupState(PopupState.NOT_SHOWN);
            }
        }
    }, [orgInvitePopupState, buddyInvitePopupState]);
    return(
        <>
            {/* TODO: Remove these condition logics */}
            {(orgInvitePopupState === PopupState.LOADING || orgInvitePopupState === PopupState.SHOWN) && (
                <OrgInvitePopup setPopupState={setOrgInvitePopupState} />
            )}
            {(buddyInvitePopupState === PopupState.LOADING || buddyInvitePopupState === PopupState.SHOWN) && (
                <BuddyInvitePopup setPopupState={setBuddyInvitePopupState} />
            )}
            {dailyMoodPopupState === PopupState.SHOWN && <DailyMoodPopup />}
        </>
    )
}