import React from "react";
import { PRIVACY_URL, TERMS_URL } from "../../util/constants";

export const Footer = ({ withLinks = true }: { withLinks?: boolean }) => {
  const parts = [
    { text: "Website", link: "https://flouriship.com" },
    { text: "FAQ", link: "https://www.flouriship.com/faq" },
    { text: "Privacy", link: PRIVACY_URL },
    { text: "Terms", link: TERMS_URL },
  ];

  const usableParts = withLinks ? parts : parts.filter((part) => !("link" in part));
  return (
    <div className="text-xs text-gray-800 text-center">
      {usableParts.map((part, index) => {
        const isTail = index === usableParts.length - 1;
        return (
          <React.Fragment key={index}>
            {part.link ? (
              <a href={part.link} rel="noopener noreferrer" target="_blank">
                {part.text}
              </a>
            ) : (
              <span>{part.text}</span>
            )}
            {!isTail && " | "}
          </React.Fragment>
        );
      })}
      <p>© Flourish Science, Inc. All rights reserved.</p>
    </div>
  );
};
