import GroupPhotoImage from "../../../assets/illustrations/group-photo.svg";
import MailImage from "../../../assets/illustrations/mail.svg";

interface Props {
  organizationName?: string;
  onInviteAccepted: () => void;
}

export const DefaultCommunityTab = ({ organizationName, onInviteAccepted }: Props) => {
  const TOGGLE_LEARN_MORE_BUTTON = false;
  return (
    <div className="container flex flex-col items-center pb-12">
      {/* Organization Invite Banner */}
      {organizationName && (
        <div className="w-full flex items-start bg-[#FEFEFD] rounded-3xl pl-9 py-4 my-4">
          <div className="flex flex-col flex-grow justify-between text-sm mt-4 text-[#5C5F5C]">
            <div>You've got an invite from</div>
            <div>{organizationName}</div>
            <button
              className="border-2 border-teal-500 rounded-xl mt-4 w-fit py-2 px-4 text-teal-500 font-semibold"
              onClick={onInviteAccepted}
            >
              Accept Invite
            </button>
          </div>
          <img src={MailImage} className="flex-none ml-4" alt="An illustration of a mail" />
        </div>
      )}
      {/* Default Card */}
      <div className="w-full flex flex-col px-6 items-center mt-4 bg-[#FEFEFD] rounded-3xl">
        <div className="w-full mb-10">
          <h1 className="flex font-bold text-teal-500 mt-6">Flourish with your Community!</h1>
          <p className="text-sm text-[#5C5F5C] my-6">
            Well-being is a collective journey. Research shows that{" "}
            <b>
              being part of a flourishing community can greatly enhance your happiness and
              well-being.
            </b>
            <br />
            <br />
            Bring Flourish to your community for deeper connections and happier days!
          </p>
          {TOGGLE_LEARN_MORE_BUTTON && (
            <a
              href="https://www.flouriship.com/community"
              target="_blank"
              className="border-2 border-teal-500 py-2 px-4 font-semibold text-teal-500 rounded-xl"
            >
              Learn More
            </a>
          )}
        </div>
        <img
          className="w-full max-w-[704px]"
          src={GroupPhotoImage}
          alt="An image of people flourishing together"
        />
      </div>
    </div>
  );
};
