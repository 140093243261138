import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import getData from "../../controllers/helper/getData";
import { GET_USER_DISPLAY_NAME } from "../../controllers/helper/requestConstants";
import { create } from "../../controllers/ajax/BuddiesController";
import { patchLastInvitationDate } from "../../controllers/ajax/UserController";
import { buddiesPageLink, activityPageLink } from "../../util/relative-links";
import { Button, ButtonColor, ButtonLink } from "../../components/button/Button";
import { BuddyPopupState } from "../../util/constants";
import { BuddyCreateErrorCode } from "../../../client-server-shared/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import SunHoldingHeart from "../../../assets/illustrations/sun-holding-heart.svg";
import SunChasingHeartWithClouds from "../../../assets/illustrations/sun-chasing-heart-with-clouds.svg";
import SunSadWithClouds from "../../../assets/illustrations/sun-sad-with-clouds.svg";
import { PopupState } from "../../components/logged-in-page-wrapper/PopupHandler";

/**
 * This component handles the popups for the Buddy Invite components. It uses a switch
 * statement that cycles through different pages of the popups based on `BuddyPopupState`.
 */
interface BuddyInvitePopupProps {
  setPopupState: React.Dispatch<React.SetStateAction<PopupState>>;
}
export const BuddyInvitePopup = ({setPopupState}: BuddyInvitePopupProps) => {
  const [searchParams] = useSearchParams();
  const referrerCode = searchParams.get("referrer");
  const [buddyPopup, setBuddyPopup] = useState<BuddyPopupState>(BuddyPopupState.NO_POPUP);
  const [referrerDisplayName, setReferrerDisplayName] = useState("[Name]");
  /**
   * This `useEffect` block handles showing the popups for the Buddy-Add feature if it
   * detects a `referrerCode` in the search parameters. It will set the opaque background
   * and set the necessary `buddyPopup` based on the referrer code.
   * 
   * When there's a `referrerCode` detected, it will set the popup state to `SHOWN` and
   * set the user's `lastInvitationDate` field.
   */
  useEffect(() => {
    if (referrerCode) {
      async function fetchDisplayName() {
        try {
          await patchLastInvitationDate();
          const response = await getData(GET_USER_DISPLAY_NAME, { userId: referrerCode });
          if (response && response.displayName) {
            setReferrerDisplayName(response.displayName);
            setBuddyPopup(BuddyPopupState.INITIAL_PROMPT);
          } else {
            setBuddyPopup(BuddyPopupState.INVALID_LINK_ERROR);
          }
        } catch (error) {
          console.error("Failed to fetch the display name", error);
        }
      }      // If there is a `referrerCode` show and render this popup
      setPopupState(PopupState.SHOWN);
      fetchDisplayName();
    } else {
      setPopupState(PopupState.NOT_SHOWN); // Disable this popup if no `referrerCode` is found
    }
  }, []);
  // This switch statement will render a Buddy Invite popup component based on `BuddyPopupState`
  switch (buddyPopup) {
    case BuddyPopupState.INITIAL_PROMPT:
      return (
        <BuddyInviteAcceptPopup
          referrerDisplayName={referrerDisplayName}
          referrerCode={referrerCode}
          setBuddyPopup={setBuddyPopup}
        />
      );
    case BuddyPopupState.CONFIRMATION:
      return <BuddyInviteConfirmationPopup referrerDisplayName={referrerDisplayName} />;
    case BuddyPopupState.BUDDY_EXISTS_ERROR:
      return <BuddyExistsErrorPopup referrerDisplayName={referrerDisplayName} />;
    case BuddyPopupState.INVALID_LINK_ERROR:
      return <BuddyInvalidLinkErrorPopup />;
    case BuddyPopupState.NO_POPUP:
    default:
      return null;
  }
};

interface BuddyInviteAcceptInterface {
  referrerDisplayName: string;
  referrerCode: string | null;
  setBuddyPopup: React.Dispatch<React.SetStateAction<BuddyPopupState>>;
}
const BuddyInviteAcceptPopup = ({
  referrerDisplayName,
  referrerCode,
  setBuddyPopup,
}: BuddyInviteAcceptInterface) => {
  /**
   * This function handles accepting a buddy invite via the `referrer` search parameter.
   * This is triggered by the initial popup window prompting the user to Accept or Cancel
   * the invitation.
   */
  async function handleAccept() {
    if (referrerCode) {
      create(referrerCode).then((response) => {
        if (response.success) {
          setBuddyPopup(BuddyPopupState.CONFIRMATION);
        } else {
          switch (response.errorCode) {
            case BuddyCreateErrorCode.BUDDY_EXISTS:
              setBuddyPopup(BuddyPopupState.BUDDY_EXISTS_ERROR);
              break;
            default:
              setBuddyPopup(BuddyPopupState.INVALID_LINK_ERROR);
              break;
          }
        }
      });
    }
  }
  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className="flex flex-col justify-center modal-content bg-white px-7 pb-8 pt-48 rounded-3xl shadow-lg w-80 bg-no-repeat bg-contain relative"
        style={{ backgroundImage: `url(${SunChasingHeartWithClouds})` }}
      >
        <ButtonLink
          className="absolute top-2 right-1 w-[44px] h-[44px] flex justify-center items-center"
          to={buddiesPageLink()}
          usingDefaultStyles={false}
        >
          <FontAwesomeIcon icon={icon({ name: "xmark" })} className="text-[#00363B] w-[16px]" />
        </ButtonLink>
        <div className="text-center text-teal-700">
          <h2 className="font-bold">Accept {referrerDisplayName}'s invite?</h2>
          <p className="text-xs pt-4">
            You can share your activity highlights with each other for more inspiration and fun.
          </p>
        </div>
        <div className="flex w-full justify-between pt-4 items-center">
          <Button className="w-full" onClick={handleAccept}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};

interface BuddyInviteConfirmationInterface {
  referrerDisplayName: string;
}
const BuddyInviteConfirmationPopup = ({
  referrerDisplayName,
}: BuddyInviteConfirmationInterface) => {
  return (
    <div className="fixed top-[-36px] left-0 w-full h-screen bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className="flex flex-col justify-center modal-content bg-white px-7 pb-8 pt-48 rounded-3xl shadow-lg w-80 bg-no-repeat bg-contain relative"
        style={{ backgroundImage: `url(${SunHoldingHeart})` }}
      >
        <ButtonLink
          className="absolute top-2 right-1 w-[44px] h-[44px] flex justify-center items-center"
          to={buddiesPageLink()}
          usingDefaultStyles={false}
        >
          <FontAwesomeIcon icon={icon({ name: "xmark" })} className="text-[#00363B] w-[16px]" />
        </ButtonLink>
        <div className="text-center text-teal-700">
          <h2 className="font-bold">Congratulations!</h2>
          <p className="text-xs pt-4 flex flex-col items-center gap-1">
            <span>You and {referrerDisplayName} are now Flourish Buddies 🌱</span>
          </p>
        </div>
        <ButtonLink
          color={ButtonColor.TealUnfilled}
          className="w-full mt-6"
          to={activityPageLink()}
        >
          Explore Flourish
        </ButtonLink>
      </div>
    </div>
  );
};

interface BuddyExistsErrorInterface {
  referrerDisplayName: string;
}
const BuddyExistsErrorPopup = ({ referrerDisplayName }: BuddyExistsErrorInterface) => {
  return (
    <div className="fixed top-[-36px] left-0 w-full h-screen bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className="flex flex-col justify-center modal-content bg-white px-7 pb-8 pt-48 rounded-3xl shadow-lg w-80 bg-no-repeat bg-contain relative"
        style={{ backgroundImage: `url(${SunHoldingHeart})` }}
      >
        <ButtonLink
          className="absolute top-2 right-1 w-[44px] h-[44px] flex justify-center items-center"
          to={buddiesPageLink()}
          usingDefaultStyles={false}
        >
          <FontAwesomeIcon icon={icon({ name: "xmark" })} className="text-[#00363B] w-[16px]" />
        </ButtonLink>
        <div className="text-center">
          <h2 className="font-bold">
            You and {referrerDisplayName} are already Flourish Buddies 🌱
          </h2>
        </div>
        <ButtonLink
          color={ButtonColor.TealUnfilled}
          className="w-full mt-6"
          to={activityPageLink()}
        >
          Explore Flourish
        </ButtonLink>
      </div>
    </div>
  );
};

const BuddyInvalidLinkErrorPopup = () => {
  return (
    <div className="fixed top-[-36px] left-0 w-full h-screen bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className="flex flex-col justify-center modal-content bg-white px-7 pb-8 pt-48 rounded-3xl shadow-lg w-80 bg-no-repeat bg-contain relative"
        style={{ backgroundImage: `url(${SunSadWithClouds})` }}
      >
        <ButtonLink
          className="absolute top-2 right-1 w-[44px] h-[44px] flex justify-center items-center"
          to={buddiesPageLink()}
          usingDefaultStyles={false}
        >
          <FontAwesomeIcon icon={icon({ name: "xmark" })} className="text-[#00363B] w-[16px]" />
        </ButtonLink>
        <div className="text-center">
          <h2 className="font-bold">Oops! This link is invalid</h2>
          <p className="text-xs pt-4 flex flex-col items-center gap-1">
            <span>Contact your Buddy-to-be for a new referral link.</span>
          </p>
        </div>
        <ButtonLink
          color={ButtonColor.TealUnfilled}
          className="w-full mt-6"
          to={activityPageLink()}
        >
          Got it
        </ButtonLink>
      </div>
    </div>
  );
};
