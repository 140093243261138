import { BottomSheet } from "../bottom-sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEyeSlash, faFlag, faBan } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

/**
 * This component is the selector menu for Reporting a post, Blocking a user or Hiding a post when
 * clicking on the options icon on a community post. It will handle selecting any of the three
 * mentioned options.
 */
interface ReportAndBlockSelectorProps {
    onClose: () => void;
    buttonRef: React.RefObject<HTMLButtonElement>;
}
export const ReportAndBlockSelector = ({
    onClose,
    buttonRef,
}: ReportAndBlockSelectorProps) => {

    // TODO: Hook these up
    const handleBlock = () => {

    }

    const handleHide = () => {

    }

    const handleReport = () => {

    }

    return (
        <BottomSheet onClose={onClose} buttonRef={buttonRef}>
            <div className="flex justify-between items-center pb-[24px]">
                <h2 className="font-bold text-xl text-teal-400">
                    Report
                </h2>
                <button
                    className="w-[44px] h-[44px] flex items-center justify-center"
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faXmark} className="text-teal-400" />
                </button>
            </div>

            <OptionButton
                onClick={handleBlock}
                label="Block this user"
                subLabel="Stop seeing posts from this person"
                icon={faBan}
            />
            <OptionButton
                onClick={handleHide}
                label="Hide this post"
                subLabel="Do not show this post to me"
                icon={faEyeSlash}
            />
            <OptionButton
                onClick={handleReport}
                label="Report post"
                subLabel="I'm concerned about this post"
                icon={faFlag}
            />
        </BottomSheet>
    );
};

// This component styles the Button for each option
interface OptionButtonProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "className" | "type"> {
    label: string;
    subLabel: string;
    icon: IconDefinition;
    onClick: () => void;
}
const OptionButton = ({ label, subLabel, icon }: OptionButtonProps) => {
    return (
        <button className="flex items-center py-1.5 text-gray-400 w-full">
            <div className="inline-block h-12 w-12 flex items-center justify-center">
                <FontAwesomeIcon icon={icon} className="text-red-500" />
            </div>
            <div className="flex flex-col items-start">
                <span className="font-bold">{label}</span>
                <span className="text-sm">{subLabel}</span>
            </div>
        </button>
    );
};
