import React, { useState } from "react";
import OrganizationItem from "./OrgListItem";
import { useGetOrganizationList } from "../../controllers/hooks/GetOrganizationListHook";
import { create, remove } from "../../controllers/ajax/OrganizationController";

export const OrganizationAdmin: React.FC = () => {
  const { data: organizations, loading: isLoading, error, refetch } = useGetOrganizationList();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newOrgName, setNewOrgName] = useState("");
  const [newOrgDescription, setNewOrgDescription] = useState("");

  const handleSubmit = () => {
    create({ name: newOrgName, description: newOrgDescription }).then((success) => {
      if (success) {
        setNewOrgName("");
        setNewOrgDescription("");
        setIsModalOpen(false);
        refetch();
      } else {
        console.log("FAILED");
      }
    });
  };
  const handleDelete = (orgName: string) => {
    remove(orgName).then((success) => {
      if (success) {
        refetch();
      } else {
        console.log("FAILED");
      }
    });

    setIsModalOpen(false);
  };

  return (
    <div>
      <header className="pl-3 space-y-12">
        <h1 className="font-bold text-2xl">
          <span className="text-teal-400">Group Management</span>
        </h1>
      </header>
      <button
        className="font-semibold py-2 px-4 text-[#006972] border border-teal-700 rounded shadow mx-auto"
        onClick={() => setIsModalOpen(true)}
      >
        Add New Group
      </button>

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-10">
          <div className="modal-content bg-white p-4 rounded shadow-lg w-3/4 max-w-xl">
            <h2>Add New Group</h2>
            <input
              placeholder="Name"
              value={newOrgName}
              onChange={(e) => setNewOrgName(e.target.value)}
              className="border border-black p-2 rounded mt-2"
            />
            <input
              placeholder="Description"
              value={newOrgDescription}
              onChange={(e) => setNewOrgDescription(e.target.value)}
              className="border border-black p-2 rounded mt-2"
            />
            <div className="flex justify-end mt-4">
              <button className="mr-2" onClick={handleSubmit}>
                Submit
              </button>
              <button onClick={() => setIsModalOpen(false)}>Close</button>
            </div>
          </div>
        </div>
      )}

      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <div>Total: {Array.isArray(organizations) ? organizations.length : "No data"}</div>
      <div>
        {organizations?.map((org) => (
          <OrganizationItem key={org.name} org={org} handleDelete={handleDelete} />
        ))}
      </div>
    </div>
  );
};
