/**
 * This file contains enums that shared between the client and server in the API.
 */

// Acceptable community feed strings the client can request. Defaults to world.
export enum CommunityFeedType {
  Buddies = "buddies",
  World = "world",
  Organization = "organization",
}

// ========== InvitationCode ========== // 
export enum InvitationCodeType {
  ORG_INVITE = "ORG_INVITE",
  RESET_PASSWORD = "RESET_PASSWORD"
}

export enum InvitationCodeStatus {
  SENT = "sent", // Code has been sent, but not opened or interacted with by user
  VERIFIED = "verified", // Code has been redeemed by user // TODO: Rename this to `REDEEM`?
  DENIED = "denied", // Code has been redeemed and denied by user
  ACCEPTED = "accepted", // Code has been redeemed and accepted by user
}

// NB! Old or unused values cannot be removed from this enum unless all instances in user
// history are deleted. New values can be added at any time.
export enum ActivityId {
  THREE_GOOD = "THREE_GOOD",
  GIFTING_COMPLIMENT = "GIFTING_COMPLIMENT",
  NATURE_WALK = "NATURE_WALK",
  BEAUTIFUL_MOMENT = "BEAUTIFUL_MOMENT",
  FUTURE_SELF = "FUTURE_SELF",
  GRATITUDE_NOTE = "GRATITUDE_NOTE",
  BLAST_PAST = "BLAST_PAST",
  MEANINGFUL_CONVERSATION = "MEANINGFUL_CONVERSATION",
  SOMEBODY_WHO = "SOMEBODY_WHO",
}

// This enum sets ID types to look up ActivityComplete types
//
export enum ActivityCompleteType {
  DEFAULT = "DEFAULT",
}

export enum ActivityCardColor {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Mint = "mint",
  Cyan = "cyan",
  Mist = "mist",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
}

export enum PulseScale {
  UNKNOWN = -1,
  "Really terrible" = 0,
  "Somewhat bad" = 1,
  "Okay" = 2,
  "Pretty good" = 3,
  "Super awesome" = 4,
}

/**
 * This enum provides constants for specific error cases for when
 * a user attempts to add a buddy with the invite link flow.
 */
export enum BuddyCreateErrorCode {
  BUDDY_NOT_FOUND = "BUDDY_NOT_FOUND",
  INVALID_REFERRAL = "INVALID_REFERRAL",
  BUDDY_EXISTS = "BUDDY_EXISTS",
  DATABASE_ERROR = "DATABASE_ERROR",
}

/**
 * Constants for the user's goals for using Flourish, which the user selects during onboarding.
 * Currently we are not using this information but storing to the database for future use in
 * personalization.
 */
export enum Goal {
  IMPROVE_MOOD = "IMPROVE_MOOD",
  REDUCE_STRESS = "REDUCE_STRESS",
  DEEPEN_RELATIONSHIPS = "DEEPEN_RELATIONSHIPS",
  DISCOVER_MEANING = "DISCOVER_MEANING",
}
// FCM topics
export enum Topic {
  TOPIC_TEST_CONNECTION = 'testConnection',
};

/**
 * Constants describing where the user discovered Flourish.
 */
export enum OriginType {
  UNKNOWN = "UNKNOWN",
  ORG_OR_TEAM = "ORG_OR_TEAM",
  FLOURISH_TEAM = "FLOURISH_TEAM",
  FRIENDS_OR_FAMILY = "FRIENDS_OR_FAMILY",
  OTHER = "OTHER",
}

/**
 * Server errors that may occur while adding user origin info to the database.
 */
export enum AddUserOriginInfoErrorCode {
  USER_ORIGIN_INFO_REQUIRED = "USER_ORIGIN_INFO_REQUIRED",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
}
