import { useNavigate } from "react-router-dom";
import { CurrentActivityCard } from "../../components/activity-card";
import { ACTIVITY_IMAGE_LOOKUP } from "../../data/week-activities-mock";
import { TypeformButtonLink } from "../../components/button";
import { Header } from "../../components/header";
import StarImage from "../../../assets/accessories/star.svg";
import ArrowImage from "../../../assets/accessories/arrow.svg";
import CloverImage from "../../../assets/accessories/clover.svg";
import HeartSun from "../../../assets/illustrations/heart-sun.svg";
import { Card, CardColor } from "../../components/card";
import { LoadingState } from "../../components/loading";
import { ErrorState } from "../../components/error";
import { useTypeform, useGetActivityPage } from "../../controllers/hooks";
import { IUser } from "../../../client-server-shared/response-types";
import { insightsPageLink } from "../../util/relative-links";
import { JourneyCounter } from "../../components/journey";
import { ActivityCompleted } from "../../components/activity-completed/ActivityCompleted";
import { FeedbackCard } from "../../components/feedback-card";
import { DailyMoodEdgeCaseCard } from "../../components/daily-mood/DailyMoodComponents";

// Importing files related to measurement survey change
import Leaf from "../../../assets/accessories/leaf.svg";
import { FLOURISHING_SCALE_FORM_ID } from "../../util/typeform-constants";

// Importing files related to Share card component
import { ShareCard } from "../../components/share-card";
import { PulseCard } from "../../components/pulse-card/PulseCard";
import { IncompletePulseCard } from "../../components/pulse-card/IncompletePulseCard";
interface Props {
  user: IUser;
}

export const FullyUnlockedActivityPage = ({ user }: Props) => {
  const navigate = useNavigate();
  const {
    data: activityPageData,
    loading: activityPageLoading,
    error: activityPageError,
  } = useGetActivityPage();
  useTypeform(activityPageLoading);

  if (activityPageLoading) {
    return <LoadingState />;
  }
  if (activityPageError || !activityPageData) {
    return <ErrorState />;
  }

  // Deconstruct the ActivityPageResponse object
  const { showProfileUpdated, shareCardData, todaysPulseResults } = activityPageData;
  const host = window.location.host;
  const dataTfHidden = `id=${user._id},host=${host}`;

  return (
    <div className="space-y-6 md:space-y-12 pb-[60px] md:pb-[120px]">
      <header className="pl-3 space-y-12">
        <h1 className="font-bold text-2xl">
          <span className="text-teal-400">Flourish</span>
          <br />
          <span className="text-teal-400">Together</span> with <br />
          Science
        </h1>
      </header>
      {!user.flourishingScaleResult && (
        <Card color={CardColor.Blue}>
          <div className="container md:px-[55px] sm:px-4 max-w[913px] min-h-[111px] flex items-center justify-between">
            <div>
              <h2 className="font-semibold text-base text-teal-700">
                Welcome to Flourish{" "}
                <span role="img" aria-label="sheep">
                  🎉
                </span>
              </h2>
              <p className="font-normal text-sm text-teal-700">Time for a baseline check-in!</p>
            </div>
            <div className="relative pt-4">
              <TypeformButtonLink
                typeformLink={{ id: FLOURISHING_SCALE_FORM_ID, hiddenData: dataTfHidden }}
              >
                Start
              </TypeformButtonLink>
              <img src={Leaf} className="absolute bottom-[39px] right-[-8px]" />
            </div>
          </div>
        </Card>
      )}
      {/* Journey Counter */}
      <JourneyCounter daysActive={user?.daysActive} lastActive={user.lastActive} />

      {/* Profile Updated Card */}
      {showProfileUpdated && (
        <Card color={CardColor.Gray}>
          <div className="bg-teal-0 rounded-3xl pl-3 pr-3 pt-4 pb-6 space-y-3">
            <div className="flex flex-row items-center">
              <img className="pr-3" src={HeartSun} />
              <div className="text-teal-600 text-base font-semibold">Flourish Profile updated!</div>
            </div>

            <div className="pl-3">
              <div className="text-teal-800 text-sm font-normal">
                You are both a masterpiece and a work in progress. We’re glad to meet you where you
                are, and we’ll support you and grow with you along this journey.
              </div>
            </div>

            <div className="pl-3">
              <button onClick={() => navigate(insightsPageLink())}>
                <div className="underline text-sm font-semibold text-teal-600">See my profile</div>
              </button>
            </div>
          </div>
        </Card>
      )}

      {/* Activity Complete Message */}
      {user.activityCompleteMessage && (
        <section className="space-y-4">
          <Header text="Your Progress" icon={StarImage} />
          <ActivityCompleted activityCompleteMessage={user.activityCompleteMessage} />
        </section>
      )}

      {/* Featured Activity Card */}
      {user.todaysActivity && (
        <section className="space-y-4">
          <Header text="Today's Flourish Activity" icon={StarImage} />
          {user.todaysActivity && (
            <CurrentActivityCard
              todaysActivity={user.todaysActivity}
              backgroundImage={{
                data: ACTIVITY_IMAGE_LOOKUP[user.todaysActivity.images.large],
                position: "top-center",
              }}
              linkConfig={{
                id: user.todaysActivity.typeformId,
                hiddenData: dataTfHidden,
              }}
            />
          )}
        </section>
      )}

      {/* Share Card */}
      {shareCardData && (
        <section className="space-y-4">
          <Header text="Share Your Experience" icon={ArrowImage} />
          <ShareCard shareCardData={shareCardData} />
        </section>
      )}

      {/* Flourish Pulse */}
      <section className="space-y-4">
        <Header text="Daily Flourish Pulse" icon={CloverImage} />
        {todaysPulseResults && todaysPulseResults.length > 0 ? (
          <PulseCard pulseRecords={todaysPulseResults} />
        ) : (
          <DailyMoodEdgeCaseCard/>
        )}
      </section>
      <FeedbackCard />
    </div>
  );
};
