export const DOMAIN_URL = "/api";

// ========== auth/ ==========
export const REGISTER_LOGIN_USER_URL = DOMAIN_URL + "/auth/registerLogin";
export const ADD_USER_ORIGIN_INFO_URL = DOMAIN_URL + "/auth/addUserOriginInfo";
export const GOOGLE_LOGIN_URL = DOMAIN_URL + "/auth/googleLogin";
export const SIGNOUT_USER_URL = DOMAIN_URL + "/auth/logout";
export const GET_IS_EMAIL_ADDRESS_AVAILABLE_URL = DOMAIN_URL + "/auth/isEmailAddressAvailable";
export const SEND_RESET_PASSWORD_EMAIL_URL = DOMAIN_URL + "/auth/sendResetPasswordEmail";
export const RESET_PASSWORD_URL = DOMAIN_URL + "/auth/resetPassword";

// ========== user/ ==========
export const GET_USER_URL = DOMAIN_URL + "/user/getUser";
export const GET_USER_DISPLAY_NAME = DOMAIN_URL + "/user/getDisplayName";
export const PATCH_USER_LAST_INVITATION_DATE = DOMAIN_URL + "/user/patchLastInvitationDate";
export const BLOCK_USER_URL = DOMAIN_URL + "/user/blockUser";
export const DELETE_USER_URL = DOMAIN_URL + "/user/delete";

// ========== onboarding/ ==========
export const COMPLETE_ONBOARDING_USER_URL = DOMAIN_URL + "/onboarding/completeOnboarding";
export const SAVE_GOALS_URL = DOMAIN_URL + "/onboarding/saveGoals";

// ========== community/ ==========
export const GET_COMMUNITY_FEED_URL = DOMAIN_URL + "/community/feed";
export const GET_COMMUNITY_POST_IMAGE_URL = DOMAIN_URL + "/community/image";

// ========== communityPost/ ==========
export const CREATE_COMMUNITY_POST_URL = DOMAIN_URL + "/communityPost/create";
export const CREATE_CUSTOM_ACTIVITY_URL = DOMAIN_URL + "/communityPost/createCustomActivity";
export const UPDATE_COMMUNITY_POST_URL = DOMAIN_URL + "/communityPost/update";
export const DELETE_COMMUNITY_POST_URL = DOMAIN_URL + "/communityPost/delete";
export const TOGGLE_POST_REACTION_URL = DOMAIN_URL + "/communityPost/toggleReaction";

// ========== buddies/ ==========
export const CREATE_BUDDIES_URL = DOMAIN_URL + "/buddies/create";
export const DELETE_BUDDIES_URL = DOMAIN_URL + "/buddies/delete";
export const LIST_BUDDIES_URL = DOMAIN_URL + "/buddies/list";

// ========== organization/ ==========
export const LIST_ORG_URL = DOMAIN_URL + "/organization/list";
export const LIST_ORG_NAMES_URL = DOMAIN_URL + "/organization/listNames";
export const REMOVE_ORG_URL = DOMAIN_URL + "/organization/remove";
export const CREATE_ORG_URL = DOMAIN_URL + "/organization/create";
export const UPDATE_ORG_URL = DOMAIN_URL + "/organization/update";

// ========== invitation-code/ ==========
export const VALIDATE_INVITATION_CODE = DOMAIN_URL + "/invitation-code/validate";
export const CREATE_INVITATION_CODE = DOMAIN_URL + "/invitation-code/create";
export const APPLY_INVITATION_CODE = DOMAIN_URL + "/invitation-code/apply";
export const GET_INVITATION_CODE = DOMAIN_URL + "/invitation-code/search";
export const GET_INVITATION_CODE_LIST = DOMAIN_URL + "/invitation-code/list";

// ========== notifications/ ==========
export const SUBSCRIBE_FCM_NOTIFICATIONS_URL = DOMAIN_URL + "/notifications/subscribeFCM";
export const UNSUBSCRIBE_FCM_NOTIFICATIONS_URL = DOMAIN_URL + "/notifications/unsubscribeFCM";
export const SUBSCRIBE_NOTIFICATIONS_URL = DOMAIN_URL + "/notifications/subscribe"; // Mozilla Push-API
export const UNSUBSCRIBE_NOTIFICATIONS_URL = DOMAIN_URL + "/notifications/unsubscribe"; // Mozilla Push-API

// ========== activity/ ==========
export const SHUFFLE_TODAYS_ACTIVITY = DOMAIN_URL + "/activity/shuffle";

// ========== pulse/ ==========
export const POST_PULSE_FINISH_URL = DOMAIN_URL + "/pulse/submit";
export const UPDATE_PULSE_SCIENCE_SNIPPET_SHOWN_URL = DOMAIN_URL + "/pulse/updateScienceSnippetShown";
export const ADD_CUSTOM_PULSE_KEYWORD_URL = DOMAIN_URL + "/pulse/addCustomKeyword";

// ========== pulseChatbot/ ==========
export const POST_PULSE_CHATBOT_INIT = DOMAIN_URL + "/pulseChatbot/init";
export const POST_PULSE_CHATBOT_SEND = DOMAIN_URL + "/pulseChatbot/send";
export const POST_PULSE_CHATBOT_END = DOMAIN_URL + "/pulseChatbot/end";

// ========== pages/ ==========
export const GET_ACTIVITY_PAGE_URL = DOMAIN_URL + "/pages/activity";
export const GET_INSIGHTS_PAGE_URL = DOMAIN_URL + "/pages/insights";

// ========== admin/ ==========
export const MIGRATE_OOD_USER_URL = DOMAIN_URL + "/admin/migrateOODUser"; // Migrate old OOD user data

// ========== other ==========
export const GET_VAPID_PUBLIC_KEY_URL = DOMAIN_URL + "/getVapidPublicKey";
export const GET_DAILY_RECORD_PAGE_URL = DOMAIN_URL + "/dailyRecord";