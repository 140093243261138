import { useState } from "react";
import { CommunityFeedType } from "../../../client-server-shared/enums";
import { BottomSheet } from "../bottom-sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button } from "../button/Button";

interface PrivacySelectorProps {
  onChange: (value: CommunityFeedType) => void;
  onRemove?: () => void;
  onClose: () => void;
  org?: string;
  value: CommunityFeedType;
  buttonRef: React.RefObject<HTMLButtonElement>;
}

export const PrivacySelector = ({
  onChange,
  onRemove,
  onClose,
  org,
  value,
  buttonRef,
}: PrivacySelectorProps) => {
  const [privacySelection, setPrivacySelection] = useState<CommunityFeedType>(value);
  return (
    <BottomSheet onClose={onClose} buttonRef={buttonRef}>
      <div>
        <h2 className="font-bold text-xl text-teal-400 pb-3">
          Who can see this?
        </h2>
        <PrivacyOption
          onChange={() => {
            setPrivacySelection(CommunityFeedType.Buddies);
          }}
          value={CommunityFeedType.Buddies}
          label="My Buddies"
          checked={privacySelection === CommunityFeedType.Buddies}
        />
        <PrivacyOption
          onChange={() => {
            setPrivacySelection(CommunityFeedType.Organization);
          }}
          value={CommunityFeedType.Organization}
          label="My Group"
          subLabel={
            org ? `Members of ${org}'s flourishing group` : "Join a group to unlock"
          }
          checked={privacySelection === CommunityFeedType.Organization}
          disabled={!org}
        />
        <PrivacyOption
          onChange={() => {
            setPrivacySelection(CommunityFeedType.World);
          }}
          value={CommunityFeedType.World}
          label="The World!"
          subLabel="Anyone on Flourish"
          checked={privacySelection === CommunityFeedType.World}
        />
        {onRemove && (
          <div onClick={onRemove} className="flex items-center cursor-pointer pt-8">
            <FontAwesomeIcon
              icon={icon({ name: "trash" })}
              className="text-[#D44532] p-4 h-4 w-4"
            />
            <div className="flex-grow">
              <span className="text-[#B12C1D] font-bold text-md block">Delete this post</span>
              <span className="text-[#B12C1D] text-sm block">
                You will not be able to recover this
              </span>
            </div>
          </div>
        )}
      </div>
      <Button 
        className="mt-7 w-full"
        onClick={() => onChange(privacySelection)}
        
      >
        Confirm
      </Button>
    </BottomSheet>
  );
};

interface PrivacyOptionProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "className" | "type"> {
  value: string;
  label: string;
  subLabel?: string;
  disabled?: boolean;
}

const PrivacyOption = ({ disabled, value, label, subLabel, ...rest }: PrivacyOptionProps) => {
  return (
    <label className="flex items-center py-1.5 text-gray-400">
      <div className="inline-block h-12 w-12 flex items-center justify-center">
        <input
          disabled={disabled}
          value={value}
          type="radio"
          name="privacy"
          className="accent-teal-500"
          {...rest}
        />
      </div>
      <div className="inline-block">
        <span className="font-bold">{label}</span>
        <br />
        <span className="text-sm">{subLabel}</span>
      </div>
    </label>
  );
};
