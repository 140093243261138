import { IDayRecord } from "../../../client-server-shared/response-types";
import { GET_DAILY_RECORD_PAGE_URL } from "../helper/requestConstants";
import getData from "../helper/getData";

const DEFAULT_LIMIT = 4;

export async function getDailyRecordPage(
  page = 1,
  limit = DEFAULT_LIMIT
): Promise<IDayRecord[]> {
  const response = await getData(
    GET_DAILY_RECORD_PAGE_URL + "?page=" + page + "&limit=" + limit
  );
  return response;
}