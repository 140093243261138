import { ButtonColor, ButtonSize } from "../../components/button";
import { ClickToCopyButton } from "../../components/click-to-copy";
import { IUser } from "../../../client-server-shared/response-types";
import { buddyAddLink } from "../../util/relative-links";
import {
  INVITE_BUDDY_BUTTON_TEXT,
  INVITE_BUDDY_CLIPBOARD_TEXT,
  INVITE_BUDDY_LINK_COPY_SUCCESS,
} from "../../data/strings";
import SunSpreadingHearts from "../../../assets/illustrations/sun-spreading-hearts.svg";
import GrassAndPlantFooter from "../../../assets/illustrations/grass-and-plant-footer.svg";

interface Props {
  user: IUser;
  onCommunityLinkPressed: () => void;
}

export const NoPostsFromBuddies = ({ user, onCommunityLinkPressed }: Props) => {
  const textToCopy = `${INVITE_BUDDY_CLIPBOARD_TEXT}${buddyAddLink()}${user._id}`;
  return (
    <div className="absolute top-0 left-0 relative bg-white h-screen w-screen">
      <div className="container mt-16">
        <p className="mb-4 text-sm text-[#00363B]">
          Looks like you&apos;ve got a blank canvas&mdash;
        </p>
        <p className="mb-12 text-sm w-2/3 text-[#00363B]">
          Start adding buddies to flourish together!
        </p>
        <div className="absolute right-0 -top-8 w-[140px] h-[256px] overflow-hidden">
          <img src={SunSpreadingHearts} className="ml-[40px] w-full h-full" />
        </div>
        <ClickToCopyButton
          buttonText={INVITE_BUDDY_BUTTON_TEXT}
          textToCopy={textToCopy}
          copiedSuccessMessage={INVITE_BUDDY_LINK_COPY_SUCCESS}
          size={ButtonSize.Large}
          color={ButtonColor.TealUnfilled}
        />
        <p className="mt-4 text-sm text-[#5C5F5C]">
          or view{" "}
          <a onClick={onCommunityLinkPressed} className="font-bold underline cursor-pointer">
            posts from our community
          </a>
        </p>
      </div>
      <img src={GrassAndPlantFooter} className="w-full fixed bottom-10 md:hidden" />
    </div>
  );
};
