import { IBuddyData, IBuddyCreateResponse } from "../../../client-server-shared/response-types";
import postData from "../helper/postData";
import {
  CREATE_BUDDIES_URL,
  DELETE_BUDDIES_URL,
  LIST_BUDDIES_URL,
} from "../helper/requestConstants";
import getData from "../helper/getData";

export async function create(buddyId: string): Promise<IBuddyCreateResponse> {
  const createBuddyResponse = await postData(CREATE_BUDDIES_URL, {
    buddyId: buddyId,
  });
  return createBuddyResponse;
}

export async function remove(buddyId: string): Promise<boolean> {
  const deleteBuddyRequest = await postData(DELETE_BUDDIES_URL, {
    buddyId: buddyId,
  });
  return Boolean(deleteBuddyRequest.success);
}

// Fetch list of buddies from the server for the currently logged in user.
export async function list(): Promise<IBuddyData[]> {
  const response = await getData(LIST_BUDDIES_URL);
  return response.buddies;
}
