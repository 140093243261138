import { useEffect, useState } from "react";
import { IBuddyData } from "../../../client-server-shared/response-types";
import { QueryReturn } from "./util";
import { list } from "../ajax/BuddiesController";

// Attempts to fetch data representing the user's buddies.
export const useGetBuddyList = (): QueryReturn<IBuddyData[]> => {
  const [data, setData] = useState<IBuddyData[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    list()
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    data,
    loading,
    error,
  };
};
