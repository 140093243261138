import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import AddBuddyIcon from "../../../assets/icons/icon-add-buddy.svg";
import { IUser } from "../../../client-server-shared/response-types";
import {
  communityPageLink,
  buddiesPageLink,
  postToCommunityPageLink,
  buddyAddLink,
} from "../../util/relative-links";
import {
  INVITE_BUDDY_BUTTON_TEXT,
  INVITE_BUDDY_CLIPBOARD_TEXT,
  INVITE_BUDDY_LINK_COPY_SUCCESS,
} from "../../data/strings";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { ClickToCopyButton } from "../../components/click-to-copy";
import { ButtonSize } from "../../components/button";
import { PopDownMenu } from "../../components/pop-down-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { TabPanelContent } from "./TabPanelContent";
import { CommunityFeedType } from "../../../client-server-shared/enums";
import { getOrganizationUserCounts } from "../../controllers/ajax/OrganizationController";

const TabViewMapping: { [key: string]: number } = {
  [CommunityFeedType.Buddies]: 0,
  [CommunityFeedType.Organization]: 1,
  [CommunityFeedType.World]: 2,
};

interface Props {
  user: IUser;
}

export const Community = ({ user }: Props) => {
  const [userCount, setUserCount] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tabView = searchParams.get("view");
  const [showAddBuddyMenu, setShowAddBuddyMenu] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(TabViewMapping[tabView ?? "buddies"]);
  const addBuddyMenuButtonRef = useRef(null);
  const textToCopy = `${INVITE_BUDDY_CLIPBOARD_TEXT}${buddyAddLink()}${user._id}`;
  useEffect(() => {
    if (user.organization && user.organization._id) {
      getOrganizationUserCounts(user.organization._id).then((count: number) => {
        setUserCount(count);
      });
    }
  }, [userCount]);

  return (
    <div className="md:mt-12 relative">
      <div className="container">
        <div className="fixed top-0 left-0 right-0 md:static md:top-12 md:left-auto py-5 flex justify-end items-center bg-white md:py-0">
          {/* TODO: Could be refacored into TopNavBar. */}
          <div className="pr-2 hidden md:flex gap-4 relative">
            <button
              onClick={() => setShowAddBuddyMenu(!showAddBuddyMenu)}
              ref={addBuddyMenuButtonRef}
              className="flex items-center border-solid	border border-teal-700 rounded-3xl w-34 justify-between pl-1 pr-4 h-[40px]"
            >
              <img src={AddBuddyIcon} className="w-[40px]"/><span className="text-teal-700 text-sm">Add Buddy</span>
            </button>
            {showAddBuddyMenu && (
              <PopDownMenu
                positionRight={true}
                onClose={() => setShowAddBuddyMenu(false)}
                buttonRef={addBuddyMenuButtonRef}
              >
                <ClickToCopyButton
                  buttonText={INVITE_BUDDY_BUTTON_TEXT}
                  textToCopy={textToCopy}
                  copiedSuccessMessage={INVITE_BUDDY_LINK_COPY_SUCCESS}
                  size={ButtonSize.Full}
                />
              </PopDownMenu>
            )}
            <button 
              onClick={() => navigate(buddiesPageLink())} 
              className="flex items-center border-solid	border border-teal-700 rounded-3xl w-34 justify-between pl-1 pr-4 h-[40px]"
            >
              <FontAwesomeIcon icon={icon({ name: "users" })} className="text-[#11454E] w-[40px]" />
              <span className="text-teal-700 text-sm">Buddy List</span>
            </button>
          </div>
        </div>
        <h1 className="font-bold text-2xl mt-6 mb-4 pt-6 pb-4 text-[#004F56] md:pt-0 md:mt-0">
          My Community
        </h1>
      </div>

      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
        selectedTabClassName="text-teal-400 border-b-2 border-teal-400"
      >
        <div className="border-b border-gray-100">
          <TabList className="container cursor-pointer">
            <Tab
              className="w-1/3 text-sm inline-block text-center font-bold pb-2 text-[#8F918E] focus:outline-none"
              onClick={() => navigate(`${communityPageLink()}?view=${CommunityFeedType.Buddies}`)}
            >
              BUDDIES
            </Tab>
            <Tab
              className="w-1/3 text-sm inline-block text-center font-bold pb-2 text-[#8F918E] focus:outline-none"
              onClick={() =>
                navigate(`${communityPageLink()}?view=${CommunityFeedType.Organization}`)
              }
            >
              GROUP
            </Tab>
            <Tab
              className="w-1/3 text-sm inline-block text-center font-bold pb-2 text-[#8F918E] focus:outline-none"
              onClick={() => navigate(`${communityPageLink()}?view=${CommunityFeedType.World}`)}
            >
              WORLD
            </Tab>
          </TabList>
        </div>
        {/* TODO(FS-371): `240px` is hardcoded for now. This is so that the feed is the browser's screen minus the header and footer components. */}
        <div className="bg-[#F0F1ED] w-full min-h-[calc(100vh-240px)] relative">
          <TabPanel>
            <TabPanelContent
              user={user}
              type={CommunityFeedType.Buddies}
              onCommunityLinkPressed={() => setTabIndex(1)}
            />
          </TabPanel>
          <TabPanel>
            <TabPanelContent
              user={user}
              type={CommunityFeedType.Organization}
              userCount={userCount}
            />
          </TabPanel>
          <TabPanel>
            <TabPanelContent user={user} type={CommunityFeedType.World} />
          </TabPanel>
        </div>
      </Tabs>

      {/* Entrance button to Post To Community */}
      <button
        className="drop-shadow fixed bottom-[110px] right-[16px] w-[56px] h-[56px] rounded-full bg-white flex items-center justify-center md:right-[64px]"
        onClick={() => navigate(postToCommunityPageLink())}
      >
        <FontAwesomeIcon icon={icon({ name: "pen" })} className="text-[#006972]" />
      </button>
    </div>
  );
};
