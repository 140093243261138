import { Card } from "../card/Card";
import { CardColor } from "../card/card-util";
import { ACTIVITY_COMPLETED_IMAGE_LOOKUP } from "../../data/week-activities-mock";
import { IActivityCompleteMessage } from "../../../client-server-shared/response-types";
import ConfettiBackground from "../../../assets/activity-completed/confetti-background.svg";

interface ActivityCompletedProps {
  activityCompleteMessage: IActivityCompleteMessage;
}

export const ActivityCompleted = ({ activityCompleteMessage }: ActivityCompletedProps) => {
  return (
    <Card
      color={CardColor.Default}
      backgroundImage={{ data: ConfettiBackground, position: "top-center" }}
    >
      <div className="flex justify-between items-center gap-10 p-5">
        <img src={ACTIVITY_COMPLETED_IMAGE_LOOKUP[activityCompleteMessage.icon]} alt="" />
        <p className="text-[#FFDD95] font-bold">{activityCompleteMessage.message}</p>
      </div>
    </Card>
  );
};
