import { useState } from "react";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { emailAddressPattern } from "../../util/constants";
import { migrateOODUser } from "../../controllers/ajax/MigrateOODUserController";
import { IMigrateOODUserRequest } from "../../../client-server-shared/response-types";

export const MigrateOODUser = () => {
  const [migratingUser, setMigratingUser] = useState<boolean>(false);
  const [oldUserId, setOldUserId] = useState<string>("");
  const [newUserEmail, setNewUserEmail] = useState<string>("");
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMigratingUser(true);
    alert("Migrating...");
    try {
      if (!emailAddressPattern.test(newUserEmail)) {
        alert("Please provide a valid email address");
        return;
      }
      const request: IMigrateOODUserRequest = {
        oldUserId: oldUserId,
        newUserEmail: newUserEmail,
      }
      const response = await migrateOODUser(request);
      if (response) {
        alert(`Migrated ${newUserEmail} successfully`);
      } else {
        alert("Failed");
      }
    } catch (e) {
      alert("Failed");
      console.error(e);
    } finally {
      setMigratingUser(false);
    }
  };
  return (
    <div>
      <header className="space-y-12">
        <h1 className="font-bold text-2xl">
          <span className="text-teal-400">Migrate OOD User</span>
        </h1>
      </header>
      <form className="flex gap-6 pt-4" onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <h2 className="font-medium">
            User's OLD User Id:
          </h2>
          <Input
            id="oldUserId"
            name="oldUserId"
            type="text"
            value={oldUserId}
            onChange={(e) => setOldUserId(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <h2 className="font-medium">
            User's NEW Email Address:
          </h2>
          <Input
            id="newUserEmail"
            name="newUserEmail"
            type="text"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />
        </div>
        <Button type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};