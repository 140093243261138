import { Header } from "../../components/header";
import { LoadingState } from "../../components/loading";
import { ErrorState } from "../../components/error";
import { useGetInsightsPage } from "../../controllers/hooks/GetInsightsPageHook";
import { IUser } from "../../../client-server-shared/response-types";
import classNames from "classnames";
import { ScaleDetailsCard } from "../../components/scale-details-card/ScaleDetailsCard";
import { LockedScaleDetailsCard } from "../../components/scale-details-card/LockedScaleDetailsCard";
import { DailyRecord } from "./DailyRecord";

interface Props {
  user: IUser;
}

export const InsightsPage = ({ user }: Props) => {
  const {
    data: insightsPageData,
    loading: insightsPageLoading,
    error: insightsPageError,
  } = useGetInsightsPage();

  if (insightsPageLoading) {
    return <LoadingState />;
  }
  if (insightsPageError || !insightsPageData) {
    return <ErrorState />;
  }

  // Deconstruct the InsightsPageResponse object
  const { futureLetterData } = insightsPageData;

  return (
    <>
      <section className="space-y-4">
        <h1 className="font-bold text-2xl mt-6 mb-4 pt-4 pb-4 text-[#004F56]">
          <span className="text-teal-400">Flourish</span> Insights
        </h1>
        {user.flourishingScaleResult ? (
          <ScaleDetailsCard flourishingScaleResult={user.flourishingScaleResult} />
        ) : (
          <LockedScaleDetailsCard userId={user._id} />
        )}
      </section>

      <DailyRecord />

      <section className="space-y-4">
        <Header text="Letter from my Future Self" />
        {futureLetterData && futureLetterData.length > 0 ? (
          <div className={classNames("rounded p-1 font-medium", "bg-blue-0", "text-blue-800")}>
            {futureLetterData.map((data, index) => (
              <span key={index}>{data}</span>
            ))}
          </div>
        ) : (
          <div className="font-base"> Waiting for you to finish this activity. </div>
        )}
      </section>
    </>
  );
};
