import { useNavigate } from "react-router-dom";
import { IUser } from "../../../client-server-shared/response-types";
import { Button, ButtonColor } from "../button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { PageRoute } from "../../util/constants";

interface ManageAccountProps {
  user: IUser;
  handleNextButtonClick: () => void;
}

export const ManageAccount = ({ user, handleNextButtonClick }: ManageAccountProps) => {
  const navigate = useNavigate();
  const avatar = user.avatar ?? "default.svg";
  return (
    <div className="space-y-9 container py-10">
      {/* Header */}
      <header className="flex justify-between items-center">
        <span className="flex items-center">
          <button
            className="w-[44px] h-[44px] flex items-center justify-center"
            onClick={() => navigate(PageRoute.PROFILE)}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-teal-700" />
          </button>
          <h1 className="font-bold text-teal-700">Manage Account</h1>
        </span>
        <button
          className="w-[44px] h-[44px] flex items-center justify-center"
          onClick={() => navigate(PageRoute.PROFILE)}
        >
          <FontAwesomeIcon icon={faXmark} className="text-teal-700" />
        </button>
      </header>
      {/* Profile Info */}
      <div className="flex flex-col items-center w-full pt-8">
        <div className="flex flex-col items-center w-full gap-6">
          <img src={"/avatars/" + avatar} className="w-[83px]" />
          <div className="w-full">
            <label className="text-sm font-medium">Name</label>
            <h2 className="font-medium">{user.displayName}</h2>
          </div>
          <div className="w-full">
            <label className="text-sm font-medium">Email</label>
            <h2 className="font-medium">{user.emailAddress}</h2>
          </div>
        </div>
      </div>

      {/* Bottom Continue Button */}
      <div className="md:absolute fixed bottom-0 left-1/2 max-w-[1024px] transform -translate-x-1/2 w-full h-[120px] pb-12 flex flex-col items-center justify-end bg-gradient-overlay container md:px-[2rem]">
        <Button
          className="w-full z-40 max-h-[40px] max-w-[480px]"
          color={ButtonColor.RedUnFilled}
          onClick={handleNextButtonClick}
        >
          Delete my Account
        </Button>
      </div>

    </div>
  )
}