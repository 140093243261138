interface Props {
  color: string;
}

export const ShareIcon = ({ color }: Props) => {
  return (
    <svg width="13" height="14" viewBox="0 0 16 17" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.58646 10.582C4.93603 10.582 6.03007 9.488 6.03007 8.13843C6.03007 6.78887 4.93603 5.69482 3.58646 5.69482C2.23689 5.69482 1.14285 6.78887 1.14285 8.13843C1.14285 9.488 2.23689 10.582 3.58646 10.582Z"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0695 5.88283C13.419 5.88283 14.5131 4.78878 14.5131 3.43922C14.5131 2.08965 13.419 0.995605 12.0695 0.995605C10.7199 0.995605 9.62585 2.08965 9.62585 3.43922C9.62585 4.78878 10.7199 5.88283 12.0695 5.88283Z"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0695 15.2813C13.419 15.2813 14.5131 14.1872 14.5131 12.8377C14.5131 11.4881 13.419 10.394 12.0695 10.394C10.7199 10.394 9.62585 11.4881 9.62585 12.8377C9.62585 14.1872 10.7199 15.2813 12.0695 15.2813Z"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.71964 5.99959C6.95103 4.74106 7.08644 4.73046 9.62584 4.01416"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.71964 10.2775C6.95103 11.536 7.08644 11.5466 9.62584 12.2629"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
