/*
 * Utils for Firebase
 */

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { IUser } from "../../client-server-shared/response-types";
import { OriginType } from "../../client-server-shared/enums";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCL0ZmfGMxIlEbjHTk6mJbbp23sjwCo9JA",
    authDomain: "flourish-74072.firebaseapp.com",
    projectId: "flourish-74072",
    storageBucket: "flourish-74072.appspot.com",
    messagingSenderId: "386083300467",
    appId: "1:386083300467:web:bd3ff1cd5775fa67e8b606",
    measurementId: "G-JR4F3KMM1J"
};

export function initFirebase() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);
  // Initialize Analytics
  const analytics = getAnalytics(app);
}

// Log user properties which can be used in the Firebase console to break down/better understand 
// user segmentation.
export function logUserProperties(user : IUser) {
  if (user?.userOrigin !== undefined) {
    let userOrigin: String = user.userOrigin.type;
    if (user.userOrigin.type == OriginType.ORG_OR_TEAM) {
      userOrigin += ": " + user.userOrigin.orgName;
    } else if (user.userOrigin.type == OriginType.OTHER) {
      userOrigin += ": " + user.userOrigin.otherText;
    }
    // See https://firebase.google.com/docs/analytics/user-properties for more details on logging
    // custom user properties.
    const analytics = getAnalytics();
    setUserProperties(analytics, { user_origin: userOrigin })
  }
}

// Server (Firebase Admin SDK) requires the registration token to send message to a specific device
export async function sendRegistrationTokenToServer(sendFunction: CallableFunction) {
    const messaging = getMessaging();
    // The 'getToken' function tells FCM the VAPID Key to use web push services.
    await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_KEY }).then((currentToken) => {
        if (currentToken) {
          sendFunction(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving registration token. ', err);
      });
}
