import { useState, useEffect } from "react";
import { deleteUser } from "../../controllers/ajax/UserController";
import { Button, ButtonColor } from "../button/Button";
import { Card } from "../card/Card";
import { CardColor } from "../card/card-util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import SunnieStraightFace from "../../../assets/illustrations/sunnie-straight-face.svg";

interface DeleteAccountProps {
  handleBackButtonClick: () => void;
}

export const DeleteAccount = ({ handleBackButtonClick }: DeleteAccountProps) => {
  const [countdown, setCountdown] = useState<number>(5);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  useEffect(() => {
    // If the countdown is 0, enable the button
    if (countdown === 0) {
      setButtonDisabled(false);
      return;
    }

    // Countdown logic
    const timer = setTimeout(() => {
      setCountdown((currentCountdown) => currentCountdown - 1);
    }, 1000);

    // Clear the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [countdown])

  const handleOnDelete = async () => {
    setIsDeleting(true);
    const response = await deleteUser();
    if (response) {
      window.location.href = "/";
    }
    else {
      setIsDeleting(false);
      console.error("Failed deleting account.");
      // TODO: Add an error message popup
    }
  }
  return (
    <div className="space-y-9 container py-10">
      {/* Header */}
      <header className="flex justify-between items-center">
        <span className="flex items-center">
          <h1 className="font-bold text-red-400">Delete Account</h1>
        </span>
        <button
          className="w-[44px] h-[44px] flex items-center justify-center"
          onClick={handleBackButtonClick}
        >
          <FontAwesomeIcon icon={faXmark} className="text-teal-700" />
        </button>
      </header>
      {/* Profile Info */}
      <div className="flex flex-col items-center w-full pt-8">
        <div className="flex flex-col items-center w-full gap-6">
          <img src={SunnieStraightFace} className="w-[83px]" />
          <Card color={CardColor.Red} className="p-6 max-w-[480px]">
            <p className="font-bold text-red-400">
              Please note, deleting your account will result in the permanent removal of all your data, which cannot be retrieved later.<br/><br/>Do you wish to proceed?
            </p>
          </Card>
        </div>
      </div>

      {/* Bottom Continue Button */}
      <div className="md:absolute fixed bottom-0 left-1/2 transform max-w-[1024px] -translate-x-1/2 w-full h-[120px] pb-12 gap-5 flex flex-col items-center justify-end bg-gradient-overlay container md:px-[2rem]">
        <Button
          className="w-full z-40 max-w-[480px]"
          color={(buttonDisabled || isDeleting) ? ButtonColor.Disabled : ButtonColor.RedUnFilledBorder}
          onClick={handleOnDelete}
          disabled={buttonDisabled || isDeleting}
        >
          {isDeleting ? "Deleting..." : (buttonDisabled ? `Yes, delete my account (${countdown}s)` : "Yes, delete my account")}
        </Button>
        <Button
          className="w-full z-40 max-w-[480px]"
          color={ButtonColor.TealUnfilled}
          onClick={handleBackButtonClick}
        >
          I will think about it
        </Button>
      </div>
    </div>
  )
}