import { IOrganization, IUser } from "../../../client-server-shared/response-types";
import {
  CREATE_ORG_URL,
  DOMAIN_URL,
  LIST_ORG_NAMES_URL,
  LIST_ORG_URL,
  REMOVE_ORG_URL,
  UPDATE_ORG_URL,
} from "../helper/requestConstants";
import getData from "../helper/getData";
import postData from "../helper/postData";

/** 
 * Returns a promise containing an array of the organization names in our database. Differs from 
 * getOrganizations(), which returns the full Organization object. 
 */
export async function getOrganizationNames(): Promise<string[]> {
  const response = await getData(LIST_ORG_NAMES_URL);
  return response.organizationNames;
}
export async function getOrganizations(): Promise<IOrganization[]> {
  const response = await getData(LIST_ORG_URL);
  return response.organizations;
}
export async function create(org: IOrganization): Promise<boolean> {
  const createPostResponse = await postData(CREATE_ORG_URL, org);
  return Boolean(createPostResponse.success);
}

export async function update(org: IOrganization): Promise<boolean> {
  const response = await postData(UPDATE_ORG_URL, {
    organization: org,
  });
  return Boolean(response.success);
}

export async function remove(name: string): Promise<boolean> {
  const response = await postData(REMOVE_ORG_URL, {
    name: name,
  });
  return Boolean(response.success);
}

export async function getOrganizationUserCounts(organizationId: string): Promise<number> {
  const url = `${DOMAIN_URL}/organization/${organizationId}/users/count`;
  const response = await getData(url);
  const count: number = response;
  return count;
}
