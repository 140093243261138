import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ProfileCard } from "../profile-card";
import {
  activityPageLink,
  communityPageLink,
  insightsPageLink,
  libraryPageLink,
  profilePageLink,
  buddiesPageLink,
  buddyAddLink,
} from "../../util/relative-links";
import { ENABLE_LINKS_TO_COMMUNITY, PageRoute } from "../../util/constants";
import {
  INVITE_BUDDY_BUTTON_TEXT,
  INVITE_BUDDY_CLIPBOARD_TEXT,
  INVITE_BUDDY_LINK_COPY_SUCCESS,
} from "../../data/strings";
import Logo from "../../../assets/navigation/flourish-logo.svg";
import { IUser } from "../../../client-server-shared/response-types";
import { PopDownMenu } from "../pop-down-menu";
import { ClickToCopyButton } from "../../components/click-to-copy";
import { ButtonSize } from "../../components/button";
import ArrowDown from "../../../assets/icons/icon-me-down.svg";
import AddBuddyIcon from "../../../assets/icons/icon-add-buddy.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Props {
  selected: string;
  user: IUser;
}

// Top navigation bar designed for laptop app.
export const TopNavBar = ({ selected, user }: Props) => {
  const navigate = useNavigate();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showAddBuddyMenu, setShowAddBuddyMenu] = useState<boolean>(false);
  const [isScreenWidthSmall, setIsScreenWidthSmall] = useState(window.innerWidth <= 920);
  const profileMenuButtonRef = useRef(null);
  const buddyButtonRef = useRef(null);
  const getNavButtonSelectedStyles = () => {
    return classNames(
      "flex items-center text-base font-bold text-teal-400 underline underline-offset-8"
    );
  };
  const getNavButtonDefaultStyles = () => {
    return classNames(
      "flex items-center text-base font-medium text-[#757874] hover:text-[#01929E] hover:underline hover:underline-offset-8"
    );
  };
  const avatar = user.avatar ?? "default.svg";
  const textToCopy = `${INVITE_BUDDY_CLIPBOARD_TEXT}${buddyAddLink()}${user._id}`;
  /**
   * This `useEffect` is used to listen for the current width of the window screen.
   * The purpose for this is to set the `positionRight` field for the Me card to `true` 
   * when the screen is 920px or less and `false` otherwise.
   */
  useEffect(() => {
    const handleResize = () => {
      setIsScreenWidthSmall(window.innerWidth <= 920);
    };

    // Set up the event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="w-full z-30 fixed">
      {/* Top Navigation */}
      <nav className="hidden md:block md:bg-white md:py-6">
        <div className="flex w-full items-center px-5">
          <a href={activityPageLink()} className="pr-8">
            <img src={Logo} className="h-[20px]" />
          </a>

          <button
            onClick={() => {
              navigate(activityPageLink());
              window.scrollTo({ top: 0 });
            }}
            disabled={selected === PageRoute.ACTIVITY && !showProfileMenu && true}
            className="pl-2 pr-8"
          >
            <span
              className={
                selected === PageRoute.ACTIVITY && !showProfileMenu
                  ? getNavButtonSelectedStyles()
                  : getNavButtonDefaultStyles()
              }
            >
              Today
            </span>
          </button>

          {ENABLE_LINKS_TO_COMMUNITY && (
            <button
              onClick={() => {
                navigate(communityPageLink());
                window.scrollTo({ top: 0 });
              }}
              disabled={selected === PageRoute.COMMUNITY && !showProfileMenu && true}
              className="pl-2 pr-8"
            >
              <span
                className={
                  selected === PageRoute.COMMUNITY && !showProfileMenu
                    ? getNavButtonSelectedStyles()
                    : getNavButtonDefaultStyles()
                }
              >
                Community
              </span>
            </button>
          )}

          <button
            onClick={() => {
              navigate(libraryPageLink());
              window.scrollTo({ top: 0 });
            }}
            disabled={selected === PageRoute.LIBRARY && !showProfileMenu && true}
            className="pl-2 pr-8"
          >
            <span
              className={
                selected === PageRoute.LIBRARY && !showProfileMenu
                  ? getNavButtonSelectedStyles()
                  : getNavButtonDefaultStyles()
              }
            >
              Library
            </span>
          </button>

          <button
            onClick={() => {
              navigate(insightsPageLink());
              window.scrollTo({ top: 0 });
            }}
            disabled={selected === PageRoute.INSIGHTS && !showProfileMenu && true}
            className="pl-2 pr-8"
          >
            <span
              className={
                selected === PageRoute.INSIGHTS && !showProfileMenu
                  ? getNavButtonSelectedStyles()
                  : getNavButtonDefaultStyles()
              }
            >
              Insights
            </span>
          </button>

          <div className="relative inline-block text-left">
            <button
              ref={profileMenuButtonRef}
              onClick={() => setShowProfileMenu(true)}
              className="pl-2"
            >
              <span
                className={
                  showProfileMenu ? getNavButtonSelectedStyles() : getNavButtonDefaultStyles()
                }
              >
                Me
                <img src={ArrowDown} />
              </span>
            </button>

            {showProfileMenu && (
              <PopDownMenu
                positionRight={isScreenWidthSmall}
                onClose={() => setShowProfileMenu(false)}
                buttonRef={profileMenuButtonRef}
              >
                <ProfileCard user={user} />
              </PopDownMenu>
            )}
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <div className="container md:hidden">
        <div className="fixed top-0 left-0 right-0 md:static md:top-12 md:left-auto py-5 flex justify-end items-center bg-white">
          <div className="pr-2">
            {selected === PageRoute.COMMUNITY && (
              <>
                <img
                  src={AddBuddyIcon}
                  onClick={() => setShowAddBuddyMenu(!showAddBuddyMenu)}
                  className="inline cursor-pointer"
                  ref={buddyButtonRef}
                />
                {showAddBuddyMenu && (
                  <PopDownMenu
                    positionRight={true}
                    onClose={() => setShowAddBuddyMenu(false)}
                    buttonRef={buddyButtonRef}
                  >
                    <ClickToCopyButton
                      buttonText={INVITE_BUDDY_BUTTON_TEXT}
                      textToCopy={textToCopy}
                      copiedSuccessMessage={INVITE_BUDDY_LINK_COPY_SUCCESS}
                      size={ButtonSize.Full}
                    />
                  </PopDownMenu>
                )}
                <button onClick={() => navigate(buddiesPageLink())} className="w-[44px] h-[44px]">
                  <FontAwesomeIcon icon={icon({ name: "users" })} className="text-[#11454E]" />
                </button>
              </>
            )}
            {(selected === PageRoute.LIBRARY ||
              selected === PageRoute.INSIGHTS ||
              selected === PageRoute.COMMUNITY) && (
                <img
                  onClick={() => navigate(profilePageLink())}
                  src={"avatars/" + avatar}
                  width="48"
                  className="inline p-2 cursor-pointer"
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
