interface HeaderProps {
  text: string;
  subText?: string;
  icon?: string;
}

export const Header = ({ text, subText, icon }: HeaderProps) => {
  return (
    <div className="flex items-center gap-x-[2px]">
      <img src={icon} className="block" />
      <div>
        <h1 className="font-bold text-lg">{text}</h1>
        {subText && <p>{subText}</p>}
      </div>
    </div>
  );
};
