import { ButtonColor, ButtonLink, ButtonSize } from "../../components/button";
import { activityPageLink, communityPageLink } from "../../util/relative-links";
import BadgeImage from "../../../assets/badges/badge.svg";
import { DotLottiePlayer } from "@dotlottie/react-player";
import { useLocation } from "react-router-dom";

// Page to show after the user has successfully submitted a community post.
export const CelebrateCommunityPost = () => {
  const location = useLocation();
  return (
    <div className="md:mt-12 container flex flex-col items-center">
      <DotLottiePlayer
        src="animations/Celebration.lottie"
        autoplay
        loop
        className="absolute z-0 -top-12 md:-top-8"
      />
      <div className="relative z-1 flex flex-col items-center">
        <h1 className="text-[#004F56] font-bold text-2xl my-16">Woohoo!</h1>
        {/* TODO(tddawson): Change badge based on type of activity */}
        <img src={BadgeImage} width="192" className="mb-12" />
        <p className="font-medium text-[#006972] w-2/3 text-center">
          You just took the time to nourish your well-being! Even the smallest win deserves a
          celebration!
        </p>
      </div>
      <div className="fixed right-4 left-4 bottom-8 bg-white md:static md:mt-16">
        <div className="mb-4">
          <ButtonLink
            to={
              location.state.privacyLevel // If a privacy level exists, setup searchParams to the community page
                ? `${communityPageLink()}?view=${location.state?.privacyLevel}`
                : communityPageLink()
              // TODO: Once private level posts are reintroduced, handle the case to redirect to Insights Page
            }
            size={ButtonSize.Full}
          >
            View My Post
          </ButtonLink>
        </div>
        <ButtonLink to={activityPageLink()} size={ButtonSize.Full} color={ButtonColor.TealUnfilled}>
          Back Home
        </ButtonLink>
      </div>
    </div>
  );
};
