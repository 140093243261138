async function deleteData(url = "") {
  const response = await fetch(url, {
    method: "DELETE",
    mode: "same-origin", 
    cache: "default",
    credentials: "include", 
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    redirect: "follow", 
  });
  return response.json();
}

export default deleteData;