import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../../client-server-shared/response-types";
import { signoutPageLink, buddyAddLink } from "../../util/relative-links";
import { ButtonColor, ButtonLink, ButtonNavigate, ButtonSize } from "../button";
import { ClickToCopyButton } from "../click-to-copy";
import ArrowLeft from "../../../assets/icons/icon-arrow-left.svg";
import { INVITE_BUDDY_BUTTON_TEXT, INVITE_BUDDY_CLIPBOARD_TEXT } from "../../data/strings";
import { PageRoute } from "../../util/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

interface Props {
  user: IUser;
}

export const ProfileCard = ({ user }: Props) => {
  const navigate = useNavigate();
  const textToCopy = `${INVITE_BUDDY_CLIPBOARD_TEXT}${buddyAddLink()}${user._id}`;
  const reportIssueUrl = "https://flouriship.typeform.com/issue#id=" + user._id;
  const avatar = user.avatar ?? "default.svg";
  const [emailString, setEmailString] = useState(user.emailAddress);

  /**
   * This useEffect will handle censoring the email based on the `hideEmail` state
   */
  useEffect(() => {
    const atIndex = user.emailAddress.indexOf("@");
    if (atIndex > 1) {
      const masked =
        user.emailAddress.charAt(0) + "*".repeat(atIndex - 1) + user.emailAddress.slice(atIndex);
      setEmailString(masked);
    } else {
      setEmailString(user.emailAddress); // fallback just in case email is malformed
    }
  }, []);

  return (
    <div className="space-y-12 relative">
      {/* Back button */}
      <button
        className="flex items-center justify-center pb-6 absolute top-[-104px] left-0 md:hidden"
        onClick={() => navigate(PageRoute.ACTIVITY)}
      >
        <img src={ArrowLeft} alt="" />
      </button>
      <div className="flex flex-col">
        <div className="flex pb-4 items-center">
          <img src={"avatars/" + avatar} width="36" className="mr-3" />
          <div className="w-full">
            <p className="font-accent font-bold text-lg">{user.displayName}</p>
            <div className="w-full flex justify-between relative">
              <p className="text-sm">{emailString}</p>
              {/* Hide mail button */}
              <button
                onClick={() => navigate(`${PageRoute.PROFILE}/manage/account`)}
                className="absolute right-0 top-[-66%] flex items-center justify-center w-[44px] h-[44px]"
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="text-[#444744] w-[16px]"
                />
              </button>
            </div>
          </div>
        </div>

        <hr className="border-teal-100 mt-4 mb-6" />
        <ClickToCopyButton
          buttonText={INVITE_BUDDY_BUTTON_TEXT}
          textToCopy={textToCopy}
          copiedSuccessMessage="Details copied to clipboard. Feel free to use this message, or create your own!"
          size={ButtonSize.Full}
        />
      </div>

      <div>
        <div className="flex">
          <ButtonLink
            to="https://flouriship.com"
            target="_blank"
            color={ButtonColor.TealNone}
            size={ButtonSize.BorderlessLarge}
          >
            Website
          </ButtonLink>
        </div>
        <div className="flex">
          <ButtonLink
            to="https://www.flouriship.com/faq"
            target="_blank"
            color={ButtonColor.TealNone}
            size={ButtonSize.BorderlessLarge}
          >
            FAQ
          </ButtonLink>
        </div>
        <div className="flex">
          <ButtonLink
            to="https://www.flouriship.com/desktop-app"
            target="_blank"
            color={ButtonColor.TealNone}
            size={ButtonSize.BorderlessLarge}
          >
            Tutorial: Add Flourish to Home
          </ButtonLink>
        </div>
        <div className="flex">
          <ButtonLink
            to={reportIssueUrl}
            target="_blank"
            color={ButtonColor.TealNone}
            size={ButtonSize.BorderlessLarge}
          >
            Report an Issue
          </ButtonLink>
        </div>
        <div className="flex">
          <ButtonLink
            to="https://calendly.com/tianyixie/flourish-together-with-users"
            target="_blank"
            color={ButtonColor.TealNone}
            size={ButtonSize.BorderlessLarge}
          >
            Book a call with Flourish Team
          </ButtonLink>
        </div>
        {user.userType === "admin" && (
          <>
            <hr className="border-teal-100 mb-6" />
            <div className="flex">
              <ButtonNavigate
                to={PageRoute.ADMIN}
                color={ButtonColor.TealUnfilled}
                size={ButtonSize.Full}
              >
                Admin Dashboard
              </ButtonNavigate>
            </div>
          </>
        )}
        <div className="my-6" />
        <hr className="border-teal-100 mb-6" />
        <ButtonNavigate
          to={signoutPageLink()}
          color={ButtonColor.RedUnFilledBorder}
          size={ButtonSize.Full}
        >
          Sign Out
        </ButtonNavigate>
        <div className="mb-4" />
      </div>
    </div>
  );
};
