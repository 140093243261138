import { AppRoutes } from "./AppRoutes";
import { useLocation } from "react-router-dom";
import { VerifyUser } from "./components/verification";
import { LoggedInPageWrapper } from "./components/logged-in-page-wrapper";
import { PageRoute } from "./util/constants";
import { initFirebase } from "./util/firebase-utils";

// This is the the app's Top-level component where we will include routes,
// Navbar and states that do not need a refresh between page traversal
export const AppTree = () => {
  const location = useLocation().pathname;

  if (process.env.REACT_APP_USE_FCM === "true") {
    try {
      initFirebase();
    } catch (error) {
      console.log("Error when initializing firebase", error);
    }
  } else {
    // Register the service worker for fetch caching and notification subscription.
    try {
      navigator.serviceWorker.register("/serviceworker.js");
    } catch (error) {
      console.log("Error when registering service worker", error);
    }
  }

  return (
    <>
      {isLoginWrapperNeeded(location) ? (
        // VerifyUser is used to pass `user` to the ProfileCard in TopNavBar.
        <VerifyUser
          loggedInComponent={(user) => (
            <LoggedInPageWrapper location={location} user={user}>
              <AppRoutes />
            </LoggedInPageWrapper>
          )}
        />
      ) : (
        <AppRoutes />
      )}
    </>
  );
};

const isLoginWrapperNeeded = (location: string) => {
  return (
    location === PageRoute.ACTIVITY ||
    location === PageRoute.COMMUNITY ||
    location === PageRoute.LIBRARY ||
    location === PageRoute.INSIGHTS ||
    location === PageRoute.PROFILE ||
    location === PageRoute.BUDDIES ||
    location === PageRoute.COMMUNITY_POST
  );
};
