import { Card, CardColor } from "../card";
import { DailyMoodPopup } from "../daily-mood/DailyMoodPopup";
import Mood0 from "../../../assets/moods/Mood-0.svg";
import Mood1 from "../../../assets/moods/Mood-1.svg";
import Mood2 from "../../../assets/moods/Mood-2.svg";
import Mood3 from "../../../assets/moods/Mood-3.svg";
import Mood4 from "../../../assets/moods/Mood-4.svg";
import { ActivityButton } from "../button";
import { format } from "date-fns";
import { PulseScale } from "../../../client-server-shared/enums";
import { IPulseRecord } from "../../../client-server-shared/response-types";

const MoodToSun: Record<PulseScale, string> = {
  [PulseScale.UNKNOWN]: Mood2,
  [PulseScale["Really terrible"]]: Mood0,
  [PulseScale["Somewhat bad"]]: Mood1,
  [PulseScale["Okay"]]: Mood2,
  [PulseScale["Pretty good"]]: Mood3,
  [PulseScale["Super awesome"]]: Mood4,
};

interface PulseCardProps {
  pulseRecords: IPulseRecord[];
}

/**
 * Renders a card with a list of pulses. Includes button to add a new pulse.
 */
export const PulseCard = ({ pulseRecords }: PulseCardProps) => {
  return (
    <Card color={CardColor.Teal}>
      <div className="p-4 flex flex-col">
        <div className="divide-y divide-teal-100">
          {pulseRecords.map((pulseRecord, index) => (
            <div key={index} className="pt-2 pb-4">
              <PulseSection pulseRecord={pulseRecord} />
            </div>
          ))}
        </div>
        <ActivityButton
          className={"font-medium text-sm py-1 px-2 text-teal-500 w-fit self-end"}
          component={DailyMoodPopup}
          usingDefaultStyles={false}
        >
          + New
        </ActivityButton>
      </div>
    </Card>
  );
};

interface PulseSectionProps {
  pulseRecord: IPulseRecord;
}

export const PulseSection = ({ pulseRecord }: PulseSectionProps) => {
  const { submittedAt, pulseScale, currentEmotions, reasonText } = pulseRecord;
  return (
    <div className="flex flex-col">
      {submittedAt && (
        <p className="uppercase text-gray-400 w-full text-right text-xs">
          {format(new Date(submittedAt), "p")}
        </p>
      )}
      <div className="flex gap-x-3 w-full">
        <img src={MoodToSun[pulseScale]} className="w-[60px] h-[60px] flex-none" />

        <div className="grid grid-cols-[auto_1fr] flex-1 gap-3">
          <p className="uppercase text-gray-400 font-semibold text-xs leading-6">Mood</p>
          <div className="bg-yellow-100 rounded px-2 py-0.5 font-medium text-sm w-fit">
            {PulseScale[pulseScale]}
          </div>

          <p className="uppercase text-gray-400 font-semibold text-xs leading-6">Keyword</p>
          <div className="flex flex-wrap gap-2">
            {currentEmotions &&
              currentEmotions.length > 0 &&
              currentEmotions.map((keyword, index) => (
                <div key={index} className="bg-teal-50 rounded px-2 py-0.5 font-medium text-sm">
                  {keyword}
                </div>
              ))}
          </div>
        </div>
      </div>
      {reasonText && <p className="mt-4 text-sm">{reasonText}</p>}
    </div>
  );
};
