import React, { useEffect, useRef } from "react";
import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

interface Props {
  positionRight: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  children?: ReactNode;
  buttonRef: React.RefObject<HTMLButtonElement>; // Ref of the button opening this component
}

export const PopDownMenu = ({
  positionRight = false,
  onClose,
  showCloseButton = true,
  children,
  buttonRef,
}: Props) => {
  const windowRef = useRef<HTMLDivElement>(null);
  // This `useEffect` contains an event listener for click events outside of this component and will close it
  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      // If the user clicks the button that opens the popup, don't do anything
      if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
        return;
      }

      // If the user clicks inside the popup, don't do anything
      if (windowRef.current && windowRef.current.contains(event.target as Node)) {
        return;
      }

      // If the user clicks outside the popup, call the onClose function
      onClose?.();
    }
    // Add the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div 
      ref={windowRef} 
      className={
        `absolute z-10 mt-2 top-[60px]
        ${positionRight ? "right-0" : "left-0"}
      `}
    >
      <div className="min-w-[240px] md:min-w-[340px] bg-teal-0 rounded-lg drop-shadow-xl p-6 flex flex-col">
        {showCloseButton && (
          <div className="w-full h-[24px] relevant">
            <button
              className="absolute top-0 right-0 w-[48px] h-[48px] p-0.5 flex items-center justify-center self-end text-gray-600"
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faXmark} className="text-teal-700" />
            </button>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
