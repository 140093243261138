import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, ButtonSize, ButtonColor } from "../../components/button";
import Clouds from "../../../assets/illustrations/clouds.svg";
import SunChasingHeart from "../../../assets/illustrations/sun-chasing-heart.svg";
import { ErrorMessage } from "../../components/error";
import { validateCode, getCode } from "../../controllers/ajax/InvitationCodeController";
import { PageRoute } from "../../util/constants";
import { InvitationCodeType, InvitationCodeStatus } from "../../../client-server-shared/enums";
import { IInvitationCode, IInvitationCodeGetCodeRequest } from "../../../client-server-shared/response-types";
import GoogleIcon from "../../../assets/icons/icon-google.svg";
import { GOOGLE_LOGIN_URL } from "../../controllers/helper/requestConstants";

enum AccountActionOption {
  Create = "CREATE",
  Redeem = "REDEEM",
  Google = "GOOGLE",
}

export const InvitationCodePage: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationCodeSearchParam = searchParams.get("code") ?? "";
  const [codeError, setCodeError] = useState("");
  // This is the state of the InvitationCode object which will be set from a response from `getCode`
  const [invitationCodeState, setInvititationCodeState] = useState<IInvitationCode | null>(null);
  // This useEffect will retrieve an `InvitationCode` document from the DB to extract the org name
  useEffect(() => {
    const request: IInvitationCodeGetCodeRequest = {
      codeString: invitationCodeSearchParam,
      status: InvitationCodeStatus.SENT,
      codeType: InvitationCodeType.ORG_INVITE,
    };
    getCode(request).then((result) => {
      setInvititationCodeState(result);
    });
  }, []);
  // This function validates the code and sets the `InvitationCodeStatus` field to `verified` if successful
  function handleInvitationCode(option: AccountActionOption) {
    if (!invitationCodeSearchParam) setCodeError("Invalid invitation code.");
    else {
      validateCode(invitationCodeSearchParam, InvitationCodeType.ORG_INVITE)
        .then((result) => {
          if (!result) {
            setCodeError("Invalid invitation code.");
            return;
          }
          // If successful, redirect the user to an auth page depending on their `AccountActionOption`
          if (option === AccountActionOption.Create) 
            navigate(`${PageRoute.CREATE}?invitationCode=${invitationCodeSearchParam}`);
          else if (option === AccountActionOption.Redeem) 
            navigate(`${PageRoute.LOGIN}?invitationCode=${invitationCodeSearchParam}`);
          else if (option === AccountActionOption.Google) 
            window.location.href = GOOGLE_LOGIN_URL;
        })
        .catch((e) => {
          console.error(e);
          setCodeError("Sorry, an error occurred while verifying the code. Please try again later.");
        });
    }
  };

  return (
    <div
      className="flex flex-col h-screen w-screen items-center bg-no-repeat bg-[center_top_8rem] bg-sky bg-cover pt-20"
      style={{ backgroundImage: `url(${Clouds})` }}
    >
      <img className="h-21 w-40" src={SunChasingHeart} alt="Sun reaching for heart" />
      <div className="flex flex-col max-w-[300px] pt-36">
        <h1 className="font-semibold text-2xl text-teal-600 pb-4 text-center">Your invite from {invitationCodeState?.organizationName} is waiting...</h1>
        <p className="text-center pb-[54px]">Flourish with your community, for deeper connections and happier days!</p>
        <div className="text-center pb-2">
          <ErrorMessage message={codeError} />
        </div>
        <div className="flex flex-col items-end gap-4">
          {/* Google button */}
          <Button 
            size={ButtonSize.Full} 
            color={ButtonColor.TealUnfilled} 
            onClick={() => handleInvitationCode(AccountActionOption.Google)}
          >
            <div className="flex items-center justify-center space-x-2">
              <img
                src={GoogleIcon}
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
              <span>Sign in with Google</span>
            </div>
          </Button>
          {/* "or" divider */}
          <div className="w-full relative p-1">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-white text-sm text-gray-500">or</span>
            </div>
          </div>
          {/* Create Account button */}
          <Button 
            size={ButtonSize.Full} 
            color={ButtonColor.TealUnfilled} 
            onClick={() => handleInvitationCode(AccountActionOption.Create)}
          >
            Create Account
          </Button>
          {/* Login button */}
          <Button 
            size={ButtonSize.Full} 
            color={ButtonColor.TealUnfilled} 
            onClick={() => handleInvitationCode(AccountActionOption.Redeem)}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};
