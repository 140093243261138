import { ReactNode, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonSize } from "../../components/button";
import { Input } from "../../components/input";
import { activityPageLink, resetPasswordPageLink, startPageLink } from "../../util/relative-links";
import LeafImage from "../../../assets/accessories/leaf.svg";
import PotatoOpenImage from "../../../assets/illustrations/potato-open.svg";
import classNames from "classnames";
import { getIsEmailAddressAvailable, loginUser } from "../../controllers/ajax/AuthController";
import { ErrorMessage } from "../../components/error";
import { getCode } from "../../controllers/ajax/InvitationCodeController";
import { InvitationCodeType, InvitationCodeStatus } from "../../../client-server-shared/enums";
import { IInvitationCodeGetCodeRequest } from "../../../client-server-shared/response-types";

export const Login: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const invitationCode = params.get("invitationCode") || "";
  const referrerCode = params.get("referrer");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState<string>("");

  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState("");

  const passwordFilledIn = password !== "";

  const [emailAddressError, setUsernameError] = useState<ReactNode>(<></>);

  // This useEffect fills in the Email Address field if an `invitationCode` is detected
  useEffect(() => {
    if (invitationCode) {
      const request: IInvitationCodeGetCodeRequest = {
        codeString: invitationCode,
        status: InvitationCodeStatus.VERIFIED,
        codeType: InvitationCodeType.ORG_INVITE,
      };
      getCode(request).then((result) => {
        result && setEmailAddress(result.email);
      });
    }
  }, [invitationCode]);

  const validateEmailAddress = () => {
    setUsernameError(<></>);
    getIsEmailAddressAvailable(emailAddress)
      .then((result) => {
        if (result && emailAddress !== "") {
          setUsernameError(
            <span>
              Email &quot;{emailAddress}&quot; is not registered. Did you mean to{" "}
              <Link to={startPageLink()} className="underline underline-offset-2">
                create an account
              </Link>
              ?
            </span>
          );
        }
      })
      .catch((e) => {
        console.error(e);
        setUsernameError(
          "Sorry, an error occurred while verifying the username. Please try again later."
        );
      });
  };

  const handleLogin = () => {
    setLoggingIn(true);
    loginUser(emailAddress, password)
      .then((result) => {
        // Login was successful
        if (result) {
          // Redirect user to the Start Page w/ the buddy referrer code
          if (referrerCode) navigate(`${startPageLink()}?referrer=${referrerCode}`);
          // Else, just redirect them to the Activity page
          else navigate(activityPageLink());
        } else {
          setLoginError(
            "No account found matching the given email and password. Please try again."
          );
        }
      })
      .catch((e) => {
        console.error(e);
        setLoginError("Sorry, an error occurred while logging in. Please try again later.");
      })
      .finally(() => {
        setLoggingIn(false);
      });
  };

  return (
    <div
      className="flex h-screen w-screen items-center justify-center bg-no-repeat bg-right-bottom"
      style={{ backgroundImage: `url(${PotatoOpenImage})` }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
        className="flex flex-col max-w-[300px]"
      >
        <h1 className="font-semibold text-2xl text-teal-600 mb-10">
          What is your <span className="text-orange-400">Flourish</span> account?
        </h1>

        <div className="flex flex-col space-y-1 mb-2">
          <label htmlFor="emailAddress" className="text-sm font-medium">
            Email Address
          </label>
          <Input
            id="emailAddress"
            type="text"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            onBlur={() => validateEmailAddress()}
            placeholder="Email Address"
            disabled={!!invitationCode} // Disable the input if there's an code from the query parameter
          />
        </div>

        <ErrorMessage message={emailAddressError} />

        <div className="flex flex-col mb-5">
          <div className="grid grid-cols-2 items-end">
            <label htmlFor="password" className="text-sm font-medium pb-1">
              Password
            </label>
            <div
              className={classNames("h-9 place-self-end", {
                "opacity-0": !emailAddress,
                "opacity-100": emailAddress,
              })}
            >
              <img src={LeafImage} />
            </div>
          </div>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <Link to={resetPasswordPageLink()} className="text-sm font-semibold text-teal-500 underline self-end">
          Forgot password
        </Link>
        <div className="flex flex-col items-end mb-2">
          <div
            className={classNames("h-9", {
              "opacity-0": !passwordFilledIn || !emailAddress,
              "opacity-100": passwordFilledIn && emailAddress,
            })}
          >
            <img src={LeafImage} />
          </div>
          <Button
            type="submit"
            size={ButtonSize.Full}
            disabled={!emailAddress || !passwordFilledIn}
          >
            {/* TODO: maybe get a spinner asset instead of changing the text */}
            {loggingIn ? "Logging In..." : "Login"}
          </Button>
        </div>
        <ErrorMessage message={loginError} />
      </form>
    </div>
  );
};
