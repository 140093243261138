import { DetailedLibraryActivityCard } from "../activity-card";
import { useTypeform } from "../../controllers/hooks";
import { ALL_ACTIVITY_DATA } from "../../data/week-activities-mock";

interface Props {
  hiddenData: string;
}

export const ActivityList = ({ hiddenData }: Props) => {
  useTypeform();

  return (
    <div className="space-y-4">
      {ALL_ACTIVITY_DATA.map((activity, index) => {
        const formId = activity.typeformFormId;
        return (
          <DetailedLibraryActivityCard
            key={index}
            backgroundImage={{
              data: activity.backgroundImage.largeData,
              position: activity.backgroundImage.position,
            }}
            title={activity.title}
            estimatedTime={activity.estimatedTime}
            desc={activity.desc}
            linkConfig={{
              id: formId,
              hiddenData: hiddenData,
            }}
          />
        );
      })}
    </div>
  );
};
