import { useEffect, useState } from "react";
import { patchLastInvitationDate } from "../../controllers/ajax/UserController";
import SunChasingHeartWithClouds from "../../../assets/illustrations/sun-chasing-heart-with-clouds.svg";
import { IInvitationCode, IOrgApplyRequest, IInvitationCodeGetCodeRequest } from "../../../client-server-shared/response-types";
import { InvitationCodeType, InvitationCodeStatus } from "../../../client-server-shared/enums";
import { applyOrgInviteCode, getCode } from "../../controllers/ajax/InvitationCodeController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PopupState } from "../../components/logged-in-page-wrapper/PopupHandler";

interface OrgInvitePopupProps {
  setPopupState: React.Dispatch<React.SetStateAction<PopupState>>;
}
export const OrgInvitePopup = ({setPopupState}: OrgInvitePopupProps) => {
  const [inviteCode, setInviteCode] = useState<IInvitationCode | null>(null);
  // This useEffect retrieves a valid org invitation code. If none is found, it will set the popup state to `NOT_SHOWN`
  useEffect(() => {
    async function fetchInviteCode() {
      const request: IInvitationCodeGetCodeRequest = {
        status: InvitationCodeStatus.VERIFIED,
        codeType: InvitationCodeType.ORG_INVITE,
      };
      try {
        const result = await getCode(request);
        if (result) {
          setInviteCode(result);
          await patchLastInvitationDate();
          setPopupState(PopupState.SHOWN); 
        } else {
          setPopupState(PopupState.NOT_SHOWN);
        }
      } catch (error) {
        console.error("Error during fetching invite code or patching last invitation date", error);
        setPopupState(PopupState.NOT_SHOWN);
      }
    }
    fetchInviteCode();
  }, []);

  function handleJoin(toJoin: boolean): void {
    if (inviteCode) {
      const req: IOrgApplyRequest = {
        codeString: inviteCode.codeString,
        toJoin: toJoin,
      }
      applyOrgInviteCode(req).then(() => {
        setInviteCode(null);
        window.location.reload();
      });
    }
  }

  return (
    inviteCode && (
      <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 flex items-center justify-center z-40">
        <div className="flex flex-col relative justify-center modal-content bg-white rounded-3xl overflow-hidden shadow-lg w-80 pb-6">
          <img src={SunChasingHeartWithClouds} />
          <button
            className="flex absolute top-1.5 right-2.5 items-center justify-center w-[48px] h-[48px]"
            onClick={() => handleJoin(false)}
          >
            <FontAwesomeIcon icon={icon({ name: "xmark" })} className="text-[#00363B] w-[16px]" />
          </button>
          <h2 className="text-base text-center font-bold my-4 mx-6">
            Join {inviteCode ? inviteCode.organizationName : "[Name]"}?
          </h2>

          <button
            className="bg-teal-500 py-2 my-1.5 mx-6 font-semibold text-[#F5F5F5] rounded-xl"
            onClick={() => handleJoin(true)}
          >
            Accept Invite
          </button>
        </div>
      </div>
    )
  );
};
