import { useEffect, useState } from "react";
import { IInsightsPageResponse } from "../../../client-server-shared/response-types";
import getInsightsPage from "../ajax/GetInsightsPageAjaxController";
import { QueryReturn } from "./util";

/** Query to get the data for the Activity Page. Gets called on page load. */
export const useGetInsightsPage = (): QueryReturn<IInsightsPageResponse> => {
  const [data, setData] = useState<IInsightsPageResponse | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    getInsightsPage()
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    data,
    loading,
    error,
  };
};
