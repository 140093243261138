import { loginPageLink, createPageLink } from "../../util/relative-links";
import CloudBackground from "../../../assets/illustrations/cloud-background.svg";
import { GOOGLE_LOGIN_URL } from "../../controllers/helper/requestConstants";
import { DotLottiePlayer } from "@dotlottie/react-player";
import { SignInComponent } from "./SignInComponent";

export const Home: React.FC<Record<string, never>> = () => {
  return (
    <div
      className="flex flex-col h-screen w-screen bg-deepsky items-center bg-top bg-no-repeat bg-cover overflow-hidden"
      style={{
        backgroundImage: `url(${CloudBackground})`
      }}
    >
      <div className="relative flex flex-row w-screen">
        <div className="absolute w-[120px] top-16 left-1/2 flex">
          <DotLottiePlayer src="animations/Flying-Sunnie.lottie" autoplay loop className="" />
        </div>
        <div className="absolute w-[120px] top-10 md:left-[20%] -left-4 flex">
          <DotLottiePlayer src="animations/Cloud.lottie" autoplay loop className="" />
        </div>

        <div className="absolute w-[80px] absolute top-6 md:right-[20%] -right-4 flex">
          <DotLottiePlayer src="animations/Cloud.lottie" autoplay loop className="" />
        </div>
      </div>
      <div className="relative flex flex-col top-1/2 w-full max-w-[320px] px-2.5 items-center">
        <h1 className="flex font-bold text-xl text-teal-600 mb-14 text-center">
          Let's Flourish Together!
        </h1>
        <SignInComponent googleLoginUrl={`${GOOGLE_LOGIN_URL}`} createAccountUrl={`${createPageLink()}`} loginUrl={`${loginPageLink()}`} />
      </div>
    </div>
  );
};
