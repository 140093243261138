import { Card, CardColor } from "../../components/card";
import { TypeformButtonLink, ButtonColor, ButtonSize } from "../../components/button";
import FeedbackImage from "../../../assets/illustrations/feedback-image.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FEEDBACK_FORM_ID, FEEDBACK_FORM_TITLE } from "../../util/typeform-constants";
import { faMessageSmile } from '@fortawesome/pro-regular-svg-icons';

export const FeedbackCard = () => {
    return (
        <Card color={CardColor.White} border>
            <div className="container p-4">
                <div className="flex justify-between pb-3 gap-4">
                    <div className="flex justify-center flex-col">
                        <h1 className="font-bold text-teal-700">How is your Flourish experience so far?</h1>
                    </div>
                    <img
                        src={FeedbackImage}
                        alt="Feedback Image"
                        className="lg:max-h-[200px] md:max-h-[180px] sm:max-h-[120px] max-h-[90px]"
                    />
                </div>
                <TypeformButtonLink
                    typeformLink={{ id: FEEDBACK_FORM_ID, title: FEEDBACK_FORM_TITLE }}
                    color={ButtonColor.TealUnfilled}
                    size={ButtonSize.Full}
                    className="flex items-center gap-2 justify-center"
                >
                    <FontAwesomeIcon
                        icon={faMessageSmile}
                        className="text-teal-500"
                    />
                    <span>Share your feedback</span>
                </TypeformButtonLink>
            </div>
        </Card>
    )
}