/**
 * This file contains the related components for the Daily Mood Checkin feature.
 */
import { ReactNode, useState, useEffect, useRef } from "react";
import { IPulseFinishRequest } from "../../../client-server-shared/response-types";
import { PulseScale } from "../../../client-server-shared/enums";
import { DailyMoodPopup } from "./DailyMoodPopup";
import { Card } from "../card/Card";
import { CardColor } from "../card/card-util";
import { Button, ButtonColor } from "../button";
import { moodMapping } from "./utils";
import { DotLottiePlayer } from "@dotlottie/react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp, faAtomSimple, faXmark, faArrowLeft, faComments } from '@fortawesome/pro-regular-svg-icons';
import classNames from "classnames";
import Mood0 from "../../../assets/moods/Mood-0.svg";
import Mood1 from "../../../assets/moods/Mood-1.svg";
import Mood2 from "../../../assets/moods/Mood-2.svg";
import Mood3 from "../../../assets/moods/Mood-3.svg";
import Mood4 from "../../../assets/moods/Mood-4.svg";
import SunnieHoldingHeartWithClosedEyes from "../../../assets/illustrations/daily-mood/sunnie-holding-heart-eyes-closed.svg";
import SunnieCelebratingWithClosedEyes from "../../../assets/illustrations/daily-mood/sunnie-celebrating-eyes-closed.svg";
import ConfettiBackground from "../../../assets/activity-completed/confetti-background.svg";
import PlantsLeftGold from "../../../assets/illustrations/daily-mood/plants-left-light.svg";
import PlantsRightGold from "../../../assets/illustrations/daily-mood/plants-right-light.svg";
import TrophyTeal from "../../../assets/icons/trophy-teal.svg";
import TrophyOrange from "../../../assets/icons/trophy-orange.svg";
import KeepingItInYourHead from "../../../assets/science-snippet/keeping-it-in-your-head.svg";

/**
 * This component will be the first page of the Daily Mood popup, and will contain
 * the 1-5 scale.
 */
interface DailyMoodScaleProps {
    onMoodSelect: (selectedMood: PulseScale) => void;
}
export const DailyMoodScale = ({ onMoodSelect }: DailyMoodScaleProps) => {
    return (
        <div className="w-full h-screen fixed top-0 left-0 z-40 bg-deepsky bg-top bg-no-repeat bg-cover">
            {/* Lottie Animations */}
            <DotLottiePlayer
                src="animations/Cloud-Top-Row.lottie"
                autoplay
                loop
                className="object-contain min-w-[1000px] absolute top-0 left-1/2 transform -translate-x-1/2 flex object-none w-screen"
            />
            <div className="absolute w-[120px] top-16 2xl:top-[120px] left-1/2 flex">
                <DotLottiePlayer src="animations/Flying-Sunnie.lottie" autoplay loop />
            </div>
            <div className="absolute w-[120px] top-28 2xl:top-[200px] md:left-[20%] -left-4 flex">
                <DotLottiePlayer src="animations/Cloud.lottie" autoplay loop />
            </div>
            <div className="absolute w-[80px] top-16 2xl:top-[120px] md:right-[20%] -right-4 flex">
                <DotLottiePlayer src="animations/Cloud.lottie" autoplay loop />
            </div>
            <div className="absolute w-[160px] top-1/3 md:right-[24%] -right-12 flex">
                <DotLottiePlayer src="animations/Cloud.lottie" autoplay loop />
            </div>
            {/* Bottom Scale Card */}
            <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-[475px] mx-auto bg-white flex flex-col items-center justify-center gap-5 pt-[64px] pb-[45px] rounded-t-[36px]">
                <h1 className="text-center text-[22px] text-teal-700 font-bold max-w-[259px] w-full">How are you today?</h1>
                <div className="flex flex-wrap justify-center gap-[10px]">
                    <button type="button" onClick={() => onMoodSelect(PulseScale["Really terrible"])}>
                        <img src={Mood0} alt="" />
                    </button>
                    <button type="button" onClick={() => onMoodSelect(PulseScale["Somewhat bad"])}>
                        <img src={Mood1} alt="" />
                    </button>
                    <button type="button" onClick={() => onMoodSelect(PulseScale["Okay"])}>
                        <img src={Mood2} alt="" />
                    </button>
                    <button type="button" onClick={() => onMoodSelect(PulseScale["Pretty good"])}>
                        <img src={Mood3} alt="" />
                    </button>
                    <button type="button" onClick={() => onMoodSelect(PulseScale["Super awesome"])}>
                        <img src={Mood4} alt="" />
                    </button>
                </div>
                <div className="flex flex-col items-center">
                    <FontAwesomeIcon icon={faAnglesUp} className="text-teal-700" />
                    <p className="text-xs text-teal-700">Select to continue</p>
                </div>
            </div>
        </div>
    );
}

/**
 * This component will be the second page of the Daily Mood popup, and will contain
 * the emotional word bank (e.g. "happy", "inspired", "motivated", etc)
 */
interface DailyMoodKeywordsProps {
    moodScale: PulseScale;
    handleNextButtonClick: () => void;
    onCancel: (selectedEmotions?: string[]) => Promise<void>;
    onKeywordsSelect: (selectedEmotions: string[]) => void;
    isSubmitting: boolean;
}
export const DailyMoodKeywords = ({ moodScale, handleNextButtonClick, onCancel, onKeywordsSelect, isSubmitting }: DailyMoodKeywordsProps) => {
    const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
    const [otherToggled, setOtherToggled] = useState<boolean>(false); // Toggles the "Other" input field
    const [otherKeyword, setOtherKeyword] = useState<string>(""); // State for the "Other" keyword string
    const moodData = moodMapping[moodScale]; // Get the appropriate mood data based on `moodScale`
    const isPositiveMood = moodScale >= 2;
    /**
     * This block defines the first and second set of keywords. For positive `moodScale` ratings,
     * the positive keywords will be listed first. Else, the negative keywords will be listed first.
     */
    let firstSet, secondSet;
    if (isPositiveMood) {
        firstSet = moodData.emotionSet.positiveKeywords;
        secondSet = moodData.emotionSet.negativeKeywords;
    } else {
        firstSet = moodData.emotionSet.negativeKeywords;
        secondSet = moodData.emotionSet.positiveKeywords;
    }
    /**
     * This function will be called to move to the next page. It will push the `otherKeyword`
     * emotion to the keyword list for `onKeywordsSelect` if "Other" is selected.
     */
    const handleContinue = () => {
        const finalKeywords = otherToggled && otherKeyword
            ? [...selectedKeywords, otherKeyword]
            : selectedKeywords;
        onKeywordsSelect(finalKeywords);
        handleNextButtonClick();
    }
    // This function toggles a keyword to be added or removed from `selectedKeywords`
    const toggleKeyword = (keyword: string) => {
        setSelectedKeywords((prevSelectedKeywords) => {
            // If the toggling keyword exists in `selectedKeywords`, filter it out
            if (prevSelectedKeywords.includes(keyword)) {
                return prevSelectedKeywords.filter(kw => kw !== keyword);
            }
            // Else, add it to `selectedKeywords`
            else {
                return [...prevSelectedKeywords, keyword];
            }
        });
    };
    return (
        <DailyMoodPageLayout
            titleText={"Which word(s) best describe your feelings now?"}
            subText={moodData.subText}
            iconColor={moodData.iconColor}
            buttonText={"Continue"}
            buttonOnClick={handleContinue}
            isSubmitting={isSubmitting}
            moodImage={moodData.backgroundImage}
            onCancel={onCancel}
        >
            <div className="flex flex-col h-full gap-5">
                {/* First Keyword Set */}
                <div className="flex flex-wrap gap-3">
                    {firstSet.map((keyword) => (
                        <button
                            className={classNames(
                                selectedKeywords.includes(keyword) ? "border-solid border-2 border-teal-700" : "",
                                isPositiveMood ? "bg-yellow-100" : "bg-gray-100",
                                "h-[44px] px-4 flex items-center justify-center rounded-3xl font-semibold"
                            )}
                            type="button"
                            key={keyword}
                            onClick={() => toggleKeyword(keyword)}
                        >
                            {keyword}
                        </button>
                    ))}
                </div>
                {/* Second Keyword Set */}
                <div className="flex flex-wrap gap-3 pb-[120px]">
                    {secondSet.map((keyword) => (
                        <button
                            className={classNames(
                                selectedKeywords.includes(keyword) ? "border-solid border-2 border-teal-700" : "",
                                isPositiveMood ? "bg-gray-100" : "bg-yellow-100",
                                "h-[44px] px-4 flex items-center justify-center rounded-3xl font-semibold"
                            )}
                            type="button"
                            key={keyword}
                            onClick={() => toggleKeyword(keyword)}
                        >
                            {keyword}
                        </button>
                    ))}
                    {/* "Other" Keyword Button and Input field */}
                    <div
                        className={classNames(
                            otherToggled ? "border-solid border-2 border-teal-700" : "",
                            isPositiveMood ? "bg-gray-100" : "bg-yellow-100",
                            "h-[44px] px-4 flex items-center justify-center rounded-3xl font-semibold cursor-pointer"
                        )}
                        onClick={() => setOtherToggled(!otherToggled)}
                    >
                        {/* Render the input field if "Other" is toggled */}
                        {otherToggled
                            ? <input
                                className="border-none bg-transparent focus:outline-none"
                                type="text"
                                value={otherKeyword}
                                onChange={(e) => setOtherKeyword(e.target.value)}
                                autoFocus
                            />
                            : <span>Other</span>
                        }
                    </div>
                </div>
            </div>
        </DailyMoodPageLayout>
    );
}

/**
 * This component is the third page of the Daily Mood popup. This page contains the
 * section where a user inputs the highlight (written part).
 */
interface DailyMoodHighlightProps {
    requestState: IPulseFinishRequest;
    onCancel: (selectedEmotions?: string[]) => Promise<void>;
    onHighlightSet: (highlightText: string) => void;
    handleNextButtonClick: () => void;
    isSubmitting: boolean;
}
export const DailyMoodHighlight = ({ requestState, onCancel, onHighlightSet, isSubmitting, handleNextButtonClick }: DailyMoodHighlightProps) => {
    const [highlightText, setHighlightText] = useState<string>("");
    const moodData = moodMapping[requestState.pulseScale]; // Get the appropriate mood data based on `moodScale`
    // Constructing the subText
    const keywords = requestState.currentEmotions;
    const lastKeyword = keywords.length > 0 ? keywords[keywords.length - 1] : null;
    let subText = "";
    if (keywords.length > 0) {
        subText = `You are feeling ${
            keywords.length > 1 
                ? keywords.slice(0, -1).join(", ") + " and " 
                : ""}${lastKeyword}.`;
    }
    /**
     * This function will be called to move to the next page. It will push the `otherKeyword`
     * emotion to the keyword list for `onKeywordsSelect` if "Other" is selected.
     */
    const handleContinue = () => {
        onHighlightSet(highlightText);
        handleNextButtonClick();
    }
    return (
        <DailyMoodPageLayout
            titleText={keywords.length > 0 ? "What made you feel this way?" : "Can you describe how you feel?"}
            subText={subText}
            iconColor={moodData.iconColor}
            buttonText={isSubmitting ? "Loading..." : "Done"}
            buttonOnClick={handleContinue}
            disabled={highlightText.length === 0 || isSubmitting}
            isSubmitting={isSubmitting}
            moodImage={moodData.backgroundImage}
            onCancel={onCancel}
        >
            <textarea 
                className="w-full bg-white px-3 py-2 rounded-xl border border-solid"
                value={highlightText}
                onChange={(e) => setHighlightText(e.target.value)}
                rows={5}
                placeholder="Putting thoughts into words can help us improve mental clarity and build emotional awareness."
            />
        </DailyMoodPageLayout>
    )
}

/**
 * This component is the last page after the highlight section. It shows a dynamic
 * quote depending on the `moodScale` given.
 */
interface DailyMoodQuotePageProps {
    moodScale: PulseScale;
    handleNextButtonClick: () => void;
}
export const DailyMoodQuotePage = ({moodScale, handleNextButtonClick}: DailyMoodQuotePageProps) => {
    const moodData = moodMapping[moodScale];
    const isPositive = moodData.isPositive;
    const quoteText = moodData.quote.text;
    const author = moodData.quote.author;
    const backgroundColor = isPositive ? "bg-[#F0F8FA]" : "bg-teal-700";
    const cardColor = isPositive ? "bg-white" : "bg-teal-500";
    const textColor = isPositive ? "text-teal-600" : "text-white";
    const buttonColor = isPositive ? ButtonColor.TealFilled : ButtonColor.TealLightFilled;
    const buttonText = isPositive ? "text-white" : "text-teal-600";
    const gradient = isPositive ? "bg-gradient-overlay-teal-light" : "bg-gradient-overlay-teal-dark";
    const sunnieImage = isPositive ? SunnieCelebratingWithClosedEyes: SunnieHoldingHeartWithClosedEyes;
    const trophy = isPositive ? TrophyOrange : TrophyTeal;
    const handleBackToHomeClick = () => {
        window.location.href = "/activity"; 
    }
    return (
        <div className="w-full h-screen fixed top-0 left-0 z-40 flex items-center justify-center md:bg-black md:bg-opacity-50">
            <div className={classNames(backgroundColor, "w-full h-full flex flex-col md:max-w-[650px] md:max-h-[736px] md:rounded-3xl md:relative overflow-hidden")}>
                {/* "X" Button */}
                <button
                    className="absolute top-3 right-3 w-[44px] h-[44px] flex items-center justify-center"
                    onClick={handleBackToHomeClick}
                >
                    <FontAwesomeIcon icon={faXmark} className={`${classNames(textColor, "w-[14px] h-[18px]")}`}/>
                </button>
                <div className="relative mt-[16%] w-full h-full flex flex-col items-center justify-between bg-no-repeat"
                    style={{ backgroundImage: `url(${ConfettiBackground})` }}
                >
                    {/* Assets */}
                    <div className="absolute top-20 left-0 w-full">
                        <div className="flex justify-between">
                            <img className="w-1/3 max-w-[184px] min-w-[100px]" src={PlantsLeftGold} alt=""/>
                            <img className="w-1/3 max-w-[184px] min-w-[100px]" src={PlantsRightGold} alt=""/>
                        </div>
                    </div>
                    <img className="pt-4" src={trophy} alt=""/>
                    <img className="pt-8" src={sunnieImage} alt=""/>
                    {/* Quote Box */}
                    <div className="container overflow-y-scroll flex flex-col flex-1 pb-[240px] md:px-8">
                        <Card className={classNames(cardColor, "w-full px-[31px] py-[48px]")}>
                            {quoteText.map((text, index) => {
                                return <span className={classNames(textColor, (index % 2 === 0) ? "font-bold" : "font-normal", "whitespace-pre-line")}>{text}</span>
                            })}
                            {author && <p className={classNames(textColor, "font-bold text-right pt-6")}>{`-${moodData.quote.author}`}</p>}
                        </Card>
                    </div>
                </div>
                {/* Bottom Button Bar */}
                <div className={classNames(gradient, `md:absolute fixed bottom-0 pb-8 pt-12 left-1/2 transform -translate-x-1/2 w-full min-h-[120px] flex flex-col items-center justify-center gap-[14px] container md:px-[2rem]`)}>
                    {!isPositive && <Button 
                        className="w-full z-40"
                        color={ButtonColor.TealLightUnfilled}
                        onClick={handleNextButtonClick}
                    >
                        <div className="flex items-center justify-center gap-2">
                            <FontAwesomeIcon icon={faComments} />
                            <p className="font-bold">Sunnie, Let's dive deeper</p>
                            <div className="bg-teal-50 px-1 py-0 rounded h-4 flex justify-center">
                                <span className="text-teal-700 text-[11px] font-semibold">Beta</span>
                            </div>
                        </div>
                    </Button>}
                    <Button 
                        className="w-full z-40"
                        color={buttonColor}
                        onClick={handleBackToHomeClick}
                    >
                        <p className={buttonText}>Back to Home</p>
                    </Button>
                </div>
            </div>
        </div>
    )
}

/**
 * This component renders the page layout including the dynamic mood image for the
 * Keywords and Highlight pages.
 */
interface DailyMoodPageLayoutProps {
    moodImage: string;
    titleText: string;
    subText: string;
    iconColor: string;
    buttonText: string;
    buttonOnClick: any;
    isSubmitting: boolean;
    disabled?: boolean;
    onCancel: (selectedEmotions?: string[]) => Promise<void>;
    children: ReactNode;
}
const DailyMoodPageLayout = ({ moodImage, onCancel, titleText, subText, iconColor, buttonText, buttonOnClick, disabled, isSubmitting, children }: DailyMoodPageLayoutProps) => {
    const [showScienceSnippet, setShowScienceSnippet] = useState<boolean>(false);
    const scienceSnippetButtonRef = useRef(null);
    return (
        <div className="w-full h-screen fixed top-0 left-0 z-40 flex items-center justify-center md:bg-black md:bg-opacity-50">
            <div className="w-full h-full flex flex-col bg-white md:max-w-[650px] md:max-h-[736px] md:rounded-3xl overflow-hidden md:relative">
                    <div className="absolute top-3 left-0 w-full px-3 flex items-center justify-between z-40">
                        <button 
                            className="w-[44px] h-[44px] flex items-center justify-center"
                            onClick={() => setShowScienceSnippet(!showScienceSnippet)}
                            ref={scienceSnippetButtonRef}
                        >
                            <FontAwesomeIcon icon={faAtomSimple} className={`${classNames(iconColor, "w-[18px] h-[18px]")}`}/>
                        </button>
                        <button
                            className="w-[44px] h-[44px] flex items-center justify-center"
                            onClick={() => onCancel()}
                        >
                            <FontAwesomeIcon icon={faXmark} className={`${classNames(iconColor, "w-[14px] h-[18px]")}`}/>
                        </button>
                    </div>
                {/* Mood Background */}
                <div className="w-full relative overflow-hidden">
                    <div className="aspect-ratio-box">
                        <img
                            className="aspect-ratio-box-img"
                            src={moodImage}
                            alt="Background image"
                        />
                    </div>
                </div>
                {/* Content Container */}
                <div className="flex-1 overflow-y-scroll container flex flex-col md:px-[2rem]">
                    <h2 className="pt-5 text-sm">{subText}</h2>
                    <h1 className="text-[22px] font-bold pb-4">{titleText}</h1>
                    {children}
                </div>
                {/* Bottom Continue Button */}
                <div className="md:absolute fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full h-[120px] flex items-center justify-center bg-gradient-overlay container md:px-[2rem]">
                    <Button 
                        className="w-full z-40" 
                        disabled={disabled}
                        onClick={buttonOnClick}
                    >
                        {buttonText}
                    </Button>
                </div>
                {showScienceSnippet && <ScienceSnippet closeScienceSnippet={() => setShowScienceSnippet(false)} buttonRef={scienceSnippetButtonRef}/>}
            </div>
        </div>
    )
}

interface ScienceSnippetProps {
    closeScienceSnippet: () => void;
    buttonRef: React.RefObject<HTMLButtonElement>; // Ref of the button opening this component
}
const ScienceSnippet = ({closeScienceSnippet, buttonRef}: ScienceSnippetProps) => {
    const windowRef = useRef<HTMLDivElement>(null);
    // This `useEffect` contains an event listener for click events outside of this component and will close it
    useEffect(() => {
      function handleOutsideClick(event: MouseEvent) {
        // If the user clicks the button that opens the popup or anything in the popup, don't do anything.
        if (buttonRef.current && buttonRef.current.contains(event.target as Node)) return;
        if (windowRef.current && windowRef.current.contains(event.target as Node)) return;
        // If the user clicks outside the popup, call the onClose function
        closeScienceSnippet();
      }
      document.addEventListener("click", handleOutsideClick);
      return () => { // Remove the event listener when the component unmounts
        document.removeEventListener("click", handleOutsideClick);
      };
    }, [closeScienceSnippet]);
    return (
        <div className="
            md:bg-transparent md:h-full md:absolute
            fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 
            z-40 flex items-center justify-center
        ">
            <div 
                className="container flex items-center justify-center h-full md:p-0 fixed left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2"
            >
                <div 
                    className="md:h-full overflow-hidden relative max-w-[748px] max-h-[800px] md:max-w-[650px] md:max-h-[736px] h-5/6 flex flex-col bg-white rounded-3xl"
                    ref={windowRef}
                >
                    <div className="w-full bg-sky pt-[42px] px-[26px] pb-3 custom-curve md:pt-[28px]">
                        {/* Back Button */}
                        <div className="md:block hidden w-full h-[44px] relative flex items-center justify-between">
                            <button 
                                className="absolute left-[-12px] top-0 w-[44px] h-[44px] flex items-center justify-center"
                                onClick={() => closeScienceSnippet()}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} className="text-teal-700 w-[18px] h-[18px]" />
                            </button>
                        </div>
                        <h1 className="text-[28px] font-bold">Flourish Pulse</h1>
                        <p className="text-sm">This is your personal santuary - a safe space to take a break and connect with yourself.</p>
                    </div>
                    <div className="w-full pt-6 pb-4 overflow-y-scroll flex-1 container md:p-[2rem]">
                        <label className="bg-[#B1E6Ed] px-2 py-1 rounded-lg font-semibold text-[11px]">WHY</label>
                        <p className="text-sm pt-3">Research by Professor James Pennebaker finds that jotting down our thoughts and feelings benefits emotional well-being, self-discovery, and even physical health.</p>
                        <img className="pt-3 pb-[160px]" src={KeepingItInYourHead} alt="Science Snippet"/>
                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full h-[120px] flex items-center justify-center bg-gradient-overlay container md:p-[2rem]">
                            <Button className="w-full z-40" onClick={() => closeScienceSnippet()}>
                                Sounds Good!
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

/**
 * This component is the edge case card in which a user opens an Org or Buddy popup,
 * disabling the Daily Mood popup for the day. This card is rendered on the 
 * home screen.
 */
export const DailyMoodEdgeCaseCard = () => {
    const [selectedMood, setSelectedMood] = useState<PulseScale | null>(null);
    const handleMoodSelect = (mood: PulseScale) => {
        setSelectedMood(mood);
    }
    return (
        <Card color={CardColor.White} className="py-6 px-4 border border-solid-1 border-neutral-200">
            <h1 className="font-bold text-teal-700">I'm feeling...</h1>
            <div className="flex justify-evenly gap-1 pt-1">
                <button onClick={() => handleMoodSelect(PulseScale["Really terrible"])}>
                    <img src={Mood0} alt="" />
                </button>
                <button onClick={() => handleMoodSelect(PulseScale["Somewhat bad"])}>
                    <img src={Mood1} alt="" />
                </button>
                <button onClick={() => handleMoodSelect(PulseScale["Okay"])}>
                    <img src={Mood2} alt="" />
                </button>
                <button onClick={() => handleMoodSelect(PulseScale["Pretty good"])}>
                    <img src={Mood3} alt="" />
                </button>
                <button onClick={() => handleMoodSelect(PulseScale["Super awesome"])}>
                    <img src={Mood4} alt="" />
                </button>
            </div>
            {/* Conditionally render DailyMoodPopup with the selected mood */}
            {selectedMood !== null && (
                <DailyMoodPopup initialPulseScale={selectedMood} initialPage={1} />
            )}
        </Card>
    )
}