import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "./pages/home/Home";
import { Login } from "./pages/login/Login";
import { CreateAccount } from "./pages/create-account/CreateAccount";
import { Signout } from "./pages/signout/Signout";
import { VerifyCheckin, VerifyUser, VerifyAdmin } from "./components/verification";
import { ErrorState } from "./components/error";
import { activityPageLink } from "./util/relative-links";
import { PageTitle } from "./components/page-title";
import { InsightsPage } from "./pages/insights/InsightsPage";
import { FullyUnlockedActivityPage } from "./pages/activity/FullyUnlockedActivityPage";
import { CommunityPostForm } from "./pages/community-post-form/CommunityPostForm";
import { Community } from "./pages/community/Community";
import { Me } from "./pages/profile/Me";
import { Buddies } from "./pages/buddies/Buddies";
import { BuddyInvitePage } from "./pages/buddy-invite/BuddyInvitePage";
import { LibraryPage } from "./pages/library/LibraryPage";
import { PageRoute } from "./util/constants";
import { CelebrateCommunityPost } from "./pages/celebrate-community-post/CelebrateCommunityPost";
import { InvitationCodePage } from "./pages/invitation-code/InvitationCodePage";
import { LoggedInPageWrapper } from "./components/logged-in-page-wrapper";
import { ResetPasswordPage } from "./pages/reset-password/ResetPassword";
import { ManageAccountPageSelector } from "./components/manage-profile/ManageAccountPageSelector";
import { AdminPage } from "./pages/admin/AdminPage";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={PageRoute.HOME}
        element={
          <VerifyUser
            loggedInComponent={() => <Navigate replace to={activityPageLink()} />}
            loggedOutComponent={
              <PageTitle title="">
                <Home />
              </PageTitle>
            }
          />
        }
      />
      <Route
        path={PageRoute.LOGIN}
        element={
          <PageTitle title="Login">
            <Login />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.SIGNOUT}
        element={
          <PageTitle title="Signout">
            <Signout />
          </PageTitle>
        }
      />
      {/* Route handling for Buddy Referral */}
      <Route
        path={PageRoute.START}
        element={
          <VerifyUser
            // If user is logged in, direct to Buddies page
            loggedInComponent={() => (
              <PageTitle title="Buddies">
                <VerifyUser
                  loggedInComponent={(user) => (
                    <VerifyCheckin
                      successComponent={
                        // TODO: Refactor LoggedInPageWrapper, there's a better way to do this
                        <LoggedInPageWrapper user={user} location={PageRoute.BUDDIES}>
                          <Buddies userId={user._id} />
                        </LoggedInPageWrapper>
                      }
                      comingBack />
                  )}
                />
              </PageTitle>
            )}
            // If user is logged out, direct to Buddy Invite page
            loggedOutComponent={
              <PageTitle title="Buddy Invitation">
                <BuddyInvitePage />
              </PageTitle>
            }
          />
        }
      />
      <Route
        path={PageRoute.CREATE}
        element={
          <PageTitle title="Create Account">
            <CreateAccount />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.ACTIVITY}
        element={
          <PageTitle title="Activities">
            <VerifyUser
              loggedInComponent={(user) => (
                <VerifyCheckin
                  successComponent={<FullyUnlockedActivityPage user={user} />}
                  comingBack
                />
              )}
            />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.LIBRARY}
        element={
          <PageTitle title="Library">
            <VerifyUser
              loggedInComponent={(user) => (
                <VerifyCheckin successComponent={<LibraryPage user={user} />} comingBack />
              )}
            />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.INSIGHTS}
        element={
          <PageTitle title="Insights">
            <VerifyUser
              loggedInComponent={(user) => (
                <VerifyCheckin successComponent={<InsightsPage user={user} />} comingBack />
              )}
            />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.COMMUNITY_POST}
        element={
          <PageTitle title="Post to Community">
            <VerifyUser
              loggedInComponent={(user) => (
                <VerifyCheckin successComponent={<CommunityPostForm user={user} />} comingBack />
              )}
            />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.CELEBRATE_COMMUNITY_POST}
        element={
          <PageTitle title="Celebrate">
            <VerifyUser
              loggedInComponent={() => (
                <VerifyCheckin successComponent={<CelebrateCommunityPost />} comingBack />
              )}
            />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.COMMUNITY}
        element={
          <PageTitle title="Community">
            <VerifyUser
              loggedInComponent={(user) => (
                <VerifyCheckin successComponent={<Community user={user} />} comingBack />
              )}
            />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.BUDDIES}
        element={
          <PageTitle title="Buddies">
            <VerifyUser
              loggedInComponent={(user) => (
                <VerifyCheckin successComponent={<Buddies userId={user._id} />} comingBack />
              )}
            />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.PROFILE}
      >
          <Route
            index
            element={
              <VerifyUser
                loggedInComponent={(user) => (
                  <VerifyCheckin successComponent={<Me user={user} />} comingBack />
                )}
              />
            }
          />
          <Route
            path="manage/account"
            element={
              <VerifyUser
                loggedInComponent={(user) => (
                  <VerifyCheckin successComponent={<ManageAccountPageSelector user={user} />} comingBack />
                )}
              />
            }
          />
      </Route>
      <Route
        path={PageRoute.INVITATION_CODE}
        element={
          <PageTitle title="Invitation Code">
            <InvitationCodePage />
          </PageTitle>
        }

      />
      <Route
        path={PageRoute.RESET_PASSWORD}
        element={
          <PageTitle title="Reset Password">
            <ResetPasswordPage />
          </PageTitle>
        }
      />
      <Route
        path={PageRoute.ADMIN}
        element={
          <PageTitle title="Admin Page">
            <VerifyUser
              loggedInComponent={(user) => (
                <VerifyAdmin successComponent={<AdminPage />} userType={user.userType}/>
              )}
            />
          </PageTitle>
        }
      />
      <Route path="*" element={<ErrorState message="Sorry, that page does not exist." />} />
    </Routes>
  );
};
