import { useEffect, useState } from "react";
import { IActivityPageResponse } from "../../../client-server-shared/response-types";
import getActivityPage from "../ajax/GetActivityPageAjaxController";
import { QueryReturn } from "./util";

/** Query to get the data for the Activity Page. Gets called on page load. */
export const useGetActivityPage = (): QueryReturn<IActivityPageResponse> => {
  const [data, setData] = useState<IActivityPageResponse | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    getActivityPage()
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    data,
    loading,
    error,
  };
};
