import { ReactNode, useEffect, useState } from "react";
import { Checkbox, FullWidthInput } from "../../components/input/Input";
import { PRIVACY_URL, TERMS_URL, emailAddressPattern } from "../../util/constants";
import { Button } from "../../components/button/Button";
import SpeechBubbleArrow from "../../../assets/accessories/speech-bubble-arrow.svg";
import { getIsEmailAddressAvailable, registerUser } from "../../controllers/ajax/AuthController";
import { loginPageLink } from "../../util/relative-links";
import { Link, useSearchParams } from "react-router-dom";
import { ErrorMessage } from "../../components/error/ErrorMessage";
import { getCode } from "../../controllers/ajax/InvitationCodeController";
import { InvitationCodeStatus, InvitationCodeType } from "../../../client-server-shared/enums";
import { IInvitationCodeGetCodeRequest } from "../../../client-server-shared/response-types";
import { completeOnboardingUser } from "../../controllers/ajax/OnboardingController";


/**
 * Handles account creation as well as the completion of onboarding for users who haven't yet fully
 * completed onboarding (i.e. users who create their account using Google auth).
 * 
 * In the account creation case, it prompts users to enter display name, email address, and
 * password. In the complete onboarding case, it prompts users to enter a display name. Both cases 
 * display a checkbox to agree to our terms of service and privacy policy.
 */
interface Props {
    handleNextButtonClick: () => void;
}

export const NameOrAccountForm: React.FC<Props> = ({ handleNextButtonClick }) => {
    const [displayName, setDisplayName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    // Tracks state of checkbox for agreeing to Terms of Service and Privacy Policy
    const [checkedTos, setCheckedTos] = useState(false);
    // validatingInput and creatingAccount track the state of validating the account creation input
    // and registering the user account. Both require calls to the backend so we disable the action
    // button until the calls have completed.
    const [validatingInput, setValidatingInput] = useState(false);
    const [creatingAccount, setCreatingAccount] = useState(false);
    const [errorMessage, setErrorMessage] = useState<ReactNode>(<></>);
    const [searchParams] = useSearchParams();
    const invitationCode = searchParams.get("invitationCode") || "";
    const referrerCode = searchParams.get("referrer");
    const completeOnboarding = searchParams.has('completeOnboarding');
    const submitInProgress = validatingInput || creatingAccount;
    const continueButtonDisabled = completeOnboarding ? (!displayName || !checkedTos || submitInProgress)
        : (!displayName || !emailAddress || !password || !checkedTos || submitInProgress);

    // This useEffect will detect if there's an invitationCode in the searchParams, and will 
    // prefill the email address input if a matching org is found.
    useEffect(() => {
        if (invitationCode) {
            const request: IInvitationCodeGetCodeRequest = {
                codeString: invitationCode,
                status: InvitationCodeStatus.VERIFIED,
                codeType: InvitationCodeType.ORG_INVITE,
            };
            getCode(request).then((result) => {
                result && setEmailAddress(result.email);
            });
        }
    });

    const handleCreateAccountFlow = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage("");
        if (!emailAddressPattern.test(emailAddress)) {
            setErrorMessage("Please provide a valid email address");
            return;
        }

        setValidatingInput(true);
        getIsEmailAddressAvailable(emailAddress)
            .then((isAvailable) => {
                if (isAvailable) {
                    registerUserAccount(displayName, emailAddress, password);
                } else {
                    setErrorMessage(
                        <span>
                            &quot;{emailAddress}&quot; is already taken.
                            <br />
                            Did you mean to{" "}
                            <Link to={loginPageLink()} className="underline underline-offset-2">
                                log in
                            </Link>
                            ?
                        </span>
                    );
                }
            })
            .catch((e) => {
                console.error(e);
                setErrorMessage(
                    "Sorry, an error occurred while verifying the email address. Please try again later."
                );
            })
            .finally(() => {
                setValidatingInput(false);
            });
    };

    const registerUserAccount = (
        displayName: string,
        emailAddress: string,
        password: string
    ) => {
        setCreatingAccount(true);
        registerUser(emailAddress, password, displayName, true, referrerCode)
            .then((result) => {
                if (result) {
                    handleNextButtonClick();
                } else {
                    setErrorMessage(
                        "Sorry, an error occurred while creating your account. Please try again."
                    );
                }
            })
            .catch((e) => {
                console.error(e);
                setErrorMessage(
                    "Sorry, an error occurred while creating your account. Please try again later."
                );
            })
            .finally(() => {
                setCreatingAccount(false);
            });
    };

    const handleCompleteOnboardingFlow = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setCreatingAccount(true);
        try {
            const result = await completeOnboardingUser(displayName);
            if (result) {
                handleNextButtonClick();
            } else {
                setErrorMessage(
                    "Sorry, an error occurred while completing your onboarding. Please try again."
                );
            }
        } catch (e) {
            console.error(e);
            setErrorMessage(
                "Sorry, an error occurred while completing your onboarding. Please try again later."
            );
        } finally {
            setCreatingAccount(false);
        }
    }
 
    return (<>
        <form onSubmit={completeOnboarding ? handleCompleteOnboardingFlow : handleCreateAccountFlow}
            className="max-w-[443px] md:w-[443px]"
        >
            {/* Speech Bubble */}
            <img src={SpeechBubbleArrow} className="absolute top-[38px] right-1/3"></img>
            <div className="w-full mb-4 border-2 border-[#D2DDDF] rounded-2xl p-5 font-bold text-[#004F56] md:text-xl">
                {completeOnboarding ? "First, what would you like to be called?" : "Let's create your account!"}
            </div>
            {completeOnboarding ?
                <div className="mb-16 mt-8">
                    {/* Name Input Field */}
                    <FullWidthInput value={displayName} onChange={(e) => { setDisplayName(e.target.value); }}
                        placeholder="Preferred Name" />
                </div>
                : <div className="mb-4">
                    {/* Create Account Input Fields (name, email, password) */}
                    <div className="mb-2">
                        <label className="text-sm font-medium text-teal-700">What should we call you?</label>
                        <FullWidthInput value={displayName} onChange={(e) => { setDisplayName(e.target.value); }}
                            placeholder="Name" />
                    </div>
                    <div className="mb-2">
                        <label className="text-sm font-medium text-teal-700">Email Address</label>
                        <FullWidthInput value={emailAddress} onChange={(e) => { setEmailAddress(e.target.value); }}
                            placeholder="example@email.com" />
                    </div>
                    <div className="mb-8">
                        <label className="text-sm font-medium text-teal-700">Password</label>
                        <FullWidthInput type="password" value={password} onChange={(e) => { setPassword(e.target.value); }}
                            placeholder="At least 6 characters" />
                    </div>
                </div>}

            <div className="mb-7">
                {/* Checkbox for Terms of Service and Privacy Policy */}
                <Checkbox
                    checked={checkedTos}
                    onChange={() => { setCheckedTos(!checkedTos); }}
                    label={
                        <p className="font-medium text-xs">
                            Agree to the {" "}
                            <a
                                className="font-semibold external underline"
                                href={PRIVACY_URL}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                                className="font-semibold external underline"
                                href={TERMS_URL}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Terms of Service
                            </a>
                        </p>
                    } />
            </div>
            {/* Action Button */}
            <Button className="w-full"
                disabled={continueButtonDisabled}>
                {validatingInput || creatingAccount ? "Creating Account" : "Continue"}
            </Button>
            <div className="mt-2">
                <ErrorMessage message={errorMessage} />
            </div>
        </form>
    </>)
}