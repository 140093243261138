import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Footer } from "../footer";
import { TopNavBar } from "../top-nav-bar";
import { IUser } from "../../../client-server-shared/response-types";
import { BottomNavBar } from "../bottom-nav-bar";
import { subscribeNotifications, subscribeFCMNotifications } from "../../helper/pushNotifications";
import { PageRoute } from "../../util/constants";
import { DotLottiePlayer } from "@dotlottie/react-player";
import { PopupHandler } from "./PopupHandler";
import { createPageLink } from "../../util/relative-links";

interface LoggedInPageWrapperProps {
  user: IUser;
  location: string;
  children?: ReactNode;
}

export const LoggedInPageWrapper = ({
  user,
  location,
  children,
}: LoggedInPageWrapperProps) => {
  const navigate = useNavigate();
  // If the user has not completed onboarding, redirect them to the Complete Onboarding page
  useEffect(() => {
    if (user && !user.hasCompletedOnboarding) {
      navigate(`${createPageLink()}?completeOnboarding`);
    }
  }, [user, navigate]);

  // Most logged in pages currently show a sun image in the top right, but should be omitted on certain pages.
  const shouldDisplaySunImage = () => {
    return location === PageRoute.PROFILE || location === PageRoute.ACTIVITY;
  };

  // Most page's content is wrapped in a container class to keep reasonable width.
  // Override for pages whose content manages own width, such as when using a unique background.
  const shouldUseFullWidth = () => {
    return location == PageRoute.COMMUNITY;
  };

  try {
    if (process.env.REACT_APP_USE_FCM === "true") {
      subscribeFCMNotifications();
    } else {
      subscribeNotifications();
    }
  } catch (error) {
    console.log("Error when subscribing to push notifications: ", error);
  }

  return (
    <>
      <PopupHandler user={user}/>
      <TopNavBar selected={location} user={user} />
      <div className={classNames("md:pb-10 pb-24", shouldDisplaySunImage() ? "pt-24" : "pt-10")}>
        {shouldDisplaySunImage() && (
          <div className="absolute top-[-88px] right-[-48px] w-[200px] h-[200px] overflow-hidden z-30">
            <DotLottiePlayer
              src="animations/Blinking-Sun.lottie"
              autoplay
              loop
              className="w-full h-full"
            />
          </div>
        )}
        <div className={classNames("space-y-9", { container: !shouldUseFullWidth() })}>
          {children}
          <footer
            className={classNames("md:pb-20", location === "/me" ? "block" : "hidden md:block")}
          >
            <Footer />
          </footer>
        </div>
      </div>
      <BottomNavBar selected={location} />
    </>
  );
};
