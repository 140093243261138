import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../components/button";
import SpeechBubbleArrow from "../../../assets/accessories/speech-bubble-arrow.svg";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ReactNode } from "react";

/** 
 * Provides the layout for the pages of the onboarding flow, including the background image, info
 * and back button. Callers can provide contentText and buttonText to display the contentText in a 
 * speech bubble from Sunnie and the buttonText in an action button at the bottom of the page, or
 * provide their own components to be displayed in the page. 
 */
interface Props {
    backgroundImage: string;
    handleBackButtonClick: () => void;
    contentText?: string;   // if present, shows a text bubble with the provided content.
    buttonText?: string;    // if present, shows an action button with the given text. Handle nextButtonClick will be called on click.
    handleNextButtonClick?: () => void;
    children?: ReactNode;
}

const addLineBreak = (str: string) =>
    str.split('\n').map((subStr) => {
        return (
            <>
                {subStr}
                <br />
            </>
        );
    });

export const OnboardingFlowPage: React.FC<Props> = ({ handleBackButtonClick, handleNextButtonClick, contentText, buttonText, backgroundImage, children }) => {
    return (
        <div className="grid h-screen grid-rows-[auto,1fr]">
            <img src={backgroundImage} alt="Background image" className="w-full object-none md:object-none xl:object-cover h-[240px] md:h-[354px] xl:h-full"/>
            <div className="flex justify-center">
            
                {/* FAQ Link (TODO(Dorothy): change this to an info button that opens to a modal popup instead) */}
                <div className="absolute top-8 right-10">
                    <a href="https://www.flouriship.com/faq" target="_blank" className="font-medium text-sm">FAQ</a>
                </div>
                
                <div className="relative w-[320px] md:w-[443px] mx-6 flex flex-col items-center">
                    <div className="w-full">
                        {/* Back Button */}
                        <button
                            className="p-4"
                            onClick={handleBackButtonClick}
                        >
                            <FontAwesomeIcon icon={icon({ name: "angle-left" })} className="text-teal-500 w-[16px]" />
                        </button>
                    </div>
                    <div className="w-full mb-20">
                        {/* Speech Bubble */}
                        {contentText &&
                            <div>
                                <img src={SpeechBubbleArrow} className="absolute top-[38px] right-1/3"></img>
                                <div className="w-full mb-8 border-2 border-[#D2DDDF] rounded-2xl p-8 font-bold text-[#004F56] md:text-xl">
                                    {addLineBreak(contentText)}
                                </div>
                            </div>}
                        {/* Action Button */}
                        {buttonText &&
                            <Button className="w-full" onClick={handleNextButtonClick}>{buttonText}</Button>
                        }
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};