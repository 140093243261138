import { ActivityCardColor, ActivityData } from "./types";
import { ActivityId } from "../../client-server-shared/enums";
import {
  THREE_GOOD_FORM_ID,
  MEANINGFUL_CONVERSATION_FORM_ID,
  GRATITUDE_NOTE_FORM_ID,
  NATURE_WALK_FORM_ID,
  BEAUTIFUL_MOMENT_FORM_ID,
  BLAST_PAST_FORM_ID,
  GIFTING_COMPLIMENT_FORM_ID,
  FUTURE_SELF_FORM_ID,
  FLOURISHING_SCALE_FORM_ID,
  THREE_GOOD_TYPEFORM_LINK,
  MEANINGFUL_CONVERSATION_TYPEFORM_LINK,
  GRATITUDE_NOTE_TYPEFORM_LINK,
  NATURE_WALK_TYPEFORM_LINK,
  BEAUTIFUL_MOMENT_TYPEFORM_LINK,
  BLAST_PAST_TYPEFORM_LINK,
  GIFTING_COMPLIMENT_TYPEFORM_LINK,
  FUTURE_SELF_TYPEFORM_LINK,
} from "../util/typeform-constants";

// Activity Images
import ThreeGoodImgSmall from "../../assets/illustrations/three-good-small.svg";
import ThreeGoodImgLarge from "../../assets/illustrations/three-good-large.svg";
import GratitudeNoteImgSmall from "../../assets/illustrations/gratitude-note-small.svg";
import GratitudeNoteImgLarge from "../../assets/illustrations/gratitude-note-large.svg";
import MeaningfulConversationImgSmall from "../../assets/illustrations/meaningful-conversation-small.svg";
import MeaningfulConversationImgLarge from "../../assets/illustrations/meaningful-conversation-large.svg";
import NatureWalkImgSmall from "../../assets/illustrations/nature-walk-small.svg";
import NatureWalkImgLarge from "../../assets/illustrations/nature-walk-large.svg";
import GiftingComplimentImgSmall from "../../assets/illustrations/gifting-compliment-small.svg";
import GiftingComplimentImgLarge from "../../assets/illustrations/gifting-compliment-large.svg";
import FutureSelfImgSmall from "../../assets/illustrations/future-self-small.svg";
import FutureSelfImgLarge from "../../assets/illustrations/future-self-large.svg";
import WeeklyCheckinSmall from "../../assets/illustrations/weekly-checkin-small.svg";
import WeeklyCheckinLarge from "../../assets/illustrations/weekly-checkin-large.svg";
import BlastPastImgSmall from "../../assets/illustrations/blast-past-small.svg";
import BlastPastImgLarge from "../../assets/illustrations/blast-past-large.svg";
import BeautifulMomentImgSmall from "../../assets/illustrations/beautiful-moment-small.svg";
import BeautifulMomentImgLarge from "../../assets/illustrations/beautiful-moment-large.svg";
import SomebodyWhoImgSmall from "../../assets/illustrations/somebody-who-small.svg";
import SomebodyWhoImgLarge from "../../assets/illustrations/somebody-who-large.svg";

// Activity Completed Images
import SunHoldingCloud from "../../assets/activity-completed/sun-holding-cloud.svg";

/**
 * This object maps a key value string to the respective imported image of an activity
 * This is used for an `IActivity`'s `images` field
 * (e.g. `ACTIVITY_IMAGE_LOOKUP[<activity>.images.large]`)
 */
export const ACTIVITY_IMAGE_LOOKUP: { [key: string]: string } = {
  ThreeGoodImgSmall: ThreeGoodImgSmall,
  ThreeGoodImgLarge: ThreeGoodImgLarge,
  GratitudeNoteImgSmall: GratitudeNoteImgSmall,
  GratitudeNoteImgLarge: GratitudeNoteImgLarge,
  MeaningfulConversationImgSmall: MeaningfulConversationImgSmall,
  MeaningfulConversationImgLarge: MeaningfulConversationImgLarge,
  NatureWalkImgSmall: NatureWalkImgSmall,
  NatureWalkImgLarge: NatureWalkImgLarge,
  GiftingComplimentImgSmall: GiftingComplimentImgSmall,
  GiftingComplimentImgLarge: GiftingComplimentImgLarge,
  FutureSelfImgSmall: FutureSelfImgSmall,
  FutureSelfImgLarge: FutureSelfImgLarge,
  WeeklyCheckinSmall: WeeklyCheckinSmall,
  WeeklyCheckinLarge: WeeklyCheckinLarge,
  BlastPastImgSmall: BlastPastImgSmall,
  BlastPastImgLarge: BlastPastImgLarge,
  BeautifulMomentImgSmall: BeautifulMomentImgSmall,
  BeautifulMomentImgLarge: BeautifulMomentImgLarge,
  SomebodyWhoImgSmall: SomebodyWhoImgSmall,
  SomebodyWhoImgLarge: SomebodyWhoImgLarge,
};

/**
 * This object maps a key value string to the respective imported image of an ActivityCompleted component
 * This is used for an `IActivity`'s `images` field
 * (e.g. `ACTIVITY_IMAGE_LOOKUP[<activity>.images.large]`)
 */
export const ACTIVITY_COMPLETED_IMAGE_LOOKUP: { [key: string]: string } = {
  SunHoldingCloud: SunHoldingCloud,
};

export const ALL_ACTIVITY_DATA: ActivityData[] = [
  {
    title: "Three Good Things",
    estimatedTime: "2 min",
    desc: "Our favorite activity for savoring the small things in life.",
    color: ActivityCardColor.Yellow,
    backgroundImage: {
      smallData: ThreeGoodImgSmall,
      largeData: ThreeGoodImgLarge,
      position: "bottom-right",
    },
    typeformFormId: THREE_GOOD_FORM_ID,
    trialLink: THREE_GOOD_TYPEFORM_LINK,
  },
  {
    title: "Nature Walk",
    estimatedTime: "5-10 min",
    desc: "A powerful, time-tested method to reboot and recharge.",
    color: ActivityCardColor.Mint,
    backgroundImage: {
      smallData: NatureWalkImgSmall,
      largeData: NatureWalkImgLarge,
      position: "bottom-left",
    },
    typeformFormId: NATURE_WALK_FORM_ID,
    trialLink: NATURE_WALK_TYPEFORM_LINK,
  },
  {
    title: "Beautiful Moment",
    estimatedTime: "2 min",
    desc: "Discover the beauty in your everyday moments.",
    color: ActivityCardColor.Green,
    backgroundImage: {
      smallData: BeautifulMomentImgSmall,
      largeData: BeautifulMomentImgLarge,
      position: "bottom-center",
    },
    typeformFormId: BEAUTIFUL_MOMENT_FORM_ID,
    trialLink: BEAUTIFUL_MOMENT_TYPEFORM_LINK,
  },
  {
    title: "Gifting a Compliment",
    estimatedTime: "2-5 min",
    desc: "A surprisingly easy (and powerful) way to spread joy.",
    color: ActivityCardColor.Mist,
    backgroundImage: {
      smallData: GiftingComplimentImgSmall,
      largeData: GiftingComplimentImgLarge,
      position: "bottom-left",
    },
    typeformFormId: GIFTING_COMPLIMENT_FORM_ID,
    trialLink: GIFTING_COMPLIMENT_TYPEFORM_LINK,
  },
  {
    title: "Gratitude Note",
    estimatedTime: "5-10 min",
    desc: "You can make someone’s day—and yours, too!",
    color: ActivityCardColor.Purple,
    backgroundImage: {
      smallData: GratitudeNoteImgSmall,
      largeData: GratitudeNoteImgLarge,
      position: "bottom-left",
    },
    typeformFormId: GRATITUDE_NOTE_FORM_ID,
    trialLink: GRATITUDE_NOTE_TYPEFORM_LINK,
  },
  {
    title: "Blast from the Past",
    estimatedTime: "3-5 min",
    desc: "Rediscover a gem from the past.",
    color: ActivityCardColor.Blue,
    backgroundImage: {
      smallData: BlastPastImgSmall,
      largeData: BlastPastImgLarge,
      position: "bottom-center",
    },
    typeformFormId: BLAST_PAST_FORM_ID,
    trialLink: BLAST_PAST_TYPEFORM_LINK,
  },
  {
    title: "Meaningful Conversation",
    estimatedTime: "5-15 min",
    desc: "Find a deeper connection with someone special.",
    color: ActivityCardColor.Red,
    backgroundImage: {
      smallData: MeaningfulConversationImgSmall,
      largeData: MeaningfulConversationImgLarge,
      position: "bottom-left",
    },
    typeformFormId: MEANINGFUL_CONVERSATION_FORM_ID,
    trialLink: MEANINGFUL_CONVERSATION_TYPEFORM_LINK,
  },
  {
    title: "Letter from my Future Self",
    estimatedTime: "8-15 min",
    desc: "Discover the wisdom and strength within you.",
    color: ActivityCardColor.Orange,
    backgroundImage: {
      smallData: FutureSelfImgSmall,
      largeData: FutureSelfImgLarge,
      position: "bottom-left",
    },
    typeformFormId: FUTURE_SELF_FORM_ID,
    trialLink: FUTURE_SELF_TYPEFORM_LINK,
  },
  {
    title: "How Flourishing Do You Feel?",
    estimatedTime: "1-2 min",
    desc: "Take stock of your well-being with this short quiz.",
    color: ActivityCardColor.Mint,
    backgroundImage: {
      smallData: WeeklyCheckinSmall,
      largeData: WeeklyCheckinLarge,
      position: "bottom-right",
    },
    typeformFormId: FLOURISHING_SCALE_FORM_ID,
    trialLink: "",
  },
];

/* TODO: Will change name to `ACTIVITY_DATA` once the array version above is no longer
 * needed after the `weekProgress` refactor.
 */
export const ACTIVITY_DATA_OBJECT: { [key: string]: ActivityData } = {
  [ActivityId.THREE_GOOD]: {
    title: "Three Good Things",
    estimatedTime: "2 min",
    desc: "Our favorite activity for savoring the small things in life.",
    color: ActivityCardColor.Yellow,
    backgroundImage: {
      smallData: ThreeGoodImgSmall,
      largeData: ThreeGoodImgLarge,
      position: "bottom-right",
    },
    typeformFormId: THREE_GOOD_FORM_ID,
    trialLink: THREE_GOOD_TYPEFORM_LINK,
  },
  [ActivityId.MEANINGFUL_CONVERSATION]: {
    title: "Meaningful Conversation",
    estimatedTime: "5-15 min",
    desc: "Find a deeper connection with someone special.",
    color: ActivityCardColor.Red,
    backgroundImage: {
      smallData: MeaningfulConversationImgSmall,
      largeData: MeaningfulConversationImgLarge,
      position: "bottom-left",
    },
    typeformFormId: MEANINGFUL_CONVERSATION_FORM_ID,
    trialLink: MEANINGFUL_CONVERSATION_TYPEFORM_LINK,
  },
  [ActivityId.GRATITUDE_NOTE]: {
    title: "Gratitude Note",
    estimatedTime: "5-10 min",
    desc: "You can make someone’s day—and yours, too!",
    color: ActivityCardColor.Purple,
    backgroundImage: {
      smallData: GratitudeNoteImgSmall,
      largeData: GratitudeNoteImgLarge,
      position: "bottom-left",
    },
    typeformFormId: GRATITUDE_NOTE_FORM_ID,
    trialLink: GRATITUDE_NOTE_TYPEFORM_LINK,
  },
  [ActivityId.NATURE_WALK]: {
    title: "Nature Walk",
    estimatedTime: "5-10 min",
    desc: "A powerful, time-tested method to reboot and recharge.",
    color: ActivityCardColor.Mint,
    backgroundImage: {
      smallData: NatureWalkImgSmall,
      largeData: NatureWalkImgLarge,
      position: "bottom-left",
    },
    typeformFormId: NATURE_WALK_FORM_ID,
    trialLink: NATURE_WALK_TYPEFORM_LINK,
  },
  [ActivityId.BEAUTIFUL_MOMENT]: {
    title: "Beautiful Moment",
    estimatedTime: "2 min",
    desc: "Discover the beauty in your everyday moments.",
    color: ActivityCardColor.Cyan,
    backgroundImage: {
      smallData: BeautifulMomentImgSmall,
      largeData: BeautifulMomentImgLarge,
      position: "bottom-center",
    },
    typeformFormId: BEAUTIFUL_MOMENT_FORM_ID,
    trialLink: BEAUTIFUL_MOMENT_TYPEFORM_LINK,
  },
  [ActivityId.BLAST_PAST]: {
    title: "Blast from the Past",
    estimatedTime: "3-5 min",
    desc: "Rediscover a gem from the past.",
    color: ActivityCardColor.Blue,
    backgroundImage: {
      smallData: BlastPastImgSmall,
      largeData: BlastPastImgLarge,
      position: "bottom-center",
    },
    typeformFormId: BLAST_PAST_FORM_ID,
    trialLink: BLAST_PAST_TYPEFORM_LINK,
  },
  [ActivityId.GIFTING_COMPLIMENT]: {
    title: "Gifting a Compliment",
    estimatedTime: "2-5 min",
    desc: "A surprisingly easy (and powerful) way to spread joy.",
    color: ActivityCardColor.Mist,
    backgroundImage: {
      smallData: GiftingComplimentImgSmall,
      largeData: GiftingComplimentImgLarge,
      position: "bottom-left",
    },
    typeformFormId: GIFTING_COMPLIMENT_FORM_ID,
    trialLink: GIFTING_COMPLIMENT_TYPEFORM_LINK,
  },
  [ActivityId.FUTURE_SELF]: {
    title: "Letter from my Future Self",
    estimatedTime: "8-15 min",
    desc: "Discover the wisdom and strength within you.",
    color: ActivityCardColor.Orange,
    backgroundImage: {
      smallData: FutureSelfImgSmall,
      largeData: FutureSelfImgLarge,
      position: "bottom-left",
    },
    typeformFormId: FUTURE_SELF_FORM_ID,
    trialLink: FUTURE_SELF_TYPEFORM_LINK,
  },
};
