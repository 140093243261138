import { CardColor, BackgroundImageConfig } from "../components/card";

export const ActivityCardColor = {
  Red: {
    card: CardColor.Red,
    gradient: "from-red-0",
    color: "red",
    colorHex: "#123123",
    darkColor: "red-600",
    exLightColor: "red-0",
    lightColor: "red-100",
    background: "bg-red-100",
    border: "border-red-600",
    playPause: "fill-red-600",
  },
  Orange: {
    card: CardColor.Orange,
    gradient: "from-orange-0",
    color: "orange",
    colorHex: "#123123",
    darkColor: "orange-600",
    exLightColor: "orange-0",
    lightColor: "orange-100",
    background: "bg-orange-100",
    border: "border-orange-600",
    playPause: "fill-orange-600",
  },
  Yellow: {
    card: CardColor.Yellow,
    gradient: "from-yellow-0",
    color: "yellow",
    colorHex: "#123123",
    darkColor: "yellow-600",
    exLightColor: "yellow-0",
    lightColor: "yellow-100",
    background: "bg-yellow-100",
    border: "border-yellow-600",
    playPause: "fill-yellow-600",
  },
  Mint: {
    card: CardColor.Mint,
    gradient: "from-mint-0",
    color: "mint",
    colorHex: "#123123",
    darkColor: "mint-600",
    exLightColor: "mint-0",
    lightColor: "mint-100",
    background: "bg-mint-100",
    border: "border-mint-600",
    playPause: "fill-mint-600",
  },
  Cyan: {
    card: CardColor.Cyan,
    gradient: "from-cyan-0",
    color: "cyan",
    colorHex: "#123123",
    darkColor: "cyan-600",
    exLightColor: "cyan-0",
    lightColor: "cyan-100",
    background: "bg-cyan-100",
    border: "border-cyan-600",
    playPause: "fill-cyan-600",
  },
  Mist: {
    card: CardColor.Mist,
    gradient: "from-mist-0",
    color: "mist",
    colorHex: "#123123",
    darkColor: "mist-600",
    exLightColor: "mist-0",
    lightColor: "mist-100",
    background: "bg-mist-100",
    border: "border-mist-600",
    playPause: "fill-mist-600",
  },
  Green: {
    card: CardColor.Green,
    gradient: "from-green-0",
    color: "green",
    colorHex: "#123123",
    darkColor: "green-600",
    exLightColor: "green-0",
    lightColor: "green-100",
    background: "bg-green-100",
    border: "border-green-600",
    playPause: "fill-green-600",
  },
  Blue: {
    card: CardColor.Blue,
    gradient: "from-blue-0",
    color: "blue",
    colorHex: "#123123",
    darkColor: "blue-600",
    exLightColor: "blue-0",
    lightColor: "blue-100",
    background: "bg-blue-100",
    border: "border-blue-600",
    playPause: "fill-blue-600",
  },
  Purple: {
    card: CardColor.Purple,
    gradient: "from-purple-0",
    color: "purple",
    colorHex: "#123123",
    darkColor: "purple-600",
    exLightColor: "purple-0",
    lightColor: "purple-100",
    background: "bg-purple-100",
    border: "border-purple-600",
    playPause: "fill-purple-600",
  },
} as const;
export type ActivityCardColor = (typeof ActivityCardColor)[keyof typeof ActivityCardColor];

export interface UserProgress {
  /** Map of form ids to an array of responses to that particular form */
  surveyResponses: TypeformSurveyResponse[];
}

export interface UserInsights {
  /** Map of form ids to an array of responses to that particular form */
  surveyResponses: TypeformSurveyResponse[];
}

export enum TypeformAnswerType {
  NUMBER = "number",
  CHOICE = "choice",
  CHOICES = "choices",
  TEXT = "text",
  LONG_TEXT = "long_text",
}

export enum TypeformDefinitionType {
  MULTIPLE_CHOICE = "multiple_choice",
  NPS = "nps",
  LONG_TEXT = "long_text",
  OPINION_SCALE = "opinion_scale",
}

export interface TypeformAnswer {
  field: { id: string; ref: string; type: string };
  type: TypeformAnswerType;
  number?: number; // only if NUMBER
  choice?: any; // only if CHOICE
  choices?: any; // only if MULTIPLE_CHOICE
  text?: string; // only if TEXT
}

export interface TypeformChoice {
  id: string;
  label: string;
}

export interface TypeformVariable {
  key: string;
  type: TypeformAnswerType;
  number?: number;
  text?: string;
  choice?: any;
  choices?: any;
}

export interface TypeformDefinitionFields {
  id: string;
  properties: any;
  ref: string;
  title: string;
  type: TypeformDefinitionType;
  choices?: TypeformChoice[]; // only if MULTIPLE_CHOICE
}

export interface TypeformDefinition {
  fields: TypeformDefinitionFields[];
  id: string;
  title: string;
}

export interface TypeformSurveyResponse {
  answers: TypeformAnswer[];
  definition: TypeformDefinition[];
  form_id: string;
  landed_at: string;
  submitted_at: string; // TODO: Refactor into Date type
  variables: TypeformVariable[];
}

export interface TypeformLink {
  id: string;
}

interface ActivityBackgroundImageConfig extends Pick<BackgroundImageConfig, "position"> {
  smallData: string;
  largeData: string;
}

export interface ActivityData {
  title: string;
  estimatedTime: string;
  desc: string;
  color: ActivityCardColor;
  backgroundImage: ActivityBackgroundImageConfig;
  typeformFormId: string;
  trialLink?: string;
}