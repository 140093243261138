import { BackgroundImageConfig, getBackgroundImageStyles } from "../card";
import classNames from "classnames";
import { TypeformButtonLink } from "../button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export interface ActivityCardProps {
  backgroundImage: BackgroundImageConfig;
  title: string;
  estimatedTime: string;
  desc: string;
  linkConfig: {
    id: string;
    hiddenData?: string;
  };
}

export const DetailedLibraryActivityCard = ({
  backgroundImage,
  title,
  estimatedTime,
  desc,
  linkConfig,
}: ActivityCardProps) => {
  const { classNames: bgClassNames, styles: bgStyles } = getBackgroundImageStyles({
    ...backgroundImage,
    position: "top-center",
  });
  return (
    <TypeformButtonLink typeformLink={linkConfig} className="w-full" usingDefaultStyles={false}>
      <div className="w-full min-h-[220px] md:min-h-[390px] pb-4 bg-white rounded-2xl border border-neutral-200 flex-col">
        <div
          className={classNames(
            "w-full min-h-[180px] md:min-h-[350px] rounded-t-2xl",
            bgClassNames
          )}
          style={bgStyles}
        />
        <div className="pt-2 pb-1 px-3 flex-col justify-start items-start place-content-end flex">
          <div className="flex-col justify-start items-start gap-1 flex">
            <div className="h-9 justify-between items-center gap-3 inline-flex">
              <div className="text-cyan-950 text-base font-bold leading-normal tracking-tight">
                {title}
              </div>
            </div>
            <div className="self-stretch pr-6 pb-2 justify-start items-center inline-flex">
              <div className="justify-start items-start gap-4 flex">
                <div className="justify-start items-center gap-2 flex pb-3">
                  <div className="w-[18px] h-4 px-[3px] py-0.5 justify-center items-center flex">
                    <FontAwesomeIcon icon={icon({ name: "clock" })} className="text-[#006972]" />
                  </div>
                  <div className="text-teal-900 text-xs font-normal leading-none tracking-tight">
                    {estimatedTime}
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-start text-left text-cyan-950 text-sm font-normal leading-tight tracking-tight">
              {desc}
            </div>
          </div>
        </div>
      </div>
    </TypeformButtonLink>
  );
};
