import SunnieWavingBackground from "../../../assets/illustrations/sunnie-wave-background.svg";
import SunnieHeartBackground from "../../../assets/illustrations/sunnie-heart-background.svg";
import SunnieExcitedBackground from "../../../assets/illustrations/sunnie-excited-background.svg";
import SunnieSmileBackground from "../../../assets/illustrations/sunnie-smile-background.svg";
import SunnieWinkBackground from "../../../assets/illustrations/sunnie-wink-background.svg";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { activityPageLink, startPageLink } from "../../util/relative-links";
import { NameOrAccountForm } from "./NameOrAccountForm";
import { OnboardingFlowPage } from "./OnboardingFlowPage";
import { OnboardingSurveyForm } from "./OnboardingSurveyForm";
import { GoalsSurveyForm } from "./GoalsSurveyForm";

enum ContentStrings {
  WELCOME = "Welcome to Flourish 🌱 \n\nI'm Sunnie, your Flourish Champion!",
  INTRO = "Created with ❤️ by behavioral scientists, I am here to inspire, motivate, and celebrate you along the Flourish Journey. \n\nHere, you will connect with yourself, other people, and the world for more happiness and well-being.",
  READY_TO_START = "Ready to start? \n\nLet's embrace the power of simple actions for a flourishing life!"
}

/** 
 * Handles the onboarding flow, including account creation/onboarding completion (for users that
 * create accounts using Google Auth).
 */
export const CreateAccount: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referrerCode = searchParams.get("referrer");
  const [page, setPage] = useState(0);

  const navigateNext = () => {
    if (page === pages.length - 1) {
      if (referrerCode) {
        // Redirect user to the Start Page w/ the buddy referrer code
        navigate(`${startPageLink()}?referrer=${referrerCode}`);
      } else {
        // Else, just redirect them to the Activity page
        navigate(activityPageLink());
      }
    } else {
      setPage(page + 1);
    }
  }

  const navigateBack = () => {
    if (page === 0) {
      navigate(startPageLink());
    } else {
      setPage(page - 1);
    }
  }

  const pages = [
    {
      // Welcome page
      content: <OnboardingFlowPage
        backgroundImage={SunnieWavingBackground}
        handleBackButtonClick={navigateBack}
        contentText={ContentStrings.WELCOME}
        buttonText={"Hi Sunnie!"}
        handleNextButtonClick={navigateNext}>
      </OnboardingFlowPage>,
    },
    {
      // Intro page
      content: <OnboardingFlowPage
        backgroundImage={SunnieHeartBackground}
        handleBackButtonClick={navigateBack}
        contentText={ContentStrings.INTRO}
        buttonText={"Sounds Good!"}
        handleNextButtonClick={navigateNext}
      />,
    },
    {
      // Create account or complete onboarding page
      content: <OnboardingFlowPage
        backgroundImage={SunnieExcitedBackground}
        handleBackButtonClick={navigateBack}>
        <NameOrAccountForm handleNextButtonClick={navigateNext} />
      </OnboardingFlowPage>,
    },
    {
      // How did you meet Flourish survey page
      content: <OnboardingFlowPage
        backgroundImage={SunnieWinkBackground}
        handleBackButtonClick={navigateBack}>
        <OnboardingSurveyForm handleNextButtonClick={navigateNext} />
      </OnboardingFlowPage>,
    },
    {
      // Goals survey page
      content: <OnboardingFlowPage
        backgroundImage={SunnieExcitedBackground}
        handleBackButtonClick={navigateBack}>
        <GoalsSurveyForm handleNextButtonClick={navigateNext} />
      </OnboardingFlowPage>
    },
    {
      // Ready to start page
      content: <OnboardingFlowPage
        backgroundImage={SunnieSmileBackground}
        handleBackButtonClick={navigateBack}
        contentText={ContentStrings.READY_TO_START}
        buttonText={"Let's Go!"}
        handleNextButtonClick={navigateNext}
      />,
    },
  ];

  return (<>{pages[page].content}</>);
};
