import { useState, useEffect } from "react";

export const ActivityCount = () => {
  const [activityCount, setActivityCount] = useState<number>(0);
  useEffect(() => {
  }, [])
  return (
    <div>
      <header className="space-y-12">
        <h1 className="font-bold text-2xl">
          <span className="text-teal-400">Activity Count</span>
        </h1>
      </header>
      <h2>{activityCount} completed</h2>
    </div>
  );
};