import { useEffect, useState } from "react";
import { CommunityFeedType } from "../../../client-server-shared/enums";
import { ICommunityPostData, IUser, IOrgApplyRequest } from "../../../client-server-shared/response-types";
import { LoadingSpinner } from "../../components/loading";
import { removePost } from "../../controllers/ajax/CommunityPostController";
import { applyOrgInviteCode } from "../../controllers/ajax/InvitationCodeController";
import { useGetCommunityFeed } from "../../controllers/hooks/GetCommunityFeedHook";
import { CommunityPost } from "../../components/community-post/CommunityPost";
import { NoPostsFromBuddies } from "./NoPostsFromBuddies";
import { DefaultCommunityTab } from "./DefaultCommunityTab";
import { getFeed } from "../../controllers/ajax/CommunityController";

interface TabPanelContentProps {
  user: IUser;
  type: CommunityFeedType;
  userCount?: number;
  onCommunityLinkPressed?: () => void;
}
export const TabPanelContent: React.FC<TabPanelContentProps> = ({
  user,
  type,
  userCount,
  onCommunityLinkPressed,
}) => {
  const { data: posts, loading: isLoading, error, refetch } = useGetCommunityFeed(type);
  const [communityPosts, setCommunityPosts] = useState<ICommunityPostData[]>([]);
  const [hasMore, setHasMore] = useState(true); // Flag for more posts availability
  const [page, setPage] = useState(1); // Keep track of current page

  // This will set the `communityPosts` state with the posts received from the API
  useEffect(() => {
    if (posts) {
      setCommunityPosts(posts);
    }
  }, [posts]);

  // This will call the `getFeed` endpoint with a given type and page, and set the `communityPosts`
  // with the response.
  useEffect(() => {
    // fetchPosts is assumed to be a method to fetch posts based on page and type
    const fetchMorePosts = async () => {
      const newPosts = await getFeed(type, page);
      if (newPosts.length > 0) {
        setCommunityPosts((prevPosts) => [...prevPosts, ...newPosts]);
      } else {
        setHasMore(false); // Disable further loading if no more posts
      }
    };

    if (page > 1) {
      fetchMorePosts();
    }
  }, [page, type]);

  // Scroll Listener for Loading More Posts
  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
    if (bottom && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page to load next set
    }
  };

  function inviteAccepted(): void {
    const req: IOrgApplyRequest = {
      codeString: "",
      toJoin: true,
    }
    applyOrgInviteCode(req).then(() => {
      window.location.reload();
    });
  }
  const handleLinkPress = () => {
    // Check if onCommunityLinkPressed is defined before calling it
    if (onCommunityLinkPressed) {
      onCommunityLinkPressed();
    }
  };
  // Remove specified post from DB and update front-end state.
  async function onRemove(postId: string) {
    const success = await removePost(postId);
    if (success) {
      // Update front-end data to match.
      const updatedCommunityPosts = communityPosts?.filter((post) => post._id != postId);
      setCommunityPosts(updatedCommunityPosts);
    } else {
    }
  }

  // After we update the post on the server, update feeds to use new data, if updatedPost changed it's privacy level then remove it from the  communityPosts
  function updatePost(updatedPost: ICommunityPostData) {
    const filteredPosts = communityPosts?.filter((post) => {
      return post._id !== updatedPost._id || type.toString() === updatedPost.privacyLevel;
    });

    const updatedPosts = filteredPosts?.map((post) =>
      post._id === updatedPost._id ? updatedPost : post
    );

    setCommunityPosts(updatedPosts);
  }
  switch (type) {
    case CommunityFeedType.Buddies:
      return isLoading ? (
        <LoadingSpinner />
      ) : communityPosts && communityPosts.length > 0 ? (
        <div
          className="pt-4 container pb-12 overflow-auto h-screen"
          onScroll={handleScroll}
        >
          {communityPosts.map((post) => (
            <CommunityPost
              key={post._id}
              post={post}
              userId={user._id}
              org={user.organization?.name}
              onRemove={() => onRemove(post._id)}
              onUpdate={() => updatePost(post)}
            />
          ))}
        </div>
      ) : (
        <NoPostsFromBuddies user={user} onCommunityLinkPressed={handleLinkPress} />
      );
    case CommunityFeedType.World:
      return isLoading ? (
        <LoadingSpinner />
      ) : communityPosts && communityPosts.length > 0 ? (
        <div
          className="pt-4 container pb-12"
          onScroll={handleScroll}
          style={{ overflowY: "auto", height: "100vh" }}
        >
          {communityPosts.map((post) => (
            <CommunityPost
              key={post._id}
              post={post}
              userId={user._id}
              org={user.organization?.name}
              onRemove={() => onRemove(post._id)}
              onUpdate={() => updatePost(post)}
            />
          ))}
        </div>
      ) : (
        <p>No posts found</p>
      );
    case CommunityFeedType.Organization:
      return user.organization ? (
        <div className="container pb-12">
          <div className="flex items-center justify-between py-4 mb-2">
            <div className="flex">
              <h1 className="font-semibold text-2xl text-teal-600 md:pt-0 md:mt-0 text-base">
                {user.organization?.name}
              </h1>
            </div>
            <div className="flex text-teal-600 text-sm">{userCount} members</div>
          </div>
          {/* Either the spinner or the posts */}
          {isLoading ? (
            <LoadingSpinner />
          ) : communityPosts && communityPosts.length > 0 ? (
            <div
              className="pt-4"
              onScroll={handleScroll}
              style={{ overflowY: "auto", height: "100vh" }}
            >
              {communityPosts.map((post) => (
                <CommunityPost
                  key={post._id}
                  post={post}
                  userId={user._id}
                  org={user.organization?.name}
                  onRemove={() => onRemove(post._id)}
                  onUpdate={() => updatePost(post)}
                />
              ))}
            </div>
          ) : (
            <p className="px-4">No posts found</p>
          )}
        </div>
      ) : (
        <DefaultCommunityTab
          onInviteAccepted={inviteAccepted}
          organizationName={user.invitationOrgName}
        />
      );
  }
};
