import { useCallback, useEffect, useState } from "react";
import { CommunityFeedType } from "../../../client-server-shared/enums";
import { ICommunityPostData } from "../../../client-server-shared/response-types";
import { QueryReturn } from "./util";
import { getFeed } from "../ajax/CommunityController";

/** Query to get user progress. Gets called on page load. */
export const useGetCommunityFeed = (
  feedType: CommunityFeedType,
  page = 1
): QueryReturn<ICommunityPostData[]> & { refetch: () => void } => {
  const [data, setData] = useState<ICommunityPostData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetchData = useCallback(() => {
    setLoading(true);
    getFeed(feedType, page)
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  };
};
