import { ButtonNavigate, ButtonColor, ButtonLink, ButtonSize } from "../../components/button";
import GoogleIcon from "../../../assets/icons/icon-google.svg";

interface SignInComponentInterface {
    googleLoginUrl: string;
    createAccountUrl: string;
    loginUrl: string;
  }

/**
 * Displays a standard sign in component with buttons for Google login, create account, and regular login.
 */
export const SignInComponent = ({googleLoginUrl, createAccountUrl, loginUrl} : SignInComponentInterface) => {

  return (
        <div className="flex flex-col items-end gap-4 w-full">
            {/* Google Login Button */}
            <ButtonLink 
              size={ButtonSize.Full} 
              color={ButtonColor.TealUnfilled} 
              to={googleLoginUrl}
              className="border-2"
            >
              <div className="flex items-center justify-center space-x-2">
                <img
                  src={GoogleIcon}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
                <span>Continue with Google</span>
              </div>
            </ButtonLink>
            {/* "or" divider */}
            <div className="w-full relative p-1">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <span className="px-2 bg-white text-sm text-gray-500">or</span>
              </div>
            </div>
            {/* Create Account button */}         
            <ButtonNavigate
              size={ButtonSize.Full} 
              color={ButtonColor.TealUnfilled} 
              to={createAccountUrl}
              className="border-2"
            >
              Create Account
            </ButtonNavigate>
            {/* Login button */}
            <ButtonNavigate
              size={ButtonSize.Full} 
              color={ButtonColor.TealUnfilled} 
              to={loginUrl}
              className="border-2"
            >
              Login
            </ButtonNavigate>
          </div>
  );
};
