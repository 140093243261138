import { Goal } from "../../../client-server-shared/enums";
import patchData from "../helper/patchData";
import { COMPLETE_ONBOARDING_USER_URL, SAVE_GOALS_URL } from "../helper/requestConstants";

/**
 * This controller will make a PATCH call to the endpoint `/api/auth/completeOnboarding`.
 * It will eventually be returned a promise of whether or not the onboarding was successful
 * if the user's display name and privacy acception was patched in the database.
 */
export async function completeOnboardingUser(
    displayName: string,
  ): Promise<boolean> {
    const [registerResponse] = await Promise.all([
      patchData(COMPLETE_ONBOARDING_USER_URL, {
        displayName,
        register: true,
      }),
    ]);
    return Boolean(registerResponse.success);
  }
  
  /**
   * Sends a patch call to save the provided array of user goals to the database. Returns a Promise
   * indicating whether the patch was successful.
   */
  export async function saveGoals(goals: Goal[]): Promise<boolean> {
    const [saveResponse] = await Promise.all([
      patchData(SAVE_GOALS_URL, {
        goals: goals,
      }),
    ]);
    return Boolean(saveResponse.success);
  }