import { IBlockUserRequest, IUser } from "../../../client-server-shared/response-types";
import postData from "../helper/postData";
import patchData from "../helper/patchData";
import deleteData from "../helper/deleteData";
import { GET_USER_URL, CREATE_CUSTOM_ACTIVITY_URL, PATCH_USER_LAST_INVITATION_DATE, BLOCK_USER_URL, DELETE_USER_URL } from "../helper/requestConstants";

export async function getUser(): Promise<IUser | undefined> {
  // Create a new TimezoneOffset from the client, that will update the user's timezone in the DB.
  const requestBody = {
    timezoneOffset: new Date().getTimezoneOffset(),
  };
  const [response] = await Promise.all([postData(GET_USER_URL, requestBody)]);
  // Convert `lastActive` back to a Date type
  if (response.success && response.user) {
    response.user.lastActive = new Date(response.user.lastActive);
  }
  return response.success ? response.user : undefined;
}

export async function patchLastInvitationDate(): Promise<boolean> {
  const response = await patchData(PATCH_USER_LAST_INVITATION_DATE);
  return Boolean(response.success);
}

export async function createCustomActivity(activity: string): Promise<boolean> {
  const response = await postData(CREATE_CUSTOM_ACTIVITY_URL, {
    activity: activity,
  });
  return Boolean(response.success);
}

export async function blockUser(req: IBlockUserRequest): Promise<boolean> {
  const response = await postData(BLOCK_USER_URL, req);
  return Boolean(response.success);
}

export async function deleteUser(): Promise<boolean> {
  const response = await deleteData(DELETE_USER_URL);
  return Boolean(response.success);
}
