import { useState, useRef } from "react";
import { IBuddyData } from "../../../client-server-shared/response-types";
import MoreIcon from "../../../assets/icons/icon-more.svg";
import { PopDownMenu } from "../../components/pop-down-menu";

interface Props {
  onRemove: (buddyId: string) => void;
  buddy: IBuddyData;
}

// Component displaying a single buddy record.
export const BuddyListItem = ({ buddy, onRemove }: Props) => {
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const avatar = buddy.avatar ?? "default.svg";
  const showMoreMenuButtonRef = useRef(null);
  return (
    <div className="py-6 px-1 border-[#DDE5DA] border-b flex items-center">
      <img src={"avatars/" + avatar} width="36" height="36" className="mr-4" />
      <div className="flex-grow">
        <h3 className="font-bold text-teal-400">{buddy.displayName}</h3>
        <h4 className="text-sm text-[#1C1B1E]">{buddy.daysActive} days on Flourish</h4>
      </div>
      <div className="relative">
        <img
          onClick={() => setShowMoreMenu(!showMoreMenu)}
          src={MoreIcon}
          className="cursor-pointer"
          ref={showMoreMenuButtonRef}
        />
        {showMoreMenu && (
          <PopDownMenu
            onClose={() => setShowMoreMenu(false)}
            positionRight={true}
            showCloseButton={false}
            buttonRef={showMoreMenuButtonRef}
          >
            <a
              onClick={() => onRemove(buddy._id)}
              className="text-black cursor-pointer hover:text-red-400"
            >
              Delete
            </a>
          </PopDownMenu>
        )}
      </div>
    </div>
  );
};
