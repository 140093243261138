/**
 * This file contains utils related to the Daily Mood components.
 */
import { PulseScale } from "../../../client-server-shared/enums";
import MoodBackground0 from "../../../assets/backgrounds/daily-mood/mood-background-0.svg";
import MoodBackground1 from "../../../assets/backgrounds/daily-mood/mood-background-1.svg";
import MoodBackground2 from "../../../assets/backgrounds/daily-mood/mood-background-2.svg";
import MoodBackground3 from "../../../assets/backgrounds/daily-mood/mood-background-3.svg";
import MoodBackground4 from "../../../assets/backgrounds/daily-mood/mood-background-4.svg";

// This is the type for the data that will be returned from `moodMapping`
interface MoodData {
    backgroundImage: string,
    subText: string,
    iconColor: string,
    emotionSet: MoodEmotionSet,
    isPositive: boolean,
    quote: {
        text: string[], // Each member of the array will alternate between bolded and non-bolded text
        author?: string,
    },
}
// A set that contains two arrays: The positive and negative keyword lists.
interface MoodEmotionSet {
    positiveKeywords: string[],
    negativeKeywords: string[],
}
// This emotion set is for mood scale ratings 2 or above
const POSITIVE_EMOTION_SET: MoodEmotionSet = {
    positiveKeywords: ["calm", "curious", "excited", "grateful", "happy", "hopeful", "inspired", "loving", "motivated"],
    negativeKeywords: ["overwhelmed", "tired", "stressed"],
}
// This emotion set is for mood scale ratings less than 2
const NEGATIVE_EMOTION_SET: MoodEmotionSet = {
    positiveKeywords: ["calm", "inspired", "curious", "hopeful"],
    negativeKeywords: ["anxious", "bored", "confused", "lonely", "overwhelmed", "sad", "tired", "stressed"],
}
const POSITIVE_SUBTEXT = "Nice!";
const NEUTRAL_SUBTEXT = "Got it!";
const NEGATIVE_SUBTEXT = "I see...";
/** 
 * This serves as a mapping to access scale-specific data such as the background 
 * image and emotional keyword set.
 */
export const moodMapping: { [key in PulseScale]: MoodData } = {
    [PulseScale.UNKNOWN]: {
        backgroundImage: MoodBackground2,
        subText: NEUTRAL_SUBTEXT,
        iconColor: "text-teal-700",
        emotionSet: POSITIVE_EMOTION_SET,
        isPositive: false,
        quote: {
            text: [],
        }
    },
    [PulseScale["Really terrible"]]: {
        backgroundImage: MoodBackground0,
        subText: NEGATIVE_SUBTEXT,
        iconColor: "text-white",
        emotionSet: NEGATIVE_EMOTION_SET,
        isPositive: false,
        quote: {
            text: ["Just as the tide rises and falls, so do our emotions. This too shall pass, and when you rise again, you will be stronger and more resilient than ever before. You've got this 💫"],
        }
    },
    [PulseScale["Somewhat bad"]]: {
        backgroundImage: MoodBackground1,
        subText: NEGATIVE_SUBTEXT,
        iconColor: "text-white",
        emotionSet: NEGATIVE_EMOTION_SET,
        isPositive: false,
        quote: {
            text: ["In nature, nothing is perfect and everything is perfect. Trees can be contorted, bent in weird ways, and they're still beautiful."],
            author: "Alice Walker",
        }
    },
    [PulseScale["Okay"]]: {
        backgroundImage: MoodBackground2,
        subText: NEUTRAL_SUBTEXT,
        iconColor: "text-teal-700",
        emotionSet: POSITIVE_EMOTION_SET,
        isPositive: false,
        quote: {
            text: [
                "", 
                "Life isn't always rainbows and sunshine, and that's perfectly okay 😌\n\nRemember, as Charles R. Swindoll said, ", 
                "\"Life is 10% what happens to you and 90% how you react to it.\""
            ],
        }
    },
    [PulseScale["Pretty good"]]: {
        backgroundImage: MoodBackground3,
        subText: POSITIVE_SUBTEXT,
        iconColor: "text-teal-700",
        emotionSet: POSITIVE_EMOTION_SET,
        isPositive: true,
        quote: {
            text: ["Enjoy the little things in life, for one day you may look back and realize they were the big things."],
            author: "Robert Brault",
        }
    },
    [PulseScale["Super awesome"]]: {
        backgroundImage: MoodBackground4,
        subText: POSITIVE_SUBTEXT,
        iconColor: "text-teal-700",
        emotionSet: POSITIVE_EMOTION_SET,
        isPositive: true,
        quote: {
            text: ["In the end, it's not the years in your life that count. It's the life in your years."],
            author: "Abraham Lincoln"
        }
    },
}