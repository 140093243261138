import { useEffect, useState } from "react";
import { Card } from "../card";
import { SaveCard } from "./SaveCard";
import { IShareCard } from "../../../client-server-shared/response-types";
import { format } from "date-fns";

import SaveIcon from "../../../assets/icons/icon-download.svg";
import ShareIcon from "../../../assets/icons/icon-share.svg";
import CloseIcon from "../../../assets/icons/icon-close.svg";
import Swirl2 from "../../../assets/accessories/swirl2.svg";

import { ACTIVITY_IMAGE_LOOKUP } from "../../data/week-activities-mock";
import * as htmlToImage from "html-to-image";
import { ENABLE_LINKS_TO_COMMUNITY } from "../../util/constants";
import { useNavigate } from "react-router-dom";
import { postToCommunityPageLink } from "../../util/relative-links";
import { isIOS } from "../../helper/tools";

export const ShareCard = ({
  shareCardData,
  popup,
  insights,
  setShowShareCard,
}: {
  shareCardData: IShareCard;
  popup?: boolean;
  insights?: boolean;
  setShowShareCard?: () => void;
}) => {
  const [renderSaveCard, setRenderSaveCard] = useState(false);
  const [webShareSupported, setWebShareSupported] = useState(false);
  const navigate = useNavigate();
  const shareCardImgName = "share-activity-card.png";

  // TODO(hope): Could be refactored into top-level component for browser compatibility check.
  useEffect(() => {
    if ("canShare" in navigator && "share" in navigator) {
      setWebShareSupported(true);
    }
  });

  const handleSave = async () => {
    await setRenderSaveCard(true);

    const saveCard = document.getElementById("save-card");
    saveCard!.style.width = "350";
    const dataUrl = await htmlToImage.toPng(saveCard!);

    // download image
    const link = document.createElement("a");
    link.download = shareCardImgName;
    link.href = dataUrl;
    link.click();
    setRenderSaveCard(false);
  };

  const handleWebShare = async () => {
    await setRenderSaveCard(true);

    const saveCard = document.getElementById("save-card");
    saveCard!.style.width = "350";

    if (isIOS()) {
      // Workaround to fix the bg image not fully rendered issue in some cases of iOS and Safari.
      // https://github.com/bubkoo/html-to-image/issues/361#issuecomment-1402537176
      await htmlToImage.toCanvas(saveCard!);
      await htmlToImage.toCanvas(saveCard!);
    }
    const canvas = await htmlToImage.toCanvas(saveCard!);

    canvas.toBlob(async (blob) => {
      if (!blob) {
        // Shouldn't happen but put it here for debugging purpose.
        console.error("Could not convert canvas to blob.");
        setRenderSaveCard(false);
        return;
      }

      const files = [new File([blob as BlobPart], shareCardImgName, { type: blob.type })];
      const shareData = { files: files };
      try {
        await navigator.share(shareData);
      } catch (err) {
        if ((err as Error).name !== "AbortError") {
          console.error("Share failed:", err);
          // If share fails, propmt to download the image instead.
          handleSave();
        }
      }
    });
    setRenderSaveCard(false);
  };

  return (
    <>
      {/* "After this activity I felt.." Card */}
      {insights && (
        <div className="w-full min-h-[120px] bg-teal-0 rounded-3xl py-4 px-3">
          <div className="grid grid-cols-2 justify-between">
            <div>
              <p className="text-xs font-medium text-gray-500">After this activity, I felt...</p>
              <ul className="pt-2 flex flex-wrap gap-2">
                {shareCardData.emotions?.map((label, index) => {
                  const feelingString =
                    label.toString().charAt(0).toUpperCase() + label.toString().slice(1);
                  return (
                    <li key={index} className="px-2 py-[2px] bg-teal-50 rounded">
                      <p className="text-sm font-medium text-teal-900">{feelingString}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
      {/* Main card */}
      <div id="share-card" className="w-full rounded-xl overflow-hidden">
        <Card>
          {!insights && (
            <header className="md:px-[55px] px-4 pt-3 pb-4 min-h-[91px] flex flex-col justify-center space-y-1">
              {popup && (
                <div className="flex items-center justify-end min-h-[44px]">
                  <button className="flex items-center justify-center min-h-[44px] min-w-[44px]">
                    <img src={CloseIcon} alt="" onClick={setShowShareCard} />
                  </button>
                </div>
              )}
              <h2 className="font-semibold text-lg text-teal-700 leading-4">
                Sharing is caring ☀️
              </h2>
              <p className="leading-4 pt-1">Time to share with your Flourish Buddies!</p>
            </header>
          )}

          <div className="text-white bg-[#006972] md:px-[55px] px-4 pt-6 pb-9 flex min-h-[250px]">
            <div className="relative sm:min-w-[350px] w-full space-y-3 flex flex-col">
              <header className="min-h-[60px] flex justify-between gap-x-3">
                <div className="flex flex-col justify-center">
                  <p>Flourish Daily</p>
                  <h2 className="font-bold text-lg text-white">{shareCardData.title}</h2>
                </div>
                <img
                  className="h-[60px] min-w-[60px] w-[60px] object-cover rounded-xl"
                  src={ACTIVITY_IMAGE_LOOKUP[shareCardData.image]}
                  alt=""
                />
              </header>
              <ul className="list-disc list-inside font-semibold space-y-2">
                {shareCardData.highlightedText.map((highlight, index) => {
                  return (
                    <div key={index}>
                      <p>{highlight}</p>
                    </div>
                  );
                })}
              </ul>

              <div className="flex justify-end pt-1">
                {insights ? (
                  // If Web Share API is supported, show `Share` instead of `Save`.
                  <button
                    className="min-h-[27px] min-w-[72px] flex justify-center items-center gap-x-2 bg-white rounded-[20px] z-10"
                    onClick={webShareSupported ? handleWebShare : handleSave}
                  >
                    <img src={webShareSupported ? ShareIcon : SaveIcon} alt="" />
                    <p className="text-sm font-medium text-teal-500 pb-[1px]">
                      {webShareSupported ? "Share" : "Save"}
                    </p>
                  </button>
                ) : (
                  //  Show date for Activity page
                  <p>{format(new Date(shareCardData.submittedAt), "MM/dd/yyyy")}</p>
                )}
              </div>
              <div className="absolute bottom-[-36px] right-0 overflow-auto">
                <img src={Swirl2} alt="" />
              </div>
            </div>
          </div>

          {!insights && (
            <div className="md:px-[55px] px-4 pt-4 pb-3 flex justify-end gap-x-2">
              {webShareSupported ? (
                <button
                  className="min-h-[44px] flex items-center bg-[#006972] text-white rounded-full px-4 font-bold"
                  onClick={handleWebShare}
                >
                  Send
                </button>
              ) : (
                // Display `Save` when Web Share API is not supported.
                <button
                  className="min-h-[44px] flex items-center bg-[#006972] text-white rounded-full px-4 font-bold"
                  onClick={handleSave}
                >
                  Save
                </button>
              )}
              {ENABLE_LINKS_TO_COMMUNITY && (
                <button
                  className="min-h-[44px] flex items-center bg-[#006972] text-white rounded-full px-4 font-bold"
                  onClick={() =>
                    navigate(postToCommunityPageLink(), {
                      state: {
                        activityId: shareCardData.activityId,
                        highlights: shareCardData.highlightedText,
                      },
                    })
                  }
                >
                  Post
                </button>
              )}
            </div>
          )}
        </Card>
      </div>
      <div style={{ position: "absolute", opacity: "0" }}>
        {/* Re-render the whole div for `SaveCard` to avoid DOM id mess-up. */}
        {renderSaveCard && (
          <div id="save-card">
            <SaveCard shareCardData={shareCardData} />
          </div>
        )}
      </div>
    </>
  );
};
