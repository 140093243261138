import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { TypeformButtonLink } from "../button";
import { Card, CardColor } from "../card";
import ReadingSun from "../../../assets/illustrations/scale-sun.svg";
import { getLatestScaleDetailsData } from "./ScaleDetailsCard";
import { FLOURISHING_SCALE_FORM_ID } from "../../util/typeform-constants";
import { useTypeform } from "../../controllers/hooks";

// This card is shown on the Insights page when the user has not completed the onboarding.
export const LockedScaleDetailsCard = ({ userId }: { userId: string }) => {
  const host = window.location.host;
  const dataTfHidden = `id=${userId},host=${host}`;
  useTypeform();
  return (
    <Card color={CardColor.Gray}>
      <div className="min-w-[343px] bg-teal-0 rounded-3xl pl-3 pr-3 pt-4 pb-6 space-y-3">
        <div className="flex flex-row flex-wrap space-y-3">
          <div className="flex md:w-1/2">
            <img className="h-24" src={ReadingSun} />
            <div className="pl-3 pt-4 space-y-2">
              <div className="text-teal-600 text-sm font-semibold">
                Waiting for your baseline measurement result.
              </div>
              <TypeformButtonLink
                typeformLink={{
                  id: FLOURISHING_SCALE_FORM_ID,
                  title: "Start",
                  hiddenData: dataTfHidden,
                }}
              >
                Let&apos;s Go!
              </TypeformButtonLink>
            </div>
          </div>

          <div className="w-[310px] h-[200px] pt-2 md:block md:mx-auto">
            <ResponsiveContainer>
              <RadarChart data={getLatestScaleDetailsData()}>
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="dimension"
                  tick={{ fontSize: 10, fontWeight: 600, fill: "#147180", fillOpacity: "0.5" }}
                />
                <PolarRadiusAxis domain={[0, 7]} tick={false} />
                <Radar
                  name="LatestScaleDetails"
                  dataKey="score"
                  stroke="#7CBDC6"
                  fill="#49ADBB"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </Card>
  );
};
