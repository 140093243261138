import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

export const VerifyCheckin = ({
  successComponent,
}: {
  successComponent: ReactNode;
  /** Whether the user would be going to the checkin page after some time has passed after creating their account */
  // TODO: better name
  comingBack?: boolean;
}) => {
  // Workaround to skip requerying if the user has just completed the checkin -- this is needed because there is a bit of a lag between the user submitting the survey and the data getting logged in the server
  const location = useLocation();
  return <>{successComponent}</>;
};
