import { useState } from "react";
import SpeechBubbleArrow from "../../../assets/accessories/speech-bubble-arrow.svg";
import { Button } from "../../components/button";
import { FullWidthInput, RadioOption } from "../../components/input/Input";
import { Select, Option } from "@material-tailwind/react";
import { useGetOrganizationNameList } from "../../controllers/hooks/GetOrganizationListHook";
import { addUserOriginInfo } from "../../controllers/ajax/AuthController";
import { OriginType } from "../../../client-server-shared/enums";
import { IUserOrigin } from "../../../client-server-shared/response-types";


interface OnboardingSurveyProps {
    handleNextButtonClick: () => void;
}

/**
 * Provides an onboarding survey form that prompts users to indicate where they discovered 
 * Flourish, with options for their org/team, the Flourish team, friends or family, or other. 
 * 
 * Users can select from a dropdown menu of orgs/teams registered with Flourish, or provide further
 * details in an input box if they select "Other".
 */
export const OnboardingSurveyForm: React.FC<OnboardingSurveyProps> = ({ handleNextButtonClick }) => {
    const { data: organizationNames } = useGetOrganizationNameList();

    // Tracks the origin option the user has selected.
    const [userOriginType, setUserOriginType] = useState(OriginType.UNKNOWN);
    // Tracks the org/team selected by the user from the dropdown in the org/team option.
    const [selectedOrg, setSelectedOrg] = useState("");
    // Tracks the input text if the user selects origin option "Other".
    const [otherInput, setOtherInput] = useState("");

    // Action button is disabled until user selects one of the origin options. In the "org/team" 
    // case, they must also choose an org from the dropdown, and in the "other" case, they must
    // enter something into the associated input text box. 
    const disabled = userOriginType === OriginType.UNKNOWN
        || (userOriginType === OriginType.OTHER && otherInput === "") ||
        (userOriginType === OriginType.ORG_OR_TEAM && selectedOrg === "");

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const userOriginInfo : IUserOrigin = {
            type: userOriginType,
        };
        if (userOriginType === OriginType.OTHER) {
            userOriginInfo.otherText = otherInput;
        } else if (userOriginType === OriginType.ORG_OR_TEAM) {
            userOriginInfo.orgName = selectedOrg;
        }
        await addUserOriginInfo(userOriginInfo);
        handleNextButtonClick();
    }

    return (<>
        <form onSubmit={onSubmit}
            className="max-w-[443px] md:w-[443px]">
            {/* Speech Bubble */}
            <img src={SpeechBubbleArrow} className="absolute top-[38px] right-1/3"></img>
            <div className="w-full mb-4 border-2 border-[#D2DDDF] rounded-2xl p-5 font-bold text-[#004F56] md:text-xl">
                How did you meet Flourish?
            </div>
            {/* Input area */}
            <div className="mb-12 px-2.5">
                <span className="text-gray-400 font-semibold text-sm">Through</span>
                <div>
                    <RadioOption value="1" label="My org/team"
                        onChange={() => setUserOriginType(OriginType.ORG_OR_TEAM)} />
                    {/* Org/Team dropdown */}
                    {userOriginType === OriginType.ORG_OR_TEAM &&
                        <div className="ml-12">
                            <Select label="Select an org" color="teal"
                                onChange={(selected:string | undefined) => setSelectedOrg(selected ? selected : "")}>
                                {organizationNames?.map((orgName, index) =>
                                    (<Option key={index} value={orgName}>{orgName}</Option>))}
                            </Select>
                        </div>}
                    <RadioOption value="2" label="The Flourish team"
                        onChange={() => setUserOriginType(OriginType.FLOURISH_TEAM)} />
                    <RadioOption value="3" label="Friends or family"
                        onChange={() => setUserOriginType(OriginType.FRIENDS_OR_FAMILY)} />
                    <RadioOption value="4" label="Other"
                        onChange={() => setUserOriginType(OriginType.OTHER)} />
                    {/* "Other" input field */}
                    {userOriginType === OriginType.OTHER &&
                        <div className="ml-12">
                            <FullWidthInput value={otherInput} onChange={(e) => setOtherInput(e.target.value)}
                                placeholder="Other source" />
                        </div>}
                </div>
            </div>
            {/* Action Button */}
            <Button className="w-full" disabled={disabled} type="submit">
                Continue
            </Button>
        </form>
    </>);


}