import { useEffect, useState } from "react";
import { QueryReturn } from "./util";
import { getUser } from "../ajax/UserController";
import { IUser } from "../../../client-server-shared/response-types";

export const useGetUser = (): QueryReturn<IUser> => {
  const [data, setData] = useState<IUser | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  useEffect(() => {
    getUser()
      .then((user) => {
        if (user) {
          setData(user);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return {
    data,
    loading,
    error,
  };
};
