import React, { useEffect, useState } from "react";
import {
  generateAndSendCode,
  getCodesByOrg,
} from "../../controllers/ajax/InvitationCodeController";
import { IInvitationCode } from "../../../client-server-shared/response-types";

interface Organization {
  name: string;
  description?: string;
}
interface OrganizationItemProps {
  org: Organization;
  handleDelete: (orgName: string) => void;
}
const OrganizationItem: React.FC<OrganizationItemProps> = ({ org, handleDelete }) => {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [userEmails, setUserEmails] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [userInvites, setUserInvites] = useState<IInvitationCode[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleUserSubmit = () => {
    generateAndSendCode(org.name, userEmails).then((success) => {
      if (success) {
        setUserEmails("");
        setIsUserModalOpen(false);
      } else {
        alert("failed");
        console.log("FAILED");
      }
    });
  };
  const remove = () => {
    handleDelete(org.name);
    setIsDeleteModalOpen(false);
  };
  useEffect(() => {
    if (isExpanded) {
      setIsLoading(true);
      fetchUsersForOrganization(org.name).then((fetchedUsers) => setUserInvites(fetchedUsers));
      setIsLoading(false);
    }
  }, [isExpanded, org.name]);
  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };
  const fetchUsersForOrganization = async (orgName: string): Promise<IInvitationCode[]> => {
    return getCodesByOrg(orgName);
  };
  return (
    <div className="org-item bg-gray-100 p-4 rounded shadow-lg relative">
      <div className="flex">
        <h3 onClick={toggleExpanded} className="flex-1 cursor-pointer">
          {org.name}
        </h3>
        <p className="flex-1 font-sm">{org.description}</p>
        <button
          className="font-semibold py-2 px-4 text-[#006972] border border-teal-700 rounded shadow mx-auto"
          onClick={() => setIsUserModalOpen(true)}
        >
          Invite
        </button>
        <button
          className="font-semibold py-2 px-4 text-[#006972] border border-teal-700 rounded shadow mx-auto"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete
        </button>
      </div>
      {isExpanded && (
        <div>
          {isLoading ? (
            <div>Loading...</div> // <-- This is a simple loading message. Replace with a spinner or other loading UI if preferred.
          ) : (
            <ul>
              <li className="bg-gray-300 p-2 font-bold flex justify-between items-center">
                <span style={{ flex: 2 }}>User</span>
                <span style={{ flex: 1 }}>Status</span>
                <span style={{ flex: 1 }}>Expires At</span>
              </li>
              {userInvites.map((user) => {
                // Convert the expiresAt to a Date object
                const expiresAtDate = new Date(user.expiration);
                // Format the date to MM/DD/YYYY
                const formattedExpiresAt = `${
                  expiresAtDate.getMonth() + 1
                }/${expiresAtDate.getDate()}/${expiresAtDate.getFullYear()}`;

                return (
                  <li
                    key={user.email}
                    className="bg-gray-200 p-2 flex justify-between items-center"
                  >
                    <span style={{ flex: 2 }}>{user.email}</span>
                    <span style={{ flex: 1 }}>{user.status}</span>
                    <span style={{ flex: 1 }}>{formattedExpiresAt}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
      {isUserModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-10">
          <div className="modal-content bg-white p-4 rounded shadow-lg w-3/4 max-w-xl">
            <h2>
              Invite User to <span className="font-bold">{org.name}</span>
            </h2>
            <textarea
              placeholder="eg: email1,email2"
              value={userEmails}
              onChange={(e) => setUserEmails(e.target.value)}
              className="border border-black p-2 rounded mt-2 w-full"
            />
            <div className="flex justify-end mt-4">
              <button className="mr-2" onClick={handleUserSubmit}>
                Invite
              </button>
              <button onClick={() => setIsUserModalOpen(false)}>Close</button>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-10">
          <div className="modal-content bg-white p-4 rounded shadow-lg w-3/4 max-w-xl">
            <h2>Are you sure you want to delete {org.name}?</h2>
            <div className="flex justify-end mt-4">
              <button
                className="bg-red-500 hover:bg-red-600 font-semibold py-2 px-4 text-white border border-red-700 rounded shadow mx-auto mr-2"
                onClick={remove}
              >
                Confirm
              </button>
              <button onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default OrganizationItem;
