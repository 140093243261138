import { BackgroundImageConfig, getBackgroundImageStyles } from "../card";
import classNames from "classnames";
import { TypeformButtonLink, ButtonSize } from "../button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IActivity } from "../../../client-server-shared/response-types";
import { shuffle } from "../../controllers/ajax/ActivityController";

export interface CurrentActivityCardProps {
  todaysActivity: IActivity;
  backgroundImage: BackgroundImageConfig;
  linkConfig: {
    id: string;
    hiddenData?: string;
  };
}

export const CurrentActivityCard = ({
  todaysActivity,
  backgroundImage,
  linkConfig,
}: CurrentActivityCardProps) => {
  const { classNames: bgClassNames, styles: bgStyles } = getBackgroundImageStyles({
    ...backgroundImage,
  });
  async function handleShuffle() {
    await shuffle();
  }
  return (
    <div className="w-full min-h-[220px] md:min-h-[390px] pb-4 bg-white rounded-2xl border border-neutral-200 flex-col">
      <TypeformButtonLink typeformLink={linkConfig} usingDefaultStyles={false} className="w-full">
        <div
          className={classNames(
            "w-full min-h-[180px] md:min-h-[350px] rounded-t-2xl",
            bgClassNames
          )}
          style={bgStyles}
        />
      </TypeformButtonLink>
      <div className="pt-2 pb-1 px-3 flex-col justify-start items-start place-content-end flex">
        <div className="w-full flex-col justify-start items-start gap-1 flex">
          <div className="w-full h-9 justify-between items-center gap-3 inline-flex">
            <div className="text-cyan-950 text-base font-bold leading-normal tracking-tight">
              {todaysActivity.name}
            </div>
            <button
              className="w-[44px] h-[44px] flex items-center justify-center"
              onClick={handleShuffle}
            >
              <FontAwesomeIcon
                icon={icon({ name: "shuffle" })}
                // TODO(ben): Set hex-color as constants
                className="text-[#006972]"
              />
            </button>
          </div>
          <div className="self-stretch pr-6 pb-2 justify-start items-center inline-flex">
            <div className="justify-start items-start gap-4 flex">
              <div className="justify-start items-center gap-2 flex pb-3">
                <div className="w-[18px] h-4 px-[3px] py-0.5 justify-center items-center flex">
                  <FontAwesomeIcon icon={icon({ name: "clock" })} className="text-[#006972]" />
                </div>
                <div className="text-teal-900 text-xs font-normal leading-none tracking-tight">
                  {todaysActivity.duration}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TypeformButtonLink
          // TODO(): Use `IActivity` data
          typeformLink={linkConfig}
          size={ButtonSize.Full}
        >
          {todaysActivity.buttonText}
        </TypeformButtonLink>
      </div>
    </div>
  );
};
