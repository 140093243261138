import { ReactNode } from "react";
import Sun from "../../../assets/accessories/sun.svg";
import { ButtonLink } from "../button";

export const ErrorState = ({ message }: { message?: ReactNode }) => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center container">
      <img src={Sun} />
      <p className="font-accent text-xl">Uh oh</p>
      <p>{message || "Something went wrong"}</p>
      <div className="mt-4">
        <ButtonLink to="/">Go back</ButtonLink>
      </div>
    </div>
  );
};
