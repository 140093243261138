import { useEffect, useState, useCallback } from "react";
import { IOrganization } from "../../../client-server-shared/response-types";
import { QueryReturn } from "./util";
import { getOrganizationNames, getOrganizations } from "../ajax/OrganizationController";

export const useGetOrganizationList = (): QueryReturn<IOrganization[]> & {
  refetch: () => void;
} => {
  const [data, setData] = useState<IOrganization[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetchData = useCallback(() => {
    setLoading(true);
    getOrganizations()
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  };
};

/** Query to get the list of organization names registered with Flourish. Called in the onboarding
 * survey to provide a list of org names which users can select from a dropdown menu. */
export const useGetOrganizationNameList = (): QueryReturn<string[]> => {
  const [data, setData] = useState<string[] | undefined>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetchData = useCallback(() => {
    setLoading(true);
    getOrganizationNames()
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    error,
  };
};
