import { ReactNode } from "react";

interface Props {
  acceptText?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
}

export const Dialog = ({ acceptText, onAccept, onCancel, children }: Props) => {
  return (
    <div className="bg-[#3A3A3A]/[.8] fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center">
      <div className="bg-[#FBFDF8] rounded-3xl fixed top-40 p-4 pb-6 flex flex-col text-center">
        <h3 className="font-bold text-[#191C1A] mt-6">Create your well-being practice</h3>
        <div className="mt-4 mb-8">{children}</div>
        <div>
          <button onClick={onCancel} className="w-1/2 py-2 font-bold text-[#B12C1D]">
            Cancel
          </button>
          <button
            onClick={onAccept}
            className="border-l border-[#C1C9BE] w-1/2 py-2 font-bold text-[#006972]"
          >
            {acceptText ?? "Accept"}
          </button>
        </div>
      </div>
    </div>
  );
};
