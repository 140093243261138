import postData from "../helper/postData";
import { POST_PULSE_CHATBOT_INIT, POST_PULSE_CHATBOT_SEND, POST_PULSE_CHATBOT_END } from "../helper/requestConstants";
import { IPulseFinishRequest, IPulseChatbotResponse, IPulseChatbotRequest } from "../../../client-server-shared/response-types";

export async function postPulseChatbotInit(req: IPulseFinishRequest): Promise<IPulseChatbotResponse> {
  const response = await postData(POST_PULSE_CHATBOT_INIT, req);
  return response;
}

export async function postPulseChatbotSend(req: IPulseChatbotRequest): Promise<IPulseChatbotResponse> {
    const response = await postData(POST_PULSE_CHATBOT_SEND, req);
    return response;
}

export async function postPulseChatbotEnd(req: IPulseChatbotRequest): Promise<string> {
    const response = await postData(POST_PULSE_CHATBOT_END, req);
    return response.summary;
}