import postData from "../helper/postData";
import { ADD_CUSTOM_PULSE_KEYWORD_URL, POST_PULSE_FINISH_URL, UPDATE_PULSE_SCIENCE_SNIPPET_SHOWN_URL } from "../helper/requestConstants";
import { IPulseAddCustomKeywordRequest, IPulseFinishRequest, IPulseScienceSnippetShownRequest } from "../../../client-server-shared/response-types";
import patchData from "../helper/patchData";

export async function postDailyMood(req: IPulseFinishRequest): Promise<boolean> {
  const response = await postData(POST_PULSE_FINISH_URL, req);
  return response.success;
}

export async function updateScienceSnippetShown(req: IPulseScienceSnippetShownRequest): Promise<boolean> {
  const response = await patchData(UPDATE_PULSE_SCIENCE_SNIPPET_SHOWN_URL, req);
  return response.success;
}

export async function addCustomPulseKeyword(req: IPulseAddCustomKeywordRequest): Promise<boolean> {
  const response = await patchData(ADD_CUSTOM_PULSE_KEYWORD_URL, req);
  return response.success;
}