import { useState, useEffect, useRef } from "react";
import { SelectablePill } from "../../components/input";
import PhotoIcon from "../../../assets/icons/icon-photo.svg";
import ArrowDown from "../../../assets/icons/icon-chevron-down.svg";
import ArrowRight from "../../../assets/icons/icon-chevron-right.svg";
import ArrowLeft from "../../../assets/icons/icon-arrow-left.svg";
import CloseIcon from "../../../assets/icons/icon-close.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { celebrateCommunityPostPageLink } from "../../util/relative-links";
import { PrivacySelector } from "../../components/community-post/PrivacySelector";
import { CommunityFeedType } from "../../../client-server-shared/enums";
import { ActivityId } from "../../../client-server-shared/enums";
import { ACTIVITY_DATA_OBJECT } from "../../data/week-activities-mock";
import { createPost } from "../../controllers/ajax/CommunityPostController";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dialog } from "../../components/dialog";
import { createCustomActivity } from "../../controllers/ajax/UserController";
import { IUser } from "../../../client-server-shared/response-types";

interface Props {
  user: IUser;
}

export const CommunityPostForm = ({ user }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [privacyLevel, setPrivacyLevel] = useState<CommunityFeedType>(CommunityFeedType.Buddies);
  const [showPrivacySelector, setShowPrivacySelector] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<string>("");
  const [showAllActivitiesPressed, setShowAllActivitiesPressed] = useState<boolean>(false);
  const [hasPreselectedActivity, setHasPreselectedActivity] = useState<boolean>(false);
  const [highlightImagePreview, setHighlightImagePreview] = useState<string>();
  const [highlight, setHighlight] = useState<string>();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [eventDate, setEventDate] = useState<Date>(new Date());
  const [showCustomActivityDialog, setShowCustomActivityDialog] = useState<boolean>(false);
  const [newCustomActivity, setNewCustomActivity] = useState<string>("");
  const [customActivities, setCustomActivities] = useState<string[]>(user.customActivities ?? []);
  const formRef = useRef<HTMLFormElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const privacyButtonRef = useRef(null);
  const today = new Date();

  const NUM_ACTIVITIES_TO_SHOW_ON_MOBILE = 4;

  const updateCanSubmit = () => {
    setCanSubmit(selectedEvent != "" && !isSaving);
  };

  const submit = () => {
    if (!selectedEvent || formRef.current == null) return;
    setIsSaving(true);
    createPost(formRef.current).then((success) => {
      if (success) {
        navigate(celebrateCommunityPostPageLink(), { state: { privacyLevel: privacyLevel } });
      } else {
        console.log("FAILED");
      }
      setIsSaving(false);
      updateCanSubmit();
    });
  };

  function previewImage(event: React.ChangeEvent<HTMLInputElement>) {
    const input = event.currentTarget;
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB limit

    if (input.files) {
      const file = input.files[0];

      if (file.size > MAX_FILE_SIZE) {
        // TODO: Show a graphic for this error message instead
        alert("File is too large! Please select a file less than 5MB.");
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear the input
        }
        return;
      }

      const reader = new FileReader();
      reader.onload = function (e: ProgressEvent) {
        setHighlightImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }

  function removePreviewImage() {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setHighlightImagePreview(undefined);
  }

  function saveCustomActivity() {
    if (newCustomActivity !== "") {
      createCustomActivity(newCustomActivity);
      setCustomActivities([...customActivities, newCustomActivity]);
      setSelectedEvent(newCustomActivity);
    }
    setNewCustomActivity("");
    setShowCustomActivityDialog(false);
  }

  function cancelCustomActivity() {
    setNewCustomActivity("");
    setShowCustomActivityDialog(false);
  }

  // When there's a pre-selected event, select it and hide other options by default.
  useEffect(() => {
    if (!selectedEvent && location.state?.activityId) {
      setSelectedEvent(location.state.activityId);
      setHasPreselectedActivity(true);
    }
  }, [location.state?.activityId]);

  // Pre-fill the highlight field if provided.
  useEffect(() => {
    if (!highlight && location.state?.highlights) {
      setHighlight(
        location.state.highlights.reduce(
          (currentValue: string, item: string) => currentValue + "\n" + item
        )
      );
    }
  }, [location.state?.highlights]);

  useEffect(updateCanSubmit, [isSaving, selectedEvent]);

  return (
    <div className="md:mt-12">
      {/* TODO: Add a `Back to home?` pop-up window. */}
      <button className="flex items-center justify-center pb-6" onClick={() => navigate(-1)}>
        <img src={ArrowLeft} alt="" />
      </button>
      <form ref={formRef} onSubmit={(e) => e.preventDefault()} encType="multipart/form-data">
        <h1
          className="font-bold text-2xl text-teal-400 cursor-pointer mb-4"
          onClick={() => setShowCalendar(!showCalendar)}
        >
          {eventDate.toDateString() === today.toDateString() ? "Today" : eventDate.toDateString()}
          <img src={ArrowDown} className="inline" />
        </h1>
        <input type="hidden" name="eventDate" value={eventDate.toString()} />
        {showCalendar && (
          <Calendar
            value={eventDate}
            maxDate={new Date()}
            onChange={(date) => {
              setEventDate(date as Date);
              setShowCalendar(false);
            }}
          />
        )}
        <div>
          <p className="text-[#004F56] font-bold mb-2">I practiced well-being today through *</p>
          {customActivities.map((activity) => {
            // Since right now cutomized activity is not clickable in Community, we just hide this part for now.
            return hasPreselectedActivity ? null : (
              <SelectablePill
                key={"custom-" + activity}
                label={activity}
                checked={selectedEvent === activity}
                onChange={() => {
                  setSelectedEvent(activity);
                }}
              />
            );
          })}
          {Object.keys(ActivityId).map((id, index) => {
            // If `ACTIVITY_DATA_OBJECT[id]` is undefined, dont render anything
            return !ACTIVITY_DATA_OBJECT[id] ||
              (hasPreselectedActivity &&
                !showAllActivitiesPressed &&
                selectedEvent !== id) ? null : selectedEvent === id ? (
              <SelectablePill
                key={id}
                label={ACTIVITY_DATA_OBJECT[id].title}
                checked={selectedEvent === id}
                onChange={() => {
                  setSelectedEvent(id);
                }}
              />
            ) : (
              <span
                key={id}
                className={
                  index >= NUM_ACTIVITIES_TO_SHOW_ON_MOBILE && !showAllActivitiesPressed
                    ? "hidden md:inline"
                    : ""
                }
              >
                <SelectablePill
                  key={id}
                  label={ACTIVITY_DATA_OBJECT[id].title}
                  checked={selectedEvent === id}
                  onChange={() => {
                    setSelectedEvent(id);
                  }}
                />
              </span>
            );
          })}
          <div
            onClick={() => setShowAllActivitiesPressed(true)}
            className={classNames(
              showAllActivitiesPressed
                ? "hidden"
                : hasPreselectedActivity
                ? "inline"
                : "inline md:hidden",
              "text-sm inline-block rounded-md cursor-pointer select-none py-2 px-4 mr-3 mb-2 border border-[#F0F1ED] font-bold text-[#757874] bg-[#F0F1ED]"
            )}
          >
            all
          </div>
          <div
            onClick={() => setShowCustomActivityDialog(true)}
            className={classNames(
              showAllActivitiesPressed
                ? "inline"
                : hasPreselectedActivity
                ? "hidden"
                : "hidden md:inline",
              "text-sm inline-block rounded-md cursor-pointer select-none py-2 px-4 mr-3 mb-2 border border-dashed border-[#7A757F] font-bold text-[#757874]"
            )}
          >
            <FontAwesomeIcon icon={icon({ name: "plus" })} className="pr-1" /> New
          </div>
          <input type="hidden" name="eventType" value={selectedEvent} />
          <p className="text-[#004F56] font-bold mt-4 mb-2">Highlights</p>
          <div className="flex items-start border border-teal-400 p-2 rounded-md w-full">
            <textarea
              value={highlight}
              onChange={(e) => setHighlight(e.target.value)}
              name="highlight"
              className="grow focus:outline-0"
              rows={4}
            ></textarea>
          </div>
          <div className="mt-2">
            <img src={PhotoIcon} className="inline p-2" />
            {/* File Upload */}
            <input
              ref={fileInputRef}
              type="file"
              name="community_post_image"
              onChange={previewImage}
              accept="image/png, image/jpeg"
            />
            {/* Image Preview */}
            {highlightImagePreview && (
              <div className="relative">
                <button
                  className="absolute top-3 right-3 w-10 h-10 flex items-center justify-center bg-white rounded-full drop-shadow"
                  onClick={removePreviewImage}
                >
                  <img src={CloseIcon} alt="" className="w-3" />
                </button>
                <img src={highlightImagePreview} />
              </div>
            )}
          </div>
        </div>

        {/* Privacy Selector */}
        {showPrivacySelector && (
          <PrivacySelector
            buttonRef={privacyButtonRef}
            onChange={(value) => {
              setPrivacyLevel(value);
              setShowPrivacySelector(false);
            }}
            onClose={() => setShowPrivacySelector(false)}
            value={privacyLevel}
            org={user.organization?.name}
          />
        )}

        <div className="fixed bottom-0 left-3 right-3 bg-white md:static md:mt-16">
          <div className="flex w-full justify-between text-teal-400 cursor-pointer my-2">
            <div>Celebrate with</div>
            <div
              ref={privacyButtonRef}
              onClick={() => {
                setShowPrivacySelector(!showPrivacySelector);
              }}
            >
              {privacyLevel}
              <img src={ArrowRight} className="inline ml-1" />
            </div>
            <input type="hidden" name="privacyLevel" value={privacyLevel} />
          </div>
          <button
            disabled={!canSubmit}
            onClick={submit}
            className="bg-teal-400 disabled:bg-gray-300 w-full py-2 rounded-full text-white font-bold my-4"
          >
            Post!
          </button>
        </div>
      </form>
      {showCustomActivityDialog && (
        <Dialog acceptText="Save" onAccept={saveCustomActivity} onCancel={cancelCustomActivity}>
          <input
            type="text"
            value={newCustomActivity}
            onChange={(e) => setNewCustomActivity(e.target.value)}
            className="border border-[#006972] rounded-md px-2 py-2 bg-transparent w-full"
          />
        </Dialog>
      )}
    </div>
  );
};
