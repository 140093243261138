import { useState, useEffect } from "react";
import { DailyMoodScale, DailyMoodKeywords, DailyMoodHighlight, DailyMoodQuotePage } from "./DailyMoodComponents";
import { PulseScale } from "../../../client-server-shared/enums";
import { IPulseFinishRequest } from "../../../client-server-shared/response-types";
import { postDailyMood } from "../../controllers/ajax/PulseController";
import { DailyMoodChatbot } from "./DailyMoodChatBot";

/**
 * This is the main component for the Daily Mood popup flow. It has pages set in
 * an array of sub-page components (first the scale popup, then the keyword bank).
 */
interface DailyMoodPopupProps {
    initialPulseScale?: PulseScale, 
    initialPage?: number
}
export const DailyMoodPopup = ({ initialPulseScale, initialPage }: DailyMoodPopupProps) => {
    const [requestState, setRequestState] = useState<IPulseFinishRequest>({
        pulseScale: initialPulseScale === undefined ? PulseScale.UNKNOWN : initialPulseScale,
        currentEmotions: [],
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [page, setPage] = useState(initialPage || 0);
    // This `useEffect` disables the scroll bar of the body of the page and will
    // re-enable it once this component unmounts
    useEffect(() => {
        // Disable scrolling on the body when the component mounts
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    // This function calls the `/api/pulse/finish` endpoint to submit the Daily Mood
    // This is used as a premature submission when a user clicks on "X" on a page.
    const onCancel = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            await postDailyMood(requestState);
            window.location.href = "/activity";
        } catch (error) {
            console.error("Failed to post daily mood:", error);
        }
    }
    // Function to update `pulseScale` in `requestState`. This is used in `DailyMoodScale`
    const handleMoodSelected = (selectedMood: PulseScale) => {
        setRequestState(prevState => ({
            ...prevState,
            pulseScale: selectedMood
        }));
        navigateNext();
    };
    const handleKeywordsSelected = (selectedEmotions: string[] = []) => {
        setRequestState(prevState => {
            const newState = {
                ...prevState,
                currentEmotions: selectedEmotions,
            };
            return newState;
        });
    }
    // This sets the `reasonText` in the request body and handles the final submission.
    const onSubmit = async (highlightText: string) => {
        setIsSubmitting(true);
        const requestStateCopy = {
            ...requestState,
            reasonText: highlightText,
        }
        setRequestState(requestStateCopy);
        try {
            await postDailyMood(requestStateCopy);
        } catch (error) {
            console.error("Failed to post daily mood:", error);
            setIsSubmitting(false);
        }
    };
    // Increments the page number when called
    const navigateNext = () => {
        if (page === pages.length - 1) {
            return;
        } else {
          setPage(page + 1);
        }
    }
    const pages = [
        {
            // 1-5 Scale Popup
            content: <DailyMoodScale onMoodSelect={handleMoodSelected} />
        },
        {
            // Emotional keyword bank
            content: <DailyMoodKeywords 
                handleNextButtonClick={navigateNext} 
                moodScale={requestState.pulseScale}
                onCancel={onCancel}
                onKeywordsSelect={handleKeywordsSelected}
                isSubmitting={isSubmitting}
            />
        },
        {
            // Written highlight
            content: <DailyMoodHighlight
                handleNextButtonClick={navigateNext} 
                requestState={requestState}
                onCancel={onCancel}
                onHighlightSet={onSubmit}
                isSubmitting={isSubmitting}
            />
        },
        {
            // Quote page
            content: <DailyMoodQuotePage 
                moodScale={requestState.pulseScale}
                handleNextButtonClick={navigateNext}
            />
        },
        {
            // Chatbot page
            content: <DailyMoodChatbot pulseBody={requestState} />
        }
    ]
    return (<>{pages[page].content}</>);
}