import Sun from "../../../assets/accessories/sun.svg";
import { DotLottiePlayer } from "@dotlottie/react-player";

export const LoadingState: React.FC<Record<string, never>> = () => {
  return (
    // TODO: Margin-top is hardcoded for now
    <div className="mt-[-96px] h-screen flex flex-col items-center justify-center">
      <img src={Sun} className="animate-spin-slow" />
      <p className="font-accent">loading...</p>
    </div>
  );
};

export const LoadingSpinner = () => {
  return (
    <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center">
      <DotLottiePlayer src="animations/Loading.lottie" autoplay loop className="" />
    </div>
  );
};
