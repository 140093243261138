import { IInsightsPageResponse } from "../../../client-server-shared/response-types";
import getData from "../helper/getData";
import { GET_INSIGHTS_PAGE_URL } from "../helper/requestConstants";

/**
 * This function makes the API call to the `/pages/activity` endpoint. It will receive
 * data for the Share Card and the user's last submission Date for Flourishing Scale.
 */
async function getInsightsPage(): Promise<IInsightsPageResponse> {
  const [getInsightsPageResponse] = await Promise.all([getData(GET_INSIGHTS_PAGE_URL)]);
  return {
    futureLetterData: getInsightsPageResponse.futureLetterData,
  };
}

export default getInsightsPage;
