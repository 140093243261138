import { ProfileCard } from "../../components/profile-card/ProfileCard";
import { IUser } from "../../../client-server-shared/response-types";

interface Props {
  user: IUser;
}

// This is the profile page designed to be used in the mobile version app.
// For laptop version, we use the ProfileCard component directly.
export const Me = ({ user }: Props) => {
  return (
    // A simplified version with only a profile card. We could add more user info in the future.
    <ProfileCard user={user} />
  );
};
