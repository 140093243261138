import { CommunityFeedType } from "../../../client-server-shared/enums";
import { ICommunityPostData } from "../../../client-server-shared/response-types";
import { GET_COMMUNITY_FEED_URL } from "../helper/requestConstants";
import getData from "../helper/getData";

const POSTS_PER_PAGE = 20;

export async function getFeed(
  feedType: CommunityFeedType,
  page = 1,
  limit = POSTS_PER_PAGE
): Promise<ICommunityPostData[]> {
  const response = await getData(
    GET_COMMUNITY_FEED_URL + "?feedType=" + feedType + "&page=" + page + "&limit=" + limit
  );
  return response.posts;
}
