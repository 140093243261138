import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGetBuddyList } from "../../controllers/hooks/GetBuddyListHook";
import { BuddyListItem } from "./BuddyListItem";
import AddBuddyIcon from "../../../assets/icons/icon-add-buddy.svg";
import ArrowLeftIcon from "../../../assets/icons/icon-arrow-left.svg";
import { remove } from "../../controllers/ajax/BuddiesController";
import { communityPageLink, buddyAddLink } from "../../util/relative-links";
import { IBuddyData } from "../../../client-server-shared/response-types";
import {
  INVITE_BUDDY_BUTTON_TEXT,
  INVITE_BUDDY_CLIPBOARD_TEXT,
  INVITE_BUDDY_LINK_COPY_SUCCESS,
} from "../../data/strings";
import { EmptyBuddiesList } from "./EmptyBuddiesList";
import { PopDownMenu } from "../../components/pop-down-menu";
import { ClickToCopyButton } from "../../components/click-to-copy";
import { ButtonSize } from "../../components/button";
import { LoadingSpinner } from "../../components/loading";

interface Props {
  userId: string;
}

// Page allowing users to view their list of Buddies.
export const Buddies = ({ userId }: Props) => {
  const navigate = useNavigate();
  const { data: initialData, loading, error } = useGetBuddyList();
  const [buddyData, setBuddyData] = useState<IBuddyData[] | undefined>(undefined);
  const [showAddBuddyMenu, setShowAddBuddyMenu] = useState<boolean>(false);
  const buddyMenuButtonRef = useRef(null);
  const textToCopy = `${INVITE_BUDDY_CLIPBOARD_TEXT}${buddyAddLink()}${userId}`;

  // buddyData set from server initially, manages its local state when changed through UI.
  useEffect(() => {
    setBuddyData(initialData);
  }, [initialData]);

  /**
   * This function handles removing a buddy which is called from a `BuddyListItem` component.
   * The `BuddyListItem` component will provide a corresponding `buddyId` to pass in.
   */
  async function onRemove(buddyId: string) {
    const success = await remove(buddyId);
    if (success) {
      // Update front-end data to match.
      const updatedBuddyData = buddyData?.filter((item) => item._id !== buddyId);
      setBuddyData(updatedBuddyData);
    } else {
      // TODO: Show an error indicator
      console.log("Failed to delete buddy record.");
    }
  }
  return (
    <div className="md:mt-12">
      <div className="fixed top-0 left-0 right-0 md:static md:top-12 md:left-auto py-5 flex justify-end items-center bg-white z-30 md:py-0">
        <div className="w-full relative flex justify-between items-center">
          {/* Back button */}
          <button
            className="flex items-center justify-center pl-4 md:pl-0"
            onClick={() => navigate(communityPageLink())}
          >
            <img src={ArrowLeftIcon} alt="" />
          </button>
          {/* Landscape Add Buddy Button */}
          <button
            onClick={(event) => {
              event.stopPropagation();
              setShowAddBuddyMenu(!showAddBuddyMenu);
            }}
            ref={buddyMenuButtonRef}
            className="hidden md:flex items-center border-solid	border border-teal-700 rounded-3xl w-34 justify-between pl-1 pr-4"
          >
            <img src={AddBuddyIcon} className="w-[40px]" /><span className="text-teal-700 text-sm">Add Buddy</span>
          </button>
          {/* Mobile Add Buddy Button */}
          <button
            onClick={() => setShowAddBuddyMenu(!showAddBuddyMenu)}
            className="md:hidden"
          >
            <img
              src={AddBuddyIcon}
              className="inline mr-6 cursor-pointer"
              ref={buddyMenuButtonRef}
            />
          </button>
          {/* Invite Buddy Popup */}
          {showAddBuddyMenu && (
            <PopDownMenu
              positionRight={true}
              onClose={() => setShowAddBuddyMenu(false)}
              buttonRef={buddyMenuButtonRef}
            >
              <ClickToCopyButton
                buttonText={INVITE_BUDDY_BUTTON_TEXT}
                textToCopy={textToCopy}
                copiedSuccessMessage={INVITE_BUDDY_LINK_COPY_SUCCESS}
                size={ButtonSize.Full}
              />
            </PopDownMenu>
          )}
        </div>
      </div>
      <h1 className="font-bold text-2xl mt-6 mb-4 pt-6 pb-4 text-[#004F56] md:pt-0">Buddies</h1>
      {loading ? (
        <LoadingSpinner />
      ) : buddyData && buddyData.length > 0 ? (
        buddyData.map((item) => {
          return <BuddyListItem key={item._id} buddy={item} onRemove={onRemove} />;
        })
      ) : (
        <EmptyBuddiesList userId={userId} />
      )}
    </div>
  );
};
