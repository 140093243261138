import { IActivityPageResponse } from "../../../client-server-shared/response-types";
import getData from "../helper/getData";
import { GET_ACTIVITY_PAGE_URL } from "../helper/requestConstants";

/**
 * This function makes the API call to the `/pages/activity` endpoint. It will receive
 * data for the Share Card and the user's last submission Date for Flourishing Scale.
 */
async function getActivityPageData(): Promise<IActivityPageResponse> {
  const [getActivityPageDataResponse] = await Promise.all([getData(GET_ACTIVITY_PAGE_URL)]);

  return {
    shareCardData: getActivityPageDataResponse.shareCardData,
    showProfileUpdated: getActivityPageDataResponse.showProfileUpdated,
    todaysPulseResults: getActivityPageDataResponse.todaysPulseResults,
  };
}

export default getActivityPageData;
