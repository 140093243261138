import { useState, useEffect } from "react";
import { Header } from "../../components/header";
import { Card, CardColor } from "../../components/card";
import { ShareCard } from "../../components/share-card/ShareCard";
import { PulseSection } from "../../components/pulse-card/PulseCard";
import { IDayRecord } from "../../../client-server-shared/response-types";
import { getDailyRecordPage } from "../../controllers/ajax/GetDailyRecordPageController";
import { format } from "date-fns";
import ArrowImage from "../../../assets/accessories/arrow.svg";

export const DailyRecord = () => {
    // Pagination
    const [page, setPage] = useState(1); // Keep track of current page
    const [dailyRecordState, setDailyRecordState] = useState<IDayRecord[]>([]);
    const [hasMore, setHasMore] = useState(true); // Flag for more posts availability
    const [isLoading, setIsLoading] = useState(false); // Track loading state

    // This will set the `communityPosts` state with the posts received from the API
    useEffect(() => {
        const fetchInitialData = async () => {
            // This function will fetch the first page of results and set the state
            const initialData = await getDailyRecordPage(1);
            setDailyRecordState(initialData);
        };

        fetchInitialData();
    }, []);

    // This will call the `getFeed` endpoint with a given page number, and set the `dailyRecordState`
    // with the response.
    const fetchMorePosts = async (currentPage: number) => {
        if (!hasMore || isLoading) return;
        setIsLoading(true);

        const newPosts = await getDailyRecordPage(currentPage);
        if (newPosts && newPosts.length > 0) {
            setDailyRecordState((prevPosts) => [...prevPosts, ...newPosts]);
        } else {
            setHasMore(false);
        }

        setIsLoading(false);
    };

    // This useEffect will be responsible for detecting the window's height and scroll position, and will
    // increment the `page` state if the scroll hits the bottom of the page. Then, it will call `fetchMorePosts`.
    useEffect(() => {
        const handleScroll = () => {
            const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
            if (bottom && hasMore) {
                setPage((prevPage) => {
                    const newPage = prevPage + 1;
                    fetchMorePosts(newPage);
                    return newPage;
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore]);

    return (
        <>
            <Header text="Daily Record" icon={ArrowImage} />
            {(!dailyRecordState || dailyRecordState.length === 0) ? (
                <div className="font-base"> Waiting for your first activity record. </div>
            ) : (
                dailyRecordState.map((dateEntry, index) => (
                    <div key={index} className="border-b-2 last:border-b-0 pt-3 pb-5">
                        <h3 className="text-base font-semibold text-teal-700">
                            {format(new Date(dateEntry.submittedAt), "M/d")}
                        </h3>
                        {dateEntry.pulseResults && dateEntry.pulseResults.length > 0 && (
                            <>
                                <section className="pb-4 flex flex-col pt-3 gap-2">
                                    <p className="text-xs font-medium text-teal-500">FLOURISH PULSE</p>
                                </section>
                                <Card color={CardColor.Teal}>
                                    <div className="p-4 flex flex-col">
                                        <div className="divide-y divide-teal-100">
                                            {dateEntry.pulseResults.map((pulseRecord, pulseIndex) => (
                                                <div key={pulseIndex} className="pt-2 pb-4">
                                                    <PulseSection pulseRecord={pulseRecord} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Card>
                            </>
                        )}
                        {dateEntry.activities && dateEntry.activities.length > 0 && (
                            dateEntry.activities.map((activity, activityIndex) => (
                                <section key={activityIndex} className="pb-0 flex flex-col pt-4 gap-2">
                                    <p className="text-xs font-medium text-teal-500">Activity - {activity.title}</p>
                                    <ShareCard shareCardData={activity} insights={true} />
                                </section>
                            ))
                        )}
                    </div>
                ))
            )}
        </>
    )
}