import React, { useState, useRef } from "react";
import EmojiPicker from "emoji-picker-react";
import { ICommunityPostData } from "../../../client-server-shared/response-types";
import { toggleReaction, updatePost } from "../../controllers/ajax/CommunityPostController";
import EmojiPickerImage from "../../../assets/icons/face-smile-plus.svg";
import { GET_COMMUNITY_POST_IMAGE_URL } from "../../controllers/helper/requestConstants";
import { ACTIVITY_DATA_OBJECT } from "../../data/week-activities-mock";
import { useNavigate } from "react-router-dom";
import { postToCommunityPageLink } from "../../util/relative-links";
import MoreIcon from "../../../assets/icons/icon-more.svg";
import { PrivacySelector } from "./PrivacySelector";
import { ReportAndBlockSelector } from "./ReportAndBlockSelector";
import AsyncImage from "../async-image/AsyncImage";

interface Props {
  post: ICommunityPostData;
  userId: string;
  onRemove: (postId: string) => void;
  onUpdate: (post: ICommunityPostData) => void;
  org?: string;
}

export const CommunityPost = ({ post: initalPost, userId, onRemove, onUpdate, org }: Props) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [post, setPost] = useState<ICommunityPostData>(initalPost);
  const navigate = useNavigate();
  const privacyButtonRef = useRef(null);
  const avatar = post.user.avatar ?? "default.svg";
  const toggleEmoji = (emoji: string) => {
    setShowEmojiPicker(false);
    toggleReaction(post._id, emoji).then((updatedPost) => {
      if (updatedPost) {
        setPost(updatedPost);
      } else {
        console.log("FAILED");
      }
    });
  };
  /**
   * This helper will apply line breaks to render whenever a `\n` character is detected.
   * The purpose of this helper is to provide necessary line breaks to a community post.
   */
  const renderWithLineBreaks = (text: string) => {
    return text.split("\n").map((str, index, array) => (
      <React.Fragment key={index}>
        {str}
        {index === array.length - 1 ? null : <br />}
      </React.Fragment>
    ));
  };
  return (
    <div className="mb-8">
      <div className="mb-2 bg-white rounded-xl">
        <div className="flex p-4">
          <div className="mr-2">
            <img src={"avatars/" + avatar} width="36" />
          </div>
          <div className="flex-grow text-sm">
            <span className="text-teal-400 font-bold">{post.user.displayName}</span> completed
            {post.eventType in ACTIVITY_DATA_OBJECT ? (
              <>
                &nbsp;
                <a
                  onClick={() =>
                    navigate(postToCommunityPageLink(), { state: { activityId: post.eventType } })
                  }
                  className="font-bold underline hover:text-teal-400 cursor-pointer"
                >
                  {ACTIVITY_DATA_OBJECT[post.eventType].title}
                </a>
              </>
            ) : (
              <>
                &nbsp;<span className="font-bold">{post.eventType}</span>
              </>
            )}
            <br />
            <span className="text-xs text-gray-400">
              {new Date(post.eventDate).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </span>
          </div>
          <div>

            <img
              onClick={() => setShowMoreMenu(!showMoreMenu)}
              src={MoreIcon}
              className="cursor-pointer"
              ref={privacyButtonRef}
            />
            {showMoreMenu && (
              post.isOwned ? 
                <PrivacySelector
                  buttonRef={privacyButtonRef}
                  org={org}
                  onChange={(privacyLevel) => {
                    updatePost(post._id, privacyLevel);
                    const updatedPost = post;
                    updatedPost.privacyLevel = privacyLevel;
                    onUpdate(updatedPost);
                    setShowMoreMenu(false);
                  }}
                  onRemove={() => {
                    onRemove(post._id);
                  }}
                  onClose={() => setShowMoreMenu(false)}
                  value={post.privacyLevel}
                />
              :
                <ReportAndBlockSelector 
                  onClose={() => setShowMoreMenu(false)}
                  buttonRef={privacyButtonRef}
                />
            )}
          </div>
        </div>
        {post.highlight && (
          <p className="pt-2 pb-4 px-4 text-sm">{renderWithLineBreaks(post.highlight)}</p>
        )}
        {post.image && (
          <AsyncImage
            src={GET_COMMUNITY_POST_IMAGE_URL + `/${post.image}`}
            referrerPolicy="no-referrer"
            className="rounded-b-xl"
          />
        )}
      </div>
      <div className="align-middle flex flex-wrap gap-2">
        {Object.entries(post.reactions ?? []).map(([emoji, users]) => {
          if (users.length == 0) return;
          return (
            // Each emoji entry
            <div
              key={emoji}
              onClick={() => toggleEmoji(emoji)}
              className="min-w-[54px] bg-white px-4 py-2 border border-gray-100 rounded-full cursor-pointer hover:border-teal-500"
            >
              {emoji}{" "}
              <span
                className={users?.includes(userId) ? "font-bold text-teal-500" : "text-gray-400"}
              >
                {users.length}
              </span>
            </div>
          );
        })}
        {/* Add emoji button */}
        <div
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          className="align-center bg-white mr-[10px] px-4 py-3 w-[54px] border border-gray-100 rounded-full cursor-pointer hover:border-teal-500"
        >
          <img src={EmojiPickerImage} />
        </div>
      </div>
      <div>
        {showEmojiPicker && (
          <EmojiPicker onEmojiClick={(e) => toggleEmoji(e.emoji)} autoFocusSearch={false} />
        )}
      </div>
    </div>
  );
};
