import { OrganizationAdmin } from "../organizations/OrganizationAdmin";
import { MigrateOODUser } from "./MigrateOODUser";
import { ActivityCount } from "./ActivityCount";

export const AdminPage: React.FC = () => {
  return (
    <div className="flex flex-col gap-10 px-10">
      <OrganizationAdmin/>
      <ActivityCount/>
      <MigrateOODUser/>
    </div>
  );
};
