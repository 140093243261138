import { Card } from "../card";
import { IShareCard } from "../../../client-server-shared/response-types";
import { ACTIVITY_IMAGE_LOOKUP } from "../../data/week-activities-mock";
import { format } from "date-fns";
import Swirl2 from "../../../assets/accessories/swirl2.svg";

interface Props {
  shareCardData: IShareCard;
}

export const SaveCard = ({ shareCardData }: Props) => {
  const { title, image, highlightedText, submittedAt } = shareCardData;
  return (
    <Card>
      <div className="text-white bg-teal-400  pt-6 pb-9 flex justify-center min-h-[250px] rounded-3xl">
        <div className="relative sm:min-w-[350px] max-w-[400px] w-full px-4 space-y-3 flex flex-col">
          <header className="min-h-[60px] flex justify-between gap-x-3">
            <div className="flex flex-col justify-center min-w-[200px]">
              <p>Flourish Daily</p>
              <h2 className="font-bold text-lg text-white">{title}</h2>
            </div>
            <img
              className="h-[60px] w-[60px] object-cover rounded-xl"
              src={ACTIVITY_IMAGE_LOOKUP[image]}
              alt=""
            />
          </header>
          <ul className="list-disc list-inside font-semibold space-y-2">
            {highlightedText?.map((highlight, index) => {
              return <p key={index}>{highlight}</p>;
            })}
          </ul>

          <div className="flex justify-end pt-1">
            <p>{format(new Date(submittedAt), "MM/dd/yyyy")}</p>
          </div>
          <div className="absolute bottom-[-36px] right-0 overflow-auto">
            <img src={Swirl2} alt="" />
          </div>
        </div>
      </div>
    </Card>
  );
};
