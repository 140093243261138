import { isValid, startOfDay } from "date-fns";
import classNames from "classnames";
import CrownFilled from "../../../assets/icons/icon-crown-filled.svg";
import CrownUnfilled from "../../../assets/icons/icon-crown-unfilled.svg";
import { useRef, useEffect } from "react";

/*
 * Day cards are all cards on the Counter including bullets.
 * This helper returns the number of total day cards on the Journey Counter based
 * off the requested behavior. By default, 20 day cards will appear on the grid to
 * properly extend the grid across landscape screens.
 *
 * The cutoff value for this behavior is if the `highlightedDay` is less than 14.
 * After this cutoff, the total day cards on the grid will be `highlightedDay` + 7 which
 * represents 3 future dates showing, followed by 3 blank circles.
 */
function getNumDayCards(highlightedDay: number) {
  const INITIAL_TOTAL_DAYCARDS = 21;
  const INITIAL_DAYCARD_CUTOFF = 15;
  const DAYCARDS_AFTER_CURRENT_DAY = 7;
  if (highlightedDay < INITIAL_DAYCARD_CUTOFF) return INITIAL_TOTAL_DAYCARDS;
  else return highlightedDay + DAYCARDS_AFTER_CURRENT_DAY;
}

/*
 * Days showing are cards on the Counter that show a number.
 * This helper returns the number of dates showing on the Journey Counter based
 * off the requested behavior. By default, 7 dates will appear on the grid.
 *
 * The cutoff value for this behavior is if the `highlightedDay` is less than 4.
 * After this cutoff, the total dates showing on the grid will be `highlightedDay` + 4 which
 * represents 3 future dates showing.
 */
function getDaysShowing(highlightedDay: number) {
  const INITIAL_DAYS_SHOWING = 8;
  const INITIAL_DAYS_SHOWING_CUTOFF = 4;
  const DAYS_SHOWING_AFTER_CURRENT_DAY = 4;
  if (highlightedDay < INITIAL_DAYS_SHOWING_CUTOFF) return INITIAL_DAYS_SHOWING;
  else return highlightedDay + DAYS_SHOWING_AFTER_CURRENT_DAY;
}

interface Props {
  daysActive: number;
  lastActive: Date;
}

export const JourneyCounter = ({ daysActive, lastActive }: Props) => {
  let highlightedDay = daysActive;
  let todaysActivityFinished = false;
  const now = new Date();

  // If the user's last activity was done a date before today, increment `highlightedDay`
  if (!isValid(lastActive) || lastActive < startOfDay(now)) {
    highlightedDay++;
  }
  // Otherwise, today's activity has been finished.
  else {
    todaysActivityFinished = true;
  }

  // This variable is the number of Day Cards showing on the Counter
  const numDayCards = getNumDayCards(highlightedDay);

  // This variable is the number of Day Cards showing the day number
  const daysShowing = getDaysShowing(highlightedDay);

  // This ref is added to the DOM element representing the current day, so that we can scroll to
  // the current day by default.
  const currentDayRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (currentDayRef.current) {
      currentDayRef.current.scrollIntoView({ block: "center", inline: "center" });
    }
  });

  // Populate the `dayCards` array
  const dayCards: JSX.Element[] = [];
  for (let index = 1; index < numDayCards; index++) {
    // Teal bg shows for this card if the user completed today's activity
    const showTealBackground = index === highlightedDay && todaysActivityFinished;
    // Dotted border shows if user did not complete today's activity
    let showDottedBorder = index === highlightedDay && !todaysActivityFinished;
    // Day text shows if the current index matches the highlighted day
    let showDayText = index === highlightedDay;
    // Subsequent day cards will be opaque
    let showOpaque = index > highlightedDay;

    // Day 1 exceptions; For 0 active days, we should still show the first Day card
    if (daysActive === 0 && index === 1) {
      showOpaque = false;
      showDottedBorder = true;
      showDayText = true;
    }

    // Crown related conditions
    const CROWN_INDEX = 5;
    const showCrown = index % CROWN_INDEX === 0; // Every 5 days, show a crown
    const filledCrown = index < highlightedDay || showTealBackground;

    // Circle shows for card indexes
    const showCircle = index >= daysShowing;
    // Show number date for indexes less than
    const showDate = index < daysShowing;

    dayCards.push(
      <div
        className={classNames(
          // Special Styles
          showTealBackground && "bg-[#D6F9F9]",
          showDottedBorder && "border-dotted border-[1px] border-teal-400",
          showOpaque && "opacity-40",
          // Normal Styles
          "min-w-[44px] h-full flex flex-col items-center justify-center text-teal-400 rounded-xl"
        )}
        key={index}
        ref={index === highlightedDay ? currentDayRef : undefined}
      >
        {/* Show a crown or 'DAY' label */}
        <div className="h-[15px] w-full flex items-center justify-center">
          {showCrown ? (
            <img src={filledCrown ? CrownFilled : CrownUnfilled} alt="" />
          ) : (
            // Else, label 'DAY'
            showDayText && <p className="text-[10px] font-bold">DAY</p>
          )}
        </div>
        {/* Show circle or number date */}
        {showCircle && (
          <div className="w-[16px] h-[16px] bg-[#004F56] mt-[4px] rounded-full opacity-40" />
        )}
        {showDate && <p className="font-bold">{index}</p>}
      </div>
    );
  }

  return (
    <div className="w-full h-[56px] flex gap-2">
      <ul className="overflow-x-scroll flex gap-2 no-scrollbar">{dayCards}</ul>
    </div>
  );
};
