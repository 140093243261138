import { useState, useRef, useEffect, ReactNode } from "react";
import User from "../../../assets/chatbot-demo/user-icon.svg";
import Assitatant from "../../../assets/chatbot-demo/assistant-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faArrowUp, faComments } from '@fortawesome/pro-regular-svg-icons';
import { Button } from "../../components/button";
import { DotLottiePlayer } from "@dotlottie/react-player";
import classNames from "classnames";
import { IPulseFinishRequest, IPulseChatbotMessage } from "../../../client-server-shared/response-types";
import { postPulseChatbotInit, postPulseChatbotSend, postPulseChatbotEnd } from "../../controllers/ajax/PulseChatbotController";

/**
 * This component is the chatbot for the Pulse feature. This chatbot will appear
 * at the end of the flow when the user clicks on the entry point when having a
 * negative mood. For now, a basic prompt is provided to chat with the user and
 * returns a log of the summary for now.
 */
interface DailyMoodChatbotProps {
  pulseBody: IPulseFinishRequest;
}
export const DailyMoodChatbot = ({ pulseBody }: DailyMoodChatbotProps) => {
  const [isChatbotTyping, setIsChatbotTyping] = useState<boolean>(false);
  const [isEndingSession, setIsEndingSession] = useState<boolean>(false);
  const [userMessage, setUserMessage] = useState<string>("");
  const [messages, setMessages] = useState<IPulseChatbotMessage[]>([]);

  /**
   * This `useEffect` initializes the chatbot by retrieving the user's pulse
   * data and calling the `/api/pulseChatbot/init` endpoint to receive the
   * initial chatbot messages and setting them to the `messages` state.
   */
  useEffect(() => {
    const initializeChat = async () => {
      try {
        // Initialize chat with pulse data
        setIsChatbotTyping(true);
        const chatInitResponse = await postPulseChatbotInit(pulseBody);
        const initialMessages = chatInitResponse.messages;
        setMessages(initialMessages);
        setIsChatbotTyping(false);
      } catch (error) {
        console.error("Error initializing chat:", error);
      }
    };
    initializeChat();
  }, []);

  // Ref of the bottom of the chat
  const bottomOfChatRef = useRef<null | HTMLDivElement>(null);
  // This `useEffect` will keep the window scrolled to the bottom of the chat after each new message.
  useEffect(() => {
    bottomOfChatRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  /**
   * This function handles submitting a message to the chatbot.
   * It will clear the input box's placeholder text and first append
   * the user's message to the `messages` state. Then, it will make a
   * request to the chatbot API to receive a response and append the
   * response to the `messages` state.
   */
  async function submitMessage() {
    if (userMessage === "") return;
    setUserMessage(""); // Clear user's message state // TODO: rename
    // Append user's new message to the messages state
    setMessages((previousMessages) => [
      ...previousMessages,
      {
        message: userMessage,
        sentFromMe: true,
      },
    ]);
    // A local deep copy is made because `setMessages` is async and doesn't
    // update in time.
    const messagesBuf = [
      ...messages,
      {
        message: userMessage,
        sentFromMe: true,
      },
    ];
    setIsChatbotTyping(true);
    const response = await postPulseChatbotSend({messages: messagesBuf});
    setIsChatbotTyping(false);
    const message = response.messages[0].message;
    // Append the chatbot's message to the messages state
    setMessages((previousMessages) => [
      ...previousMessages,
      {
        message: message,
        sentFromMe: false,
      },
    ]);
  }

  /**
   * This function handles closing the chat session by calling the
   * request to end ande summarize the session. Afterwards, the user
   * is hard-redirected to the activity page.
   */
  async function handleClose() {
    try {
      setIsEndingSession(true);
      await postPulseChatbotEnd({messages: messages});
    } catch (error) {
      console.error("Error ending chat session:", error);
    } finally {
      window.location.href = "/activity";
    }
  }
  
  // Handles resizing the input box when there's overflow text
  const resizeTextarea = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const textarea = event.currentTarget;
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`;
  };
  return (
    <div className="w-full h-screen fixed top-0 left-0 z-40 flex items-center justify-center md:bg-black md:bg-opacity-50">
      <div className="w-full h-full flex flex-col justify-between md:max-w-[650px] md:max-h-[736px] md:rounded-3xl md:relative bg-teal-50 overflow-hidden">
        {/* Loading Spinner */}
        {isEndingSession && <EndingSession />}
        {/* Top Navbar */}
        <div className="w-full h-[56px] flex justify-center items-center bg-white fixed top-0 left-0 relative">
          <button
            className="flex items-center justify-center w-[44px] h-[44px] absolute right-1 transform top-1/2 -translate-y-1/2"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faXmark} className="w-[14px] h-[18px]"/>
          </button>
          <div className="flex items-center justify-center gap-2">
            <FontAwesomeIcon icon={faComments} />
            <p className="font-semibold">Dive deeper with Sunnie</p>
            <div className="bg-[#01929E] px-1 py-0 rounded h-4 flex justify-center">
              <span className="text-white text-[11px] font-semibold">Beta</span>
            </div>
          </div>
        </div>
        <div className="overflow-scroll h-full">
          {/* Message Bubbles */}
          <div className="flex flex-col items-start px-4 pt-4 md:pb-20 pb-[200px]">
            {messages.map((message, index) => {
              return (
                <MessageBubble key={index} message={message.message} sentFromMe={message.sentFromMe} />
              );
            })}
            {/* "Sunnie is Typing" animation */}
            {isChatbotTyping &&
              <MessageBubble sentFromMe={false} className="flex items-center justify-center">
                <DotLottiePlayer
                  src="animations/Dots-Jumping.lottie"
                  autoplay
                  loop
                  className="w-[28px]"
                />
              </MessageBubble>
            }
            <div ref={bottomOfChatRef} />
          </div>
        </div>
        {/* Bottom Input Bar */}
        <div className="bg-[#F8FAF5] md:static fixed bottom-0 w-full left-0 min-h-10 pt-3 pb-10 md:pb-3 px-3 flex flex-col">
          <div className="w-full h-full relative flex justify-center">
            <div className="relative max-h-[150px] w-full flex-grow mr-2 flex justify-center items-start relative pr-12">
              <textarea
                className="rounded-xl w-full px-2.5 py-2.5 max-h-[150px] min-h-[44px] text-sm font-medium overflow-auto border border-solid-1"
                value={userMessage}
                onChange={(event) => {
                  setUserMessage(event.target.value);
                  resizeTextarea(event);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !event.shiftKey && !isChatbotTyping) {
                    event.preventDefault(); // Prevent adding a new line
                    submitMessage();
                  }
                }}
                rows={1}
                style={{ resize: 'none' }} // To disable manual resizing
                onInput={resizeTextarea}
              >
              </textarea>
              <Button
                className="pl-4 pr-4 py-3 absolute bottom-0 right-[-7px] z-40 max-h-[44px] w-[44px] max-w-[44px] bg-[#01929E] text-white rounded-xl flex items-center justify-center"
                onClick={() => submitMessage()}
                disabled={isChatbotTyping}
              >
                <FontAwesomeIcon icon={faArrowUp} className="w-[18px] h-[18px]"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * This component is the styling of the message bubble.
 */
interface MessageBubbleProps {
  message?: string;
  sentFromMe: boolean;
  children?: ReactNode; // This is for the lottie animation
  className?: string;
}
const MessageBubble = ({ message, sentFromMe, children, className }: MessageBubbleProps) => {
  // Split the message by newline characters
  const messageLines = message?.split('\n').map((line, index) => (
    // Render each line in a separate div or span
    <div key={index} className="message-line">
      {line}<br/>
    </div>
  ));
  return (
    <div className={classNames(className, sentFromMe ? "self-end mb-3" : "mb-3")}>
      {!sentFromMe && <img src={Assitatant} className="inline-block align-top" width="32" />}
      <p
        className={
          sentFromMe
            ? "bg-white text-teal-400 inline-block rounded-md mr-2 max-w-[80%] md:max-w-md"
            : "bg-[#01929E] text-white inline-block rounded-md ml-1 max-w-[80%] md:max-w-md"
        }
      >
        <div className="p-3 text-sm font-medium">{messageLines}{children}</div>
      </p>
      {sentFromMe && <img src={User} className="inline-block align-top" width="32" />}
    </div>
  );
};

/**
 * This component contains the styling for the loadng spinner for when a user ends
 * the session.
 */
const EndingSession = () => {
  return (
    <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center z-30">
      <DotLottiePlayer src="animations/Loading.lottie" autoplay loop className="" />
    </div>
  );
};