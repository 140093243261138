import { useState } from "react";
import { IUser } from "../../../client-server-shared/response-types";
import { ManageAccount } from "./ManageAccount";
import { DeleteAccount } from "./DeleteAccount";

/**
 * This file is the page selector component for the Manage Account related components and pages.
 * The `page` state is keeping track of which Manage Account page to show, and the `pages` object
 * contains the different pages.
 */
interface ManageAccountPageSelectorProps {
  user: IUser;
}

export const ManageAccountPageSelector = ({ user }: ManageAccountPageSelectorProps) => {
  const [page, setPage] = useState<number>(0);
  // Increments the page number when called
  const navigateNext = () => {
    if (page === pages.length - 1) {
        return;
    } else {
      setPage(page + 1);
    }
  }
  // Decrements the page number when called
  const navigateBack = () => {
    if (page === 0) {
        return;
    } else {
      setPage(page - 1);
    }
}
  const pages = [
    {
      content: <ManageAccount user={user} handleNextButtonClick={navigateNext}/>
    },
    {
      content: <DeleteAccount handleBackButtonClick={navigateBack}/>
    }
  ]

  return (<>{pages[page].content}</>);
}