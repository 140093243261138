import { useEffect, useState } from "react";
import GraySunPlaceholder from "../../../assets/illustrations/gray-sun-placeholder.svg";

interface AsyncImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
}

const AsyncImage: React.FC<AsyncImageProps> = ({ src, ...restProps }) => {
  const [loadedImage, setLoadedImage] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setLoadedImage(src);
    };

    return () => {
      img.onload = null;
    };
  }, [src]);

  if (!loadedImage) {
    return (
      <div className="w-full py-14 flex justify-center items-center">
        <img src={GraySunPlaceholder} className="inline" />
      </div>
    );
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...restProps} src={loadedImage} />;
};

export default AsyncImage;
