import { useState } from "react";
import { Navigate, redirect } from "react-router-dom";
import { ErrorState } from "../../components/error";
import { LoadingState } from "../../components/loading";
import { signoutUser } from "../../controllers/ajax/AuthController";

export const Signout: React.FC<Record<string, never>> = () => {
  const [signingOut, setSigningOut] = useState(true);
  const [signoutError, setSignoutError] = useState("");

  signoutUser()
    .then((result) => {
      if (result) {
        redirect("/");
      } else {
        setSignoutError("An error occured while signing out. Please try again.");
      }
    })
    .catch((e) => {
      console.error(e);
      setSignoutError("Sorry, an error occured while signing out. Please try again later.");
    })
    .finally(() => {
      setSigningOut(false);
    });

  if (signingOut) {
    return <LoadingState />;
  }

  if (signoutError) {
    return <ErrorState message={signoutError} />;
  }

  // Shouldn't reach here, but this is a fail safe
  return <Navigate replace to={"/"} />;
};
