import { urlBase64ToUint8Array } from "./tools";
import {
  GET_VAPID_PUBLIC_KEY_URL,
  SUBSCRIBE_NOTIFICATIONS_URL,
  UNSUBSCRIBE_NOTIFICATIONS_URL,
  SUBSCRIBE_FCM_NOTIFICATIONS_URL,
  UNSUBSCRIBE_FCM_NOTIFICATIONS_URL,
} from "../controllers/helper/requestConstants";
import postData from "../controllers/helper/postData";
import { sendRegistrationTokenToServer } from "../util/firebase-utils";

export function subscribeNotifications() {
  // requestPermission() brings up the prompt for the first time
  // If there is a result, then it wont ask

  // Request user's permission
  Notification.requestPermission().then((result) => {
    // If user allows, create a subscription to the server
    if (result === "granted") {
      // This is a promise that will contain the service worker registration
      navigator.serviceWorker.ready.then(function (registration) {
        return registration.pushManager.getSubscription().then(async function (subscription) {
          // If a subscription is found, return it
          if (subscription) {
            return subscription;
          }
          // Call server to get Public Key
          const [response] = await Promise.all([postData(GET_VAPID_PUBLIC_KEY_URL)]);
          const vapidPublicKey = response.key;
          const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

          // Create a new subscription
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidKey,
            })
            .then(function (subscriptionObject) {
              postData(SUBSCRIBE_NOTIFICATIONS_URL, { subscription: subscriptionObject });
            });
        });
      });
    }
  });
}

// Get existing subscription from service worker, unsubscribe
// (`subscription.unsubscribe()`) and unregister it in the server with
// a POST request to stop sending push messages to
// unexisting endpoint.
export const unsubscribeNotifications = async () => {
  navigator.serviceWorker.ready
    .then(function (registration) {
      // Retrieve the subscription object from the Push API
      return registration.pushManager.getSubscription();
    })
    .then(function (subscription) {
      // Null Check
      if (subscription === null) return;

      return subscription
        .unsubscribe() // Unsubscribe the browser's subscription object w/ the Push API
        .then(function () {
          // Remove the entry from MongoDB
          return postData(UNSUBSCRIBE_NOTIFICATIONS_URL, { subscription: subscription });
        })
        .catch((error) => {
          console.log("Unsubscribing failed: ", error);
        });
    });
};

export function subscribeFCMNotifications() {
  // requestPermission() brings up the prompt for the first time
  // If the permission has already been granted, then it wont ask

  // Request user's permission
  Notification.requestPermission().then((result) => {
    // If user allows, create a subscription to the server
    if (result === "granted") {
      // This is a promise that will contain the service worker registration
      navigator.serviceWorker.ready.then(function () {
        return sendRegistrationTokenToServer(async function (registrationToken: string) {
          await postData(SUBSCRIBE_FCM_NOTIFICATIONS_URL, { registrationToken: registrationToken });
        });
      });
    }
  });
};

export const unsubscribeFCMNotifications = async () => {
  // Only unsubscribe when the user subscribed first
  if (Notification.permission === "granted") {
    await sendRegistrationTokenToServer(async function (registrationToken: string) {
      await postData(UNSUBSCRIBE_FCM_NOTIFICATIONS_URL, { registrationToken: registrationToken });
    });
  }
};
