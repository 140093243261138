async function getData(url = "", params = {}) {
  // Convert the params object into a query string
  const queryString = new URLSearchParams(params).toString();
  // Append the query string to the URL, if it exists
  const finalUrl = queryString ? `${url}?${queryString}` : url;

  // Default options are marked with *
  const response = await fetch(finalUrl, {
    mode: "same-origin", // no-cors, *cors, same-origin
    cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "include", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

// Helper to create GET requests with default request parameters.
export default getData;
