import { Button } from "../../components/button/Button";
import SunnieStretching from "../../../assets/goals/sunnie-stretching.svg";
import SunnieStretchingColor from "../../../assets/goals/sunnie-stretching-color.svg";
import SunnieChasingHeart from "../../../assets/goals/sunnie-chasing-heart.svg";
import SunnieHuggingCloud from "../../../assets/goals/sunnie-hugging-cloud.svg";
import SunnieWithPlant from "../../../assets/goals/sunnie-with-plant.svg";
import SunnieChasingHeartColor from "../../../assets/goals/sunnie-chasing-heart-color.svg";
import SunnieHuggingCloudColor from "../../../assets/goals/sunnie-hugging-cloud-color.svg";
import SunnieWithPlantColor from "../../../assets/goals/sunnie-with-plant-color.svg";
import { useState } from "react";
import { Goal } from "../../../client-server-shared/enums";
import { saveGoals } from "../../controllers/ajax/OnboardingController";

interface GoalsSurveyFormProps {
    handleNextButtonClick: () => void;
}

interface GoalCardProps {
    selected: boolean;
    imageSource: string;
    selectedImageSource: string;
    text: string;
    onClick: () => void;
}

const GoalCard: React.FC<GoalCardProps> = ({ selected, imageSource, selectedImageSource, text, onClick }) => {
    const borderColor = selected ? "border-teal-500": "border-[#D2DDDF]";
    const image = selected ? selectedImageSource : imageSource;
    return (
        <div className={`${borderColor} bg-white bg-no-repeat bg-cover flex flex-col justify-center items-center 
        w-40 h-40 md:w-48 md:h-48 my-1.5 rounded-2xl border-2 border-solid overflow-hidden cursor-pointer`}
        style={{ backgroundImage: `url(${image})` }} onClick={onClick}>
            <div className="flex-col text-teal-600 text-center text-base font-bold leading-5 
            tracking-normal relative overflow-hidden aspect-square w-full justify-center 
            items-stretch pt-8 pb-20 px-6" >
                {text}
            </div>
        </div>)
}

export const GoalsSurveyForm: React.FC<GoalsSurveyFormProps> = ({ handleNextButtonClick }) => {
    // TODO: replace boolean state variables with a Goal array to track selected goals.
    const [isImproveMoodSelected, setIsImproveMoodSelected] = useState(false);
    const [isDeepenRelationshipsSelected, setIsDeepenRelationshipsSelected] = useState(false);
    const [isReduceStressSelected, setIsReduceStressSelected] = useState(false);
    const [isDiscoverMeaningSelected, setIsDiscoverMeaningSelected] = useState(false);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let goals: Goal[] = [];
        if (isImproveMoodSelected) {
            goals.push(Goal.IMPROVE_MOOD);
        }
        if (isDeepenRelationshipsSelected) {
            goals.push(Goal.DEEPEN_RELATIONSHIPS);
        }
        if (isReduceStressSelected) {
            goals.push(Goal.REDUCE_STRESS);
        }
        if (isDiscoverMeaningSelected) {
            goals.push(Goal.DISCOVER_MEANING);
        }
        // TODO: Add error handling message
        await saveGoals(goals);
        handleNextButtonClick();
    }

    return (
        <section className="flex flex-col max-w-[443px] md:w-[443px]">
            <h2 className="text-teal-600 text-base font-bold leading-6 tracking-normal self-start">
                Pick goal(s) that resonate with you:
            </h2>
            <form onSubmit={onSubmit} className="w-full py-8" >
                <div className="flex justify-center gap-3 md:gap-4">
                    <div className="flex-col space-y-3 md:space-y-4">
                        <GoalCard selected={isImproveMoodSelected} imageSource={SunnieStretching} 
                        selectedImageSource={SunnieStretchingColor} text={"Improve Mood"} 
                        onClick={() => setIsImproveMoodSelected(!isImproveMoodSelected)}/>
                        <GoalCard selected={isDeepenRelationshipsSelected} imageSource={SunnieChasingHeart} 
                        selectedImageSource={SunnieChasingHeartColor} text="Deepen Relationships" 
                        onClick={() => setIsDeepenRelationshipsSelected(!isDeepenRelationshipsSelected)} />
                    </div>
                    <div className="items-center flex-col space-y-3 md:space-y-4">
                        <GoalCard selected={isReduceStressSelected} imageSource={SunnieHuggingCloud} 
                        selectedImageSource={SunnieHuggingCloudColor} text={"Reduce Stress"} 
                        onClick={() => setIsReduceStressSelected(!isReduceStressSelected)} />
                        <GoalCard selected={isDiscoverMeaningSelected} imageSource={SunnieWithPlant} 
                        selectedImageSource={SunnieWithPlantColor} text="Discover Meaning" 
                        onClick={() => setIsDiscoverMeaningSelected(!isDiscoverMeaningSelected)}/>
                    </div>
                </div>
                <Button className="w-full mt-10">
                    Continue
                </Button>
            </form>
        </section>
    );
}