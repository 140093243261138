import { ReactNode } from "react";
import { hotjar } from "react-hotjar";
import { ErrorState } from "../error";
import { IUser } from "../../../client-server-shared/response-types";
import { useGetUser } from "../../controllers/hooks/GetUserHook";
import { logUserProperties } from "../../util/firebase-utils";

export const VerifyUser = ({
  loggedInComponent,
  loggedOutComponent,
}: {
  loggedInComponent: (user: IUser) => ReactNode;
  loggedOutComponent?: ReactNode;
}) => {
  const { data: user, loading } = useGetUser();

  if (loading) return <></>;

  // Customized environment variables need to start with `REACT_APP_`.
  // https://create-react-app.dev/docs/adding-custom-environment-variables/
  if (process.env.REACT_APP_ENV === "PROD" || process.env.REACT_APP_ENV === "PREPROD") {
    // https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-guide-to-installing-Pendo
    (window as any).pendo.initialize({
      visitor: {
        // Track visitor unique id, email and name.
        // The last two are used to enrich the visitor profile (so that we could tell if it is from Flourish team).
        id: user?._id,
        email: user?.emailAddress,
        // eslint-disable-next-line camelcase
        full_name: user?.displayName,
      },
      account: {
        id: "flourish-prod", // Use a new account id so that we won't be bothered by the old or dev traffic.
      },
    });

    if (process.env.REACT_APP_USE_FCM === "true" && user !== undefined) {
      logUserProperties(user);
    }
  }

  if (process.env.REACT_APP_ENV === "PROD" || process.env.REACT_APP_ENV === "PREPROD") {
    // hjid: Stands for 'Hotjar ID'. Your site's ID.
    // This is the ID which tells Hotjar which site settings it should load and where it should save the data collected.
    const hjid = 3598792;
    // hjsv: Stands for 'Hotjar Snippet Version'. The version of the Tracking Code you are using.
    // This is only needed if Hotjar ever updates the Tracking Code and needs to discontinue older ones.
    // Knowing which version your site includes allows hotjar team to contact you and inform you accordingly.
    const hjsv = 6;
    hotjar.initialize(hjid, hjsv);
    if (hotjar.initialized()) {
      hotjar.identify(user?._id ?? null, { userProperty: "value" });
    }
  }

  return <>{user ? loggedInComponent(user) : loggedOutComponent || <ErrorState />}</>;
};
