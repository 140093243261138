import { ButtonSize } from "../../components/button";
import { ClickToCopyButton } from "../../components/click-to-copy";
import { buddyAddLink } from "../../util/relative-links";
import { INVITE_BUDDY_LINK_COPY_SUCCESS, INVITE_BUDDY_CLIPBOARD_TEXT } from "../../data/strings";

interface Props {
  userId: string;
}

// View to show on Buddies page when user doesn't have any Flourish buddies.
export const EmptyBuddiesList = ({ userId }: Props) => {
  const textToCopy = `${INVITE_BUDDY_CLIPBOARD_TEXT}${buddyAddLink()}${userId}`;
  return (
    <div className="text-center pt-8">
      <p>🌱🌱🌱</p>
      <p className="mb-4">Your Flourish Buddy list is empty for now.</p>
      <p className="mb-16">
        Research shows that sharing experiences with others can bring more fun, motivation, and
        deepen your connection with them.
      </p>
      <ClickToCopyButton
        buttonText="Invite Buddy"
        textToCopy={textToCopy}
        copiedSuccessMessage={INVITE_BUDDY_LINK_COPY_SUCCESS}
        size={ButtonSize.Full}
      />
    </div>
  );
};
