import classNames from "classnames";
import { useState } from "react";
import CheckIcon from "../../../assets/icons/icon-check.svg";
import { Button, ButtonProps } from "../button";

interface Props extends Pick<ButtonProps, "color" | "size"> {
  buttonText: string;
  textToCopy: string;
  copiedSuccessMessage?: string;
}

export const ClickToCopyButton = ({
  buttonText,
  textToCopy,
  copiedSuccessMessage,
  ...buttonProps
}: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (!copied) {
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    }
  };

  return (
    <>
      <Button {...buttonProps} onClick={handleCopy} disabled={copied}>
        {buttonText}
      </Button>
      <span
        className={classNames("flex font-medium text-sm mt-1 gap-x-2", { "text-teal-500": copied })}
      >
        {copied && <img src={CheckIcon} />}
        {copied && (copiedSuccessMessage || "Copied to clipboard")}
      </span>
    </>
  );
};
