import { ALL_ACTIVITY_DATA } from "../../data/week-activities-mock";
import { ActivityCard } from "../activity-card";
import { useTypeform } from "../../controllers/hooks";

interface Props {
  hiddenData: string; // TODO how should this be passed
  userId: string;
}

export const ActivityGrid = ({ hiddenData, userId }: Props) => {
  useTypeform();
  return (
    // TODO: Implement a masonry grid
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
      {ALL_ACTIVITY_DATA.map((activity, index) => {
        const formId = activity.typeformFormId;
        return (
          <ActivityCard
            key={index}
            color={activity.color}
            backgroundImage={{
              data: activity.backgroundImage.smallData,
              position: activity.backgroundImage.position,
            }}
            title={activity.title}
            linkConfig={{
              id: formId,
              hiddenData: hiddenData,
            }}
            trialLink={activity.trialLink + "#id=" + userId + ""}
            // TODO: dont' hardcode this for the one week checkin. Maybe it shouldn't be part of the week-data?
          />
        );
      })}
    </div>
  );
};
