import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  activityPageLink,
  insightsPageLink,
  libraryPageLink,
  communityPageLink,
} from "../../util/relative-links";
import { ENABLE_LINKS_TO_COMMUNITY, PageRoute } from "../../util/constants";
import ActivityIcon from "../../../assets/navigation/activity-icon.svg";
import SelectedActivityIcon from "../../../assets/navigation/selected-activity-icon.svg";
import LibraryIcon from "../../../assets/navigation/library-icon.svg";
import SelectedLibraryIcon from "../../../assets/navigation/selected-library-icon.svg";
import InsightIcon from "../../../assets/navigation/insight-icon.svg";
import SelectedInsightIcon from "../../../assets/navigation/selected-insight-icon.svg";
import CommunityIcon from "../../../assets/navigation/community-icon.svg";
import SelectedCommunityIcon from "../../../assets/navigation/selected-community-icon.svg";

interface Props {
  selected: string;
}

// Bottom navigation bar designed for mobile app.
export const BottomNavBar = ({ selected }: Props) => {
  const navigate = useNavigate();
  const getSelectedIconLabel = () => {
    return classNames("text-xs font-semibold text-[#191C1A]");
  };

  return (
    <>
      {/* Hide botton navigation bar for secondary pages. */}
      {selected !== PageRoute.COMMUNITY_POST &&
        selected !== PageRoute.CELEBRATE_COMMUNITY_POST &&
        selected !== PageRoute.BUDDIES && (
          <div className="w-full z-30 fixed bottom-0">
            {/* Bottom Navigation */}
            <nav className="md:hidden bg-[#F6F7F6] h-[94px]">
              <div
                className={
                  "grid w-full px-2 place-items-center h-[94px] " +
                  (ENABLE_LINKS_TO_COMMUNITY ? "grid-cols-4" : "grid-cols-3")
                }
              >
                <button
                  onClick={() => {
                    navigate(activityPageLink());
                    window.scrollTo({ top: 0 });
                  }}
                  disabled={selected === PageRoute.ACTIVITY && true}
                  className=""
                >
                  {/* Temporary solution to avoid icon img loading latency. */}
                  <img
                    src={SelectedActivityIcon}
                    className={selected === PageRoute.ACTIVITY ? "" : "hidden"}
                  />
                  <img
                    src={ActivityIcon}
                    className={selected === PageRoute.ACTIVITY ? "hidden" : ""}
                  />
                  <div
                    className={selected === PageRoute.ACTIVITY ? getSelectedIconLabel() : "hidden"}
                  >
                    Today
                  </div>
                </button>

                {ENABLE_LINKS_TO_COMMUNITY && (
                  <button
                    onClick={() => {
                      navigate(communityPageLink());
                      window.scrollTo({ top: 0 });
                    }}
                    disabled={selected === PageRoute.COMMUNITY && true}
                  >
                    {/* Temporary solution to avoid icon img loading latency. */}
                    <img
                      src={SelectedCommunityIcon}
                      className={selected === PageRoute.COMMUNITY ? "mx-auto my-[10px]" : "hidden"}
                    />
                    <img
                      src={CommunityIcon}
                      className={selected === PageRoute.COMMUNITY ? "hidden" : ""}
                    />
                    <div
                      className={
                        selected === PageRoute.COMMUNITY ? getSelectedIconLabel() : "hidden"
                      }
                    >
                      Community
                    </div>
                  </button>
                )}

                <button
                  onClick={() => {
                    navigate(libraryPageLink());
                    window.scrollTo({ top: 0 });
                  }}
                  disabled={selected === PageRoute.LIBRARY && true}
                  className=""
                >
                  {/* Temporary solution to avoid icon img loading latency. */}
                  <img
                    src={SelectedLibraryIcon}
                    className={selected === PageRoute.LIBRARY ? "" : "hidden"}
                  />
                  <img
                    src={LibraryIcon}
                    className={selected === PageRoute.LIBRARY ? "hidden" : ""}
                  />
                  <div
                    className={selected === PageRoute.LIBRARY ? getSelectedIconLabel() : "hidden"}
                  >
                    Library
                  </div>
                </button>

                <button
                  onClick={() => {
                    navigate(insightsPageLink());
                    window.scrollTo({ top: 0 });
                  }}
                  disabled={selected === PageRoute.INSIGHTS && true}
                >
                  <img
                    src={SelectedInsightIcon}
                    className={selected === PageRoute.INSIGHTS ? "" : "hidden"}
                  />
                  <img
                    src={InsightIcon}
                    className={selected === PageRoute.INSIGHTS ? "hidden" : ""}
                  />
                  <div
                    className={selected === PageRoute.INSIGHTS ? getSelectedIconLabel() : "hidden"}
                  >
                    Insights
                  </div>
                </button>
              </div>
            </nav>
          </div>
        )}
    </>
  );
};
