import { ReactNode } from "react";
import { ErrorState } from "../error";

export const VerifyAdmin = ({
  successComponent,
  userType,
}: {
  successComponent: ReactNode;
  userType: string | undefined;
}) => {
  // Check if the user is an admin
  if (userType === "admin") {
    return <>{successComponent}</>;
  } else {
    // Render ErrorState component if not an admin
    return <ErrorState message="Access Denied: You do not have permission to view this page." />;
  }
};
