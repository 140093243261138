import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getData from "../../controllers/helper/getData";
import { GET_USER_DISPLAY_NAME } from "../../controllers/helper/requestConstants";
import SunChasingHeart from "../../../assets/illustrations/sun-chasing-heart.svg";
import Clouds from "../../../assets/illustrations/clouds.svg";
import { loginPageLink, createPageLink } from "../../util/relative-links";
import { GOOGLE_LOGIN_URL } from "../../controllers/helper/requestConstants";
import { SignInComponent } from "../home/SignInComponent";

/**
 * This is the Buddy Invite Page. Users will be directed here when they use a Buddy Invite
 * link and are not logged in. This page will handle directing the user to either log in
 * or sign up while maintaining the referral code.
 *
 * TODO: Handle when user enters an invalid code (nothing or incorrect)
 */
export const BuddyInvitePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referrerCode = searchParams.get("referrer");
  const [displayName, setDisplayName] = useState("[Name]"); // default to [Name]
  // If there is no referrer code, redirect the user back to the home page.
  // TODO: There may be a better way to handle this.
  useEffect(() => {
    if (!referrerCode) navigate("/");
    else {
      // Fetch the display name using the getData function
      async function fetchDisplayName() {
        try {
          const response = await getData(GET_USER_DISPLAY_NAME, { userId: referrerCode });
          if (response && response.displayName) {
            setDisplayName(response.displayName);
          }
        } catch (error) {
          console.error("Failed to fetch the display name", error);
        }
      }
      fetchDisplayName();
    }
  }, [referrerCode]);
  return (
    <div
      className="w-screen h-screen bg-no-repeat bg-[center_top_18rem] bg-teal-50 bg-cover bg-[center_top_18rem] md:bg-[center_top_15rem] lg:bg-[center_top_12rem] xl:bg-[center_top_8rem] pt-[180px]"
      style={{ backgroundImage: `url(${Clouds})` }}
    >
      <div className="container w-full h-full flex items-center flex-col justify-between">
        <img className="h-21 w-40" src={SunChasingHeart} alt="Sun reaching for heart" />
        <div className="flex flex-col items-center py-[80px] min-w-[300px]">
          <h1 className="font-bold text-[22px]">You've got an invite!</h1>
          <p className="text-sm pt-2 pb-[70px]">{displayName} wants you to flourish together.</p>
          <SignInComponent googleLoginUrl={`${GOOGLE_LOGIN_URL}?referrerCode=${referrerCode}`}
            createAccountUrl={`${createPageLink()}?referrer=${referrerCode}`}
            loginUrl={`${loginPageLink()}?referrer=${referrerCode}`} />
        </div>
      </div>
    </div>
  );
};
