import { useState, useRef } from "react";
import { Card, BackgroundImageConfig } from "../card";
import classNames from "classnames";
import arrowIcon from "../../../assets/icons/icon-arrow-right.svg";
import CloseIcon from "../../../assets/icons/icon-close.svg";
import { ShareIcon } from "../svg-components/ShareIcon";
import { ActivityCardColor } from "../../data/types";
import { TypeformButtonLink } from "../button";

export interface ActivityCardProps {
  color: ActivityCardColor;
  backgroundImage: BackgroundImageConfig;
  title: string;
  linkConfig: {
    id: string;
    hiddenData?: string;
  };
  trialLink?: string;
}

interface LinkCopiedCardProps {
  setShowShareCard: React.Dispatch<React.SetStateAction<boolean>>;
  activityName: string | undefined;
  trialLink: string | undefined;
  linkMessage: string;
}

export const LinkCopiedCard = ({ setShowShareCard, linkMessage }: LinkCopiedCardProps) => {
  // Remove extra whitespace
  const newText = linkMessage.split("\n");
  newText.splice(1, 1);

  return (
    <div className="w-[351px] rounded-xl overflow-hidden">
      <Card>
        <div className="px-4 pt-3 pb-6 flex flex-col space-y-5">
          <header className="min-h-[91px] flex flex-col justify-center space-y-1">
            <div className="flex items-center justify-end min-h-[44px]">
              <button className="flex items-center justify-center min-h-[44px] min-w-[44px]">
                <img src={CloseIcon} alt="" onClick={() => setShowShareCard(false)} />
              </button>
            </div>
            <h2 className="font-semibold text-lg text-teal-700 leading-normal">
              Enjoyed this activity? <br />
              Share it with someone who might also enjoy it! ☀️
            </h2>
            <p className="text-sm pt-2">
              Message below copied to clipboard. Feel free to use this message or create your own.
            </p>
          </header>
          <section className="w-full bg-white rounded-xl overflow-hidden break-words">
            {newText.map((text, index) => (
              <p className="text-sm p-3 italic" key={index}>
                {text}
              </p>
            ))}
          </section>
        </div>
      </Card>
    </div>
  );
};

export const ActivityCard = ({
  color,
  backgroundImage,
  title,
  linkConfig,
  trialLink,
}: ActivityCardProps) => {
  function handleShare() {
    setShowShareLinkCard(true);
    navigator.clipboard.writeText(linkMessage);
  }
  const [showShareLinkCard, setShowShareLinkCard] = useState(false);
  const linkPopupRef = useRef<HTMLDivElement>(null);
  const linkMessage = `Sharing a science-based well-being activity called ${title} here. It's short and sweet. Hope you'll enjoy it, too!\n\n${trialLink}`;
  return (
    <>
      <Card color={color.card} backgroundImage={backgroundImage}>
        <div className="min-h-[256px] h-full relative">
          <div className="flex flex-col">
            <div
              className={classNames(
                "px-3 pt-3 rounded-t-3xl flex justify-between items-start",
                color.card.background
              )}
            >
              <div className="w-2/3 sm:w-[105px] md:w-[95px] lg:w-[100px]">
                <p className="font-bold text-lg leading-6">{title}</p>
              </div>
              <TypeformButtonLink
                className={
                  "bottom-3 right-3 w-[32px] h-[32px] bg-teal-400 rounded-lg flex items-center justify-center"
                }
                usingDefaultStyles={false}
                typeformLink={linkConfig}
              >
                <img src={arrowIcon} alt="" />
              </TypeformButtonLink>
            </div>
            <div className={classNames("bg-gradient-to-b h-12", color.gradient)} />
          </div>

          {/* Show the Share Link Button */}
          {trialLink?.split("#")[0] && (
            <button
              className={`w-8 h-8 bg-${color.exLightColor} rounded-xl flex items-center 
            justify-center absolute bottom-3 right-3`}
              onClick={handleShare}
            >
              <ShareIcon color={color.colorHex} />
            </button>
          )}
        </div>
      </Card>
      {showShareLinkCard && (
        <div className="fixed top-0 left-0 w-full h-screen bg-white/50 z-10">
          <div className="w-full h-full flex justify-center items-center" ref={linkPopupRef}>
            <div className="max-w-[350px] sm:max-w-none">
              <LinkCopiedCard
                setShowShareCard={setShowShareLinkCard}
                activityName={title}
                trialLink={trialLink}
                linkMessage={linkMessage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
