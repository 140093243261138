import React, { useEffect, useRef, ReactNode } from "react";

interface Props {
  onClose?: () => void;
  children?: ReactNode;
  buttonRef: React.RefObject<HTMLButtonElement>;
}

export const BottomSheet = ({ children, onClose, buttonRef }: Props) => {

  const windowRef = useRef<HTMLDivElement>(null);
  // This `useEffect` contains an event listener for click events outside of this component and will close it
  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      // If the user clicks the button that opens the popup, don't do anything
      if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
        return;
      }

      // If the user clicks inside the popup, don't do anything
      if (windowRef.current && windowRef.current.contains(event.target as Node)) {
        return;
      }

      // If the user clicks outside the popup, call the onClose function
      onClose?.();
    }
    // Add the event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [onClose]);


  return (
    <div className="bg-gray-500/[.4] fixed bottom-0 left-0 right-0 top-0 z-50 md:flex md:justify-center md:items-center">
      <div ref={windowRef} className="bg-white rounded-t-3xl md:rounded-3xl md:min-w-[400px] fixed left-0 right-0 bottom-0 md:static px-4 pt-7 pb-7 flex flex-col md:left-36 md:right-36">
        {children}
      </div>
    </div>
  );
};
