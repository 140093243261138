import { ICommunityPostData } from "../../../client-server-shared/response-types";
import postData from "../helper/postData";
import postForm from "../helper/postForm";
import {
  CREATE_COMMUNITY_POST_URL,
  DELETE_COMMUNITY_POST_URL,
  TOGGLE_POST_REACTION_URL,
  UPDATE_COMMUNITY_POST_URL,
} from "../helper/requestConstants";

export async function createPost(form: HTMLFormElement): Promise<boolean> {
  const createPostResponse = await postForm(CREATE_COMMUNITY_POST_URL, form);
  return Boolean(createPostResponse.success);
}

export async function updatePost(postId: string, privacyLevel: string): Promise<boolean> {
  const response = await postData(UPDATE_COMMUNITY_POST_URL, {
    postId: postId,
    privacyLevel: privacyLevel,
  });
  return Boolean(response.success);
}

export async function removePost(postId: string): Promise<boolean> {
  const response = await postData(DELETE_COMMUNITY_POST_URL, {
    postId: postId,
  });
  return Boolean(response.success);
}

export async function toggleReaction(postId: string, emoji: string): Promise<ICommunityPostData> {
  const response = await postData(TOGGLE_POST_REACTION_URL, {
    postId: postId,
    emoji: emoji,
  });
  return response.post;
}
