import { useState } from "react";
import { ActivityGrid } from "../../components/activity-grid";
import { ActivityList } from "../../components/activity-list";
import { Header } from "../../components/header";
import { IUser } from "../../../client-server-shared/response-types";
import { LIBRARY_HEADER_TEXT } from "../../data/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Props {
  user: IUser;
}

// This is the library page designed to show our activities.
export const LibraryPage = ({ user }: Props) => {
  const LIST_VIEW = "list";
  const GRID_VIEW = "grid";
  const [selected, setSelected] = useState<string>(LIST_VIEW); // Be default, show the list view.

  const host = window.location.host;
  const dataTfHidden = `id=${user._id},host=${host}`;
  return (
    <>
      <section className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <h1 className="font-bold text-2xl mt-6 mb-4 pt-4 pb-4 text-[#004F56]">My Library</h1>
          <div className="place-self-end space-x-4 px-2">
            <button
              onClick={() => {
                setSelected(LIST_VIEW);
              }}
              disabled={selected === LIST_VIEW}
            >
              <FontAwesomeIcon
                icon={icon({ name: "list" })}
                className={selected === LIST_VIEW ? "text-[#004F56]" : "text-[#A9ACA8]"}
              />
            </button>
            <button
              onClick={() => {
                setSelected(GRID_VIEW);
              }}
              disabled={selected === GRID_VIEW}
            >
              <FontAwesomeIcon
                icon={icon({ name: "border-all" })}
                className={selected === GRID_VIEW ? "text-[#004F56]" : "text-[#A9ACA8]"}
              />
            </button>
          </div>
        </div>
        <Header text="" subText={LIBRARY_HEADER_TEXT} />
        {selected === LIST_VIEW ? (
          <ActivityList hiddenData={dataTfHidden} />
        ) : (
          <ActivityGrid hiddenData={dataTfHidden} userId={user._id || ""} />
        )}
      </section>
    </>
  );
};
